/* eslint-disable */
export default function OptionButton({
  caption = "",
  isSelected = false,
  onClick = () => null,
  children = <span>Option</span>,
}) {
  const css = "disp-flex dir-col flex-gap center medium-txt body-txt";
  const cssSelected = isSelected ? "option-selected" : "option-btn";

  return (
    <button className={`${css} ${cssSelected}`} type="button" onClick={onClick}>
      {children}
      <span className="regular-txt detail-txt">*{caption}</span>
    </button>
  );
}
