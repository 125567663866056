import { useUserStore } from "../../stores";

export const useCurrentUserRole = () => {
  const currentUser = useUserStore((state) => state.currentUser);
  const isAuth = currentUser !== null;
  const isCRM = isAuth && currentUser?.role === "crm";
  const isAccountant = isAuth && currentUser?.role === "accountant";
  const isSeller = isAuth && currentUser?.role === "seller";

  return { isCRM, isSeller, isAccountant };
};
