/* eslint-disable */
import * as React from "react"

const History_Icon = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8.865 7.72 2.27 2.2c.167.166.251.345.251.54 0 .193-.084.366-.252.518a.7.7 0 0 1-.525.249.7.7 0 0 1-.525-.249L7.668 8.654a.954.954 0 0 1-.22-.332 1.067 1.067 0 0 1-.074-.395V4.545c0-.222.07-.401.21-.54a.744.744 0 0 1 .546-.207c.21 0 .385.069.525.207.14.139.21.318.21.54V7.72ZM8.172 16A8.037 8.037 0 0 1 5 15.367a8.22 8.22 0 0 1-2.595-1.722 8.288 8.288 0 0 1-1.754-2.553A7.593 7.593 0 0 1 0 7.97c0-1.107.217-2.141.651-3.103a8.231 8.231 0 0 1 1.754-2.521A8.31 8.31 0 0 1 5 .633 8.036 8.036 0 0 1 8.172 0a7.58 7.58 0 0 1 3.225.706 8.907 8.907 0 0 1 2.7 1.93V1.41c0-.207.066-.384.199-.529a.69.69 0 0 1 .536-.218c.21 0 .388.073.535.218.147.145.221.322.221.53v2.987a.962.962 0 0 1-.987.975h-3.068a.744.744 0 0 1-.546-.207.725.725 0 0 1-.21-.54.76.76 0 0 1 .21-.55.725.725 0 0 1 .546-.217h1.009a6.852 6.852 0 0 0-2.006-1.38 5.67 5.67 0 0 0-2.364-.509c-1.709 0-3.165.575-4.37 1.723-1.204 1.148-1.806 2.552-1.806 4.213 0 1.701.598 3.15 1.796 4.347 1.197 1.197 2.657 1.795 4.38 1.795 1.54 0 2.84-.49 3.897-1.473 1.057-.982 1.74-2.193 2.048-3.632a.874.874 0 0 1 .41-.508c.203-.118.424-.163.662-.135a.821.821 0 0 1 .662.405.98.98 0 0 1 .115.778c-.35 1.881-1.24 3.438-2.668 4.669C11.87 15.384 10.161 16 8.172 16Z"
      fill="#fff"
    />
  </svg>
)

export default History_Icon
