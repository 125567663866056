import { UpdateState, UpdateStep, UpdateType } from '../../models/market-update';

export const createMarketUpdateSlice = (set) => ({
  marketUpdateStep: UpdateStep.First,
  marketUpdateType: UpdateType.Unselected,
  marketUpdateState: UpdateState.Waiting,
  setMarketUpdateStep: (step) => set({ marketUpdateStep: step }),
  setMarketUpdateType: (type) => set({ marketUpdateType: type }),
  setMarketUpdateState: (state) => set({ marketUpdateState: state }),
  resetMarketUpdateStep: (step) => set({
    marketUpdateStep: step,
    marketUpdateType: UpdateType.Unselected,
    marketUpdateState: UpdateState.Waiting
  })
});
