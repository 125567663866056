/* eslint-disable */
import React from "react";
import DataTable from "react-data-table-component";

const TableTravelsDrivers = ({ Travels }) => {
  const columns = [
    {
      name: "ID Viaje",
      selector: (row) => row.trip_id,
      sortable: true,
      cell: (row) => (
        <strong
          style={{
            width: "100px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row.trip_id}
        </strong>
      ),
    },
    {
      name: "Fecha de solicitud",
      selector: (row) => row.time_request,
      sortable: true,
      cell: (row) => (
        <strong>
          {new Date(row.time_request).toISOString().substring(0, 10)}
        </strong>
      ),
    },
    {
      name: "Método de pago",
      selector: (row) => row?.payment_method,
      sortable: true,
      cell: (row) => <p>{row?.payment_method?.toUpperCase()}</p>,
    },
    {
      name: "Tarifa de Total",
      selector: (row) => row?.tarifa_total?.toFixed(2) || 0,
      sortable: true,
      cell: (row) => <p>${row?.tarifa_total?.toFixed(2) || 0}</p>,
    },
    {
      name: "Tarifa de viaje",
      selector: (row) => row?.subtotal_tarifa_dinamica?.toFixed(2) || 0,
      sortable: true,
      cell: (row) => <p>${row?.subtotal_tarifa_dinamica?.toFixed(2) || 0}</p>,
    },
    {
      name: "Cuota de solicitud 10%",
      selector: (row) => row?.cuota_de_solicitud?.toFixed(2) || 0,
      sortable: true,
      cell: (row) => <p>${row?.cuota_de_solicitud?.toFixed(2) || 0}</p>,
    },
    {
      name: "IVA 8%",
      selector: (row) => row?.plataforma_iva?.toFixed(2),
      sortable: true,
      cell: (row) => <p>${row?.plataforma_iva?.toFixed(2) || 0}</p>,
    },
    {
      name: " 2.1%",
      selector: (row) => row?.impuestos_isr?.toFixed(2),
      sortable: true,
      cell: (row) => <p>${row?.impuestos_isr?.toFixed(2) || 0}</p>,
    },
    {
      name: "Uso de plataforma",
      selector: (row) => row?.uso_plataforma?.toFixed(2),
      sortable: true,
      cell: (row) => <p>${row?.uso_plataforma?.toFixed(2) || 0}</p>,
    },

    {
      name: "Comisión por viaje",
      selector: (row) => row?.tarifa_comision?.toFixed(2),
      sortable: true,
      cell: (row) => <p>${row?.tarifa_comision?.toFixed(2) || 0}</p>,
    },
    {
      name: "Total a recibir el conductor",
      selector: (row) => row?.pago_total?.toFixed(2),
      sortable: true,
      cell: (row) => <strong>${row?.pago_total?.toFixed(2)}</strong>,
    },
  ];

  const OptionsPagination = {
    rowsPerPageText: "Viajes por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "white",
        borderRadius: "10px",
        border: "1px solid rgb(229, 237, 255)",
        width: "90vw",
      }}
    >
      <DataTable
        title="Viajes terminados"
        fixedHeader
        columns={columns}
        data={Travels}
        pagination
        highlightOnHover
        paginationComponentOptions={OptionsPagination}
        customStyles={{
          headCells: {
            style: {
              paddingLeft: "8px",
              paddingRight: "8px",
              backgroundColor: "#004990",
              color: "#FFFFFF",
            },
          },
          table: {
            style: {
              minWidth: "100%",
              width: "60vw",
              borderCollapse: "separate",
              height: "70vh",
            },
          },
          cells: {
            style: {
              paddingLeft: "8px",
              paddingRight: "8px",
              border: "1px solid rgb(229, 237, 255)",
            },
          },
        }}
        /*     paginationComponentOptions={paginationComponentOptions} */
      />
    </div>
  );
};

export default TableTravelsDrivers;
