/* eslint-disable */
export function SendIcon({ props }) {
  return (
    <svg
      height="1em"
      viewBox="0 96 960 960"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M120 896V256l760 320-760 320Zm60-93 544-227-544-230v168l242 62-242 60v167Zm0 0V346v457Z"
        fill="#004990"
      />
    </svg>
  );
}
