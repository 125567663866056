/* eslint-disable */
import React, { useState } from "react";
import { Sheet_Global } from "./Global/sheet_global";
import { Sheet_Accounting } from "./Accounting/sheet_accounting";
import { moveFileToFolder } from "../Google_drive_OAuth2.0/Move_Folder";
import { Sheet_Accounting_RFC } from "./AccountigWithRFC/sheet_accountingRFC";
import { Sheet_Accounting_withoutRFC } from "./AccountingWithoutRFC/sheet_accountingwithoutRFC";
import {
  deleteSpaces,
  refactoringDates,
} from "./CalculateFunctions/CalculateFunctions";
import Sheet_DriverSummary from "./DriverSummary/sheet_driversummary";
import Sheet_listdriversweek from "./ListDriversWeek/sheet_listdriversweek";
import RegisterToCollectionFinance from "../RegisterToCollectionFinance/RegisterToCollectionFinance";
import { useUserStore } from "../../stores";

export function Create_spreadsheet() {
  const clientId =
    "863239600242-en0ikm4jerho84a3bi8q7p0qlvv8kjg3.apps.googleusercontent.com";
  const SCOPES = "https://www.googleapis.com/auth/drive.file";
  const folderId = "1JWhBEfGBho_joT96mE-LqGiefHn-6DT8";
  const [loading, setLoading] = useState(false);
  const [urlDoc, setUrlDoc] = useState("");
  const currentUser = useUserStore((state) => state.currentUser);

  const [addDocument] = RegisterToCollectionFinance(
    "project_CMS/Exports/GlobalStatementsDrivers"
  );
  function TitleSheet() {
    return deleteSpaces("ESTADODECUENTAGLOBAL-" + new Date().toLocaleString());
  }

  function Create_spreadsheet_function(
    arre_travels = [],
    dateRange = [new Date(), new Date()],
    weekselect
  ) {
    if (dateRange[0] === null || dateRange[1] === null) {
      dateRange = [new Date(), new Date()];
    }

    const google = window.google;
    google.accounts.oauth2
      .initTokenClient({
        client_id: clientId,
        scope: SCOPES,
        callback: (tokenResponse) => {
          if (tokenResponse && tokenResponse.access_token) {
            setLoading(true);
            const params = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenResponse.access_token}`,
              },
              body: JSON.stringify({
                properties: {
                  title: TitleSheet(),
                  locale: "es_ES",
                },
                sheets: [
                  Sheet_DriverSummary(arre_travels, dateRange, weekselect),
                  Sheet_listdriversweek(arre_travels, dateRange, weekselect),
                  Sheet_Global(arre_travels, dateRange),
                  Sheet_Accounting(arre_travels, dateRange),
                  Sheet_Accounting_RFC(arre_travels, dateRange),
                  Sheet_Accounting_withoutRFC(arre_travels, dateRange),
                ],
              }),
            };
            fetch("https://sheets.googleapis.com/v4/spreadsheets", params)
              .then((response) => response.json())
              .then(async (data) => {
                const fileId = data;

                if (fileId?.spreadsheetId) {
                  const moveFiletoFolder = await moveFileToFolder(
                    fileId?.spreadsheetId,
                    folderId,
                    tokenResponse.access_token
                  );

                  if (moveFiletoFolder?.data) {
                    setUrlDoc(fileId?.spreadsheetUrl);
                    setLoading(false);

                    const save_log = addDocument({
                      id: fileId?.spreadsheetId,
                      timestamp: new Date().toISOString(),
                      by: currentUser.username,
                      url: fileId?.spreadsheetUrl,
                      title: fileId?.properties?.title,
                      period: refactoringDates(dateRange, weekselect),
                    });

                    await addDocument(save_log);
                  } else {
                    setUrlDoc("Error");
                    setLoading(false);
                  }
                } else {
                  setUrlDoc("Error");
                  setLoading(false);
                }
              })
              .catch((error) => {
                setUrlDoc("Error");
                setLoading(false);
                console.error("Error al crear el archivo:", error);
              });
          } else {
            setLoading(false);
          }
        },
      })
      .requestAccessToken();
  }

  return {
    Create_spreadsheet_function,
    loading,
    setUrlDoc,
    urlDoc,
  };
}
