/* eslint-disable */
import { Sidebar } from "./components";
import "./layout.css";

export function Navbar() {
  return (
    <nav className="nav-bar">
      <Sidebar />
    </nav>
  );
}

export function MainLayout({ isAuth = false, children = null }) {
  return (
    <main>
      <div className={isAuth ? "mainlayout-container" : "mainlayout"}>
        {children}
      </div>
    </main>
  );
}
