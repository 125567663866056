import {
  totalgetNiku,
  calculateCommission,
} from '../CalculateFunctions/CalculateFunctions'

function travel_conductor_weekly_adds(travel) {
  let rides_computes = []
  let tarifaTotal = 0
  let driver_cash_adds = 0
  let driver_card_adds = 0
  let total_get_niku = 0
  let driver_cash_additions = 0

  travel.forEach((travel) => {
    tarifaTotal += travel.tarifa_total
    if (travel.payment_method === 'Efectivo') {
      driver_cash_adds += travel.pago_total
    } else {
      driver_card_adds += travel.pago_total
    }
    total_get_niku += totalgetNiku(
      travel.cuota_de_solicitud,
      travel.plataforma_iva,
      travel.impuestos_isr,
      travel.uso_plataforma,
      calculateCommission(travel.tarifa_total, 0.15)
    )
    if (travel.payment_method === 'Efectivo') {
      driver_cash_additions += travel.tarifa_total
    }
  })

  rides_computes.push(tarifaTotal)
  rides_computes.push(driver_cash_adds)
  rides_computes.push(driver_card_adds)
  rides_computes.push(total_get_niku)
  rides_computes.push(driver_cash_additions)

  return rides_computes
}

export default travel_conductor_weekly_adds
