/* eslint-disable */
export function ArrowRightIcon({ props }) {
  return (
    <svg
      height="1em"
      viewBox="0 96 960 960"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M400 776V376l200 200-200 200Z" />
    </svg>
  );
}
