/* eslint-disable */

import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DatePickerMaterialUI from "../DatePickers/DatePickerMaterialUI";
import MultipleSelectChip from "../SelectMaterialUI/SelectMaterialUI.js";
import DatePickerEndMaterialUI from "../DatePickers/DatePickerEndMaterialUI";
import Button from "@mui/material/Button";
import {
  ChangesOption,
  FilterByDate,
} from "../../hooks/FiltersDrivers/FiltersDrivers";
import SelectWeeksMaterialUI from "../SelectMaterialUI/SelectWeeksMaterialUI.js";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default function AccordionFilters({
  setDateRanges,
  copybundledDrivers,
  setDrivers,
  opeDrawer,
  weeks,
  setweekselect,
}) {
  const [expanded, setExpanded] = React.useState("panel1");
  const [initialDate, setInitialDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [selected, setSelection] = React.useState([]);
  const [selectedweek, setSelectionWeek] = React.useState(null);

  React.useEffect(() => {
    setDateRanges([initialDate, endDate]);
  }, [initialDate, endDate, setDateRanges]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const ConatainerFilters = {
    padding: "10px",
    border: "1px solid rgb(229, 237, 255)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "10rem",
  };
  const TitlesAccordionStyle = {
    width: "100%",
    flexShrink: 0,
    fontSize: "1.3rem",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    color: "#e95a13",
  };

  const SubtitlesAccordionStyle = {
    fontSize: "1rem",
    fontFamily: "Montserrat, sans-serif",
    color: "#333",
  };

  const tabAccordion = {
    backgroundColor: "#f2f2f2",
  };
  function FilterByDates() {
    setDrivers(FilterByDate(copybundledDrivers, initialDate, endDate));
    opeDrawer(false);
  }
  function FilterByBlocks() {
    setDrivers(ChangesOption(copybundledDrivers, selected[0]));
    opeDrawer(false);
  }
  function FilterByWeeks(selectedweek) {
    const inicialDate = new Date(selectedweek?.fecha_inicio);
    const endDate = new Date(selectedweek?.fecha_fin);
    setDrivers(FilterByDate(copybundledDrivers, inicialDate, endDate));
    setweekselect(selectedweek);
    opeDrawer(false);
  }

  return (
    <div>
      <Accordion
        sx={tabAccordion}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={TitlesAccordionStyle}>
            Filtrar por rango de fechas no definidas
          </Typography>
        </AccordionSummary>
        <div style={ConatainerFilters}>
          <Typography sx={SubtitlesAccordionStyle}>
            Selecciona el rango de fechas que deseas aplicar
          </Typography>
          <div style={{ display: "flex", gap: "10px" }}>
            <DatePickerMaterialUI
              label={"Fecha inicial"}
              initialDate={initialDate}
              setInitialDate={setInitialDate}
            />
            <DatePickerEndMaterialUI
              label={"Fecha final"}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <Button
              disabled={initialDate === null || endDate === null ? true : false}
              variant="contained"
              onClick={() => FilterByDates()}
              sx={{ backgroundColor: "#004990" }}
              startIcon={<FilterAltIcon />}
            >
              Filtrar
            </Button>
          </div>
        </div>
      </Accordion>
      <Accordion
        sx={tabAccordion}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={TitlesAccordionStyle}>
            Filtrar por bloques especificos
          </Typography>
        </AccordionSummary>
        <div style={ConatainerFilters}>
          <Typography sx={SubtitlesAccordionStyle}>
            Selecciona la opción por la que desees filtrar
          </Typography>
          <div style={{ display: "flex", gap: "10px" }}>
            <MultipleSelectChip
              selected={selected}
              setSelection={setSelection}
            />
            <Button
              onClick={() => FilterByBlocks()}
              disabled={selected[0] !== undefined ? false : true}
              variant="contained"
              sx={{ backgroundColor: "#004990" }}
              startIcon={<FilterAltIcon />}
            >
              Filtrar
            </Button>
          </div>
        </div>
      </Accordion>
      <Accordion
        sx={tabAccordion}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography sx={TitlesAccordionStyle}>Filtrar por semanas</Typography>
        </AccordionSummary>
        <div style={ConatainerFilters}>
          <Typography sx={SubtitlesAccordionStyle}>
            Selecciona la semana que desas buscar
          </Typography>
          <div style={{ display: "flex", gap: "10px" }}>
            <SelectWeeksMaterialUI
              weeks={weeks}
              selected={selectedweek}
              setSelection={setSelectionWeek}
              setDateRanges={setDateRanges}
            />
            <Button
              disabled={selectedweek !== null ? false : true}
              onClick={() => FilterByWeeks(selectedweek)}
              variant="contained"
              sx={{ backgroundColor: "#004990" }}
              startIcon={<FilterAltIcon />}
            >
              Filtrar
            </Button>
          </div>
        </div>
      </Accordion>
     
    </div>
  );
}
