/* eslint-disable */
import React from 'react';
import '../styled/style-LoadingSpinner.css';

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner" />
    </div>
  );
}
