/* eslint-disable */
import React, { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import Dot_Icon from "../icons/Dot_Icon";
import UpdateButtonPayments from "./UpdateButtonTablePayments";
import { LinearProgress, TextField } from "@mui/material";
import { convertDate } from "../../hooks/Google_spreadsheet_0Auth2.0/CalculateFunctions/CalculateFunctions";
const ExpandedComponent = ({ data }) => (
  <div>
    <p>Información de DASHBOARD CONEKTA</p>
    <pre>{JSON.stringify(data?.customer, null, 2)}</pre>
  </div>
);
export default function TableHistoryPaymentsDrivers({
  Payments,
  GetInfoTableDriverPayments = () => {},
}) {
  const [filterText, setFilterText] = React.useState("");

  const filteredItems = Payments.filter(
    (item) =>
      item.idDriver &&
      item.idDriver.toLowerCase().includes(filterText.toLowerCase())
  );

  const SearchDriver = React.useMemo(() => {
    return (
      <TextField
        sx={{
          width: 200,
        }}
        id="filled-basic"
        label="Buscar conductor"
        variant="standard"
        onChange={(e) => setFilterText(e.target.value)}
        value={filterText}
      />
    );
  }, [filterText]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.orderId,
      sortable: true,
      cell: (row) => <strong>{row.orderId}</strong>,
    },
    {
      name: "Fecha de creación",
      selector: (row) => convertDate(row.createdat * 1000),
      sortable: true,
      cell: (row) => <p>{convertDate(row.createdat * 1000)}</p>,
    },
    {
      name: "Estado",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
          <Dot_Icon color="#008508" />
          <strong>{row.status == "paid" ? "Pagado" : "Pendiente"}</strong>
        </div>
      ),
    },
    {
      name: "Conductor",
      selector: (row) => row?.idDriver,
      sortable: true,
      /*       cell: (row) => <strong>${row.amount}</strong>, */
    },
    {
      name: "Saldo pendiente",
      selector: (row) => row.prevBalance,
      sortable: true,
      cell: (row) => (
        <strong style={{ color: "red" }}>- ${row.prevBalance * -1}</strong>
      ),
    },
    {
      name: "Monto pagado",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <strong>${row.amount}</strong>,
    },
    {
      name: "Saldo actualizado",
      selector: (row) => row.newBalance,
      sortable: true,
      cell: (row) => (
        <strong style={{ color: row.newBalance < 0 ? "red" : "green" }}>
          ${row.newBalance}
        </strong>
      ),
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const RefreshBtn = React.useMemo(
    () => (
      <UpdateButtonPayments refresh={GetInfoTableDriverPayments} env={null} />
    ),
    []
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            backgroundColor: "red",
            borderRadius: "10px",
            backgroundColor: "white",
            /*             boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
             */ padding: "10px",
          }}
        >
          <DataTable
            title="Hitorial de pagos por SPEI"
            fixedHeader
            columns={columns}
            data={filteredItems}
            pagination
            actions={[SearchDriver, RefreshBtn]}
            highlightOnHover
            customStyles={{
              headCells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "#004990",
                  color: "#FFFFFF",
                },
              },
              table: {
                style: {
                  minWidth: "100%",
                  width: "60vw",
                  borderCollapse: "separate",
                  height: "70vh",
                },
              },
              cells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  border: "1px solid rgb(229, 237, 255)",
                },
              },
            }}
            paginationComponentOptions={paginationComponentOptions}
            expandableRows
            selectableRows
            expandableRowsComponent={ExpandedComponent}
          />
        </div>
      </div>
    </>
  );
}
