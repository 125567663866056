/* eslint-disable */
import * as React from 'react';
import History_Icon from '../icons/History_Icon';
import '../styled/style-cardShop.css';

export default function BtnHistory({ onClick = () => null, disabled }) {
  return (
    <button
      className={!disabled ? 'button-Movements-disabled' : 'button-Movements'}
      disabled={!disabled}
      onClick={() => onClick()}
      variant="contained"
    >
      <History_Icon />
      <p>Ver movimientos</p>
    </button>
  );
}
