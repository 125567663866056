import styled from "styled-components";

export const DateRangeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 1rem;
  gap: 15px;
  color: #333;
`;
export const RadioOptionLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 0.9rem;
  background-color: #f7f8fd;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: justify;
`;

export const ViewDocument = styled.a`
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 30px;
  underline: none;
  text-decoration: none;

  &:before {
    content: "\\2022"; /* Código Unicode para el carácter de viñeta */
    margin-right: 10px;
    font-size: 18px;
  }
`;
