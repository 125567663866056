/* eslint-disable */
import { useEffect, useState } from "react";
import { ShopCard } from "../../components/shops-list";
import listShopsNextToken from "../../services/db/list-shops";
import listShopNamed from "../../services/db/list-shopName";
import { LoadingModal } from "../../components";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import PaginationButton from "../../components/PaginationButtons/PaginationButtons";
import InputSearch from "../../components/InputSearch/InputSearch";
import DropDownListSelection from "../../components/DropDownListSelection/DropSelection";
import "../../components/styled/style-cardShop.css";

export default function ListShops() {
  const ITEMS_PAGE = 40;
  const [name, setName] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [dataFromDB, setDataDB] = useState([]);
  const [encapsuleShops, setEncapsule] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [charginData, setDatacharging] = useState(false);
  const [order, setOrder] = useState("Orden alfabético a-z");
  /* Data range picker */
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const dateselect1 = startDate?.toISOString();
  const dateselect2 = endDate?.toISOString();

  useEffect(() => {
    showAllShops();
  }, []);

  useEffect(() => {
    setDataDB([...initialData]);
  }, [initialData]);

  useEffect(() => {
    let independent = dataFromDB.slice(0);
    setEncapsule([...independent.splice(0, ITEMS_PAGE)].sort(SortArrayAZ));
  }, [dataFromDB]);

  function compareFn(a, b) {
    const stA = a.status || false;
    const stB = b.status || false;
    if (stA) return -1;
    if (stB) return 1;
    return 0;
  }

  async function showAllShops() {
    setDatacharging(true);
    /*     const fetchShops = await listShopsNextToken();
    setInitialData(fetchShops); */
    setDatacharging(false);
  }

  async function searchName(name) {
    setCurrentPage(0);
    setDatacharging(true);
    const foundDealers = await listShopNamed([], 50, null, name);
    setDatacharging(false);
    setInitialData(foundDealers);
  }

  const getSortType = (value) => {
    setOrder(value !== undefined ? value : "Orden alfabético a-z");
    if (value === "Registros antiguos - a +") {
      let independent = dataFromDB.slice(0);
      setEncapsule([...independent.splice(0, ITEMS_PAGE)].sort(dateSort));
    } else if (value === "Tiendas habilitadas") {
      let independent = dataFromDB.slice(0);
      setEncapsule([...independent.splice(0, ITEMS_PAGE)].sort(compareFn));
    } else {
      //Orden albfabetico
      let independent = dataFromDB.slice(0);
      setEncapsule([...independent.splice(0, ITEMS_PAGE)].sort(SortArrayAZ));
    }
  };

  function dateSort(a, b) {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  }

  function SortArrayAZ(x, y) {
    if (x.shop_name < y.shop_name) {
      return -1;
    }
    if (x.shop_name > y.shop_name) {
      return 1;
    }
    return 0;
  }

  const ShopItems = encapsuleShops.map((item, index) => {
    return (
      <ShopCard
        key={index}
        id={item.id}
        name={item.shop_name}
        socialname={item.business_name}
        email={item.seller_id?.email}
        phone_number={item.phone_number}
        availability={item.status}
        dataBank={item.interclb}
        category={item.category}
        betweenDates={[dateselect1, dateselect2]}
        list_fees={item.list_fees}
        rfc={item.seller_id?.rfc}
        zipCode={item.postal_code}
        address={item.address_shop}
        method_pay={item.method_pay}
        createdAt={item.createdAt}
        url_image={item.url_image}
      />
    );
  });

  const nextPageHandler = () => {
    const totalElements = initialData.length;
    const nextPage = currentPage + 1;
    const firstIndex = nextPage * ITEMS_PAGE;
    if (firstIndex >= totalElements) return;
    let independent = dataFromDB.slice(0);
    setEncapsule([...independent.splice(firstIndex, ITEMS_PAGE)]);
    setCurrentPage(nextPage);
  };

  const beforePageHandler = () => {
    const beforePage = currentPage - 1;
    if (beforePage < 0) return;
    const firstIndex = beforePage * ITEMS_PAGE;
    let independent = dataFromDB.slice(0);
    setEncapsule([...independent.splice(firstIndex, ITEMS_PAGE)]);
    setCurrentPage(beforePage);
  };

  /* ¨******************************************************* */
  const orderOptions = [
    "Orden alfabético a-z",
    "Registros antiguos - a +",
    "Tiendas habilitadas",
  ];

  return (
    <section className="dispersion-delivery">
      <div className="dispersion-delivery-top">
        <div className="bar-options">
          <InputSearch
            placeholder={"Negocios registrados..."}
            onClick={() => searchName(name)}
            setName={setName}
            name={name}
          />

          {/*   <div>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setDateRange={setDateRange}
              disabled={false}
              maxDate={new Date()}
            />
          </div>

          <DropDownListSelection
            typeOrder={order}
            handleValue={getSortType}
            orderOptions={orderOptions}
          />

          <PaginationButton
            beforePageHandler={beforePageHandler}
            nextPageHandler={nextPageHandler}
            currentPage={currentPage}
          /> */}
        </div>

        <div className="grid-layout-shops">{ShopItems}</div>
      </div>
      <LoadingModal
        onClose={!charginData}
        open={charginData}
        text={"Procesando tiendas, estamos haciendo cuentas..."}
      />
    </section>
  );
}
