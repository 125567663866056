/* eslint-disable */
import "./error.css";

export default function ErrorCheckMark({ hide = false }) {
  return (
    <>
      <div className="sa">
        <div className="sa-error" hidden={!hide}>
          <div className="sa-error-x">
            <div className="sa-error-left" />
            <div className="sa-error-right" />
          </div>
          <div className="sa-error-placeholder" />
          <div className="sa-error-fix" />
        </div>
      </div>
      <div className="spacing" />
    </>
  );
}
