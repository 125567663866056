/* eslint-disable */
import { useMarketUpdateStore } from "../../stores";
import "./consortium.css";

const steps = [
  "Seleccionar archivo",
  "Seleccionar tipo de actualización",
  "Finalizar",
];

export function ConsortiumUpdateStepper() {
  const marketUpdateStep = useMarketUpdateStore(
    (state) => state.marketUpdateStep
  );

  return (
    <div className="step-container">
      <div className="step-circle">
        <p className="bold-txt subtitle-txt white-txt txt-center">
          {marketUpdateStep + 1}
        </p>
      </div>
      <p className="bold-txt body-txt black-txt">{steps[marketUpdateStep]}</p>
    </div>
  );
}
