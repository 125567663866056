import { nikuProduct } from '../../models/niku-product';
import { updateProductByID } from '../common/mutation-functions';

export async function updateNikuProducts (erpProducts = [nikuProduct]) {
  try {
    const products = [];

    for (const product of erpProducts) {
      const updatedProduct = await updateProductByID(product);
      products.push(updatedProduct);
    }

    return products;
  } catch (exception) {
    return null;
  }
}
