const white = {
  red: 1,
  green: 1,
  blue: 1,
  alpha: 1,
}

const black = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 1,
}

export function footer_driversummary(pos, tag, data, adds) {
  if (adds !== undefined) {
    return {
      startRow: pos++,
      startColumn: 3,
      rowData: [
        {
          values: [
            {
              userEnteredValue: {
                stringValue: tag,
              },
              userEnteredFormat: {
                backgroundColorStyle: {
                  rgbColor: white,
                },
                textFormat: {
                  bold: true,
                  foregroundColorStyle: {
                    rgbColor: black,
                  },
                  fontSize: 8,
                },
                horizontalAlignment: 'LEFT',
                verticalAlignment: 'MIDDLE',
                wrapStrategy: 'LEGACY_WRAP',
              },
            },
            {
              userEnteredValue: {
                numberValue: adds[0],
              },
            },
            {
              userEnteredValue: {
                numberValue: adds[1],
              },
            },
            {
              userEnteredValue: {
                numberValue: adds[2],
              },
            },
            {
              userEnteredValue: {
                numberValue: adds[3],
              },
            },
            {
              userEnteredValue: {
                numberValue: adds[4],
              },
            },
          ],
        },
      ],
    }
  } else {
    return {
      startRow: pos++,
      startColumn: 3,
      rowData: [
        {
          values: [
            {
              userEnteredValue: {
                stringValue: tag,
              },
              userEnteredFormat: {
                backgroundColorStyle: {
                  rgbColor: white,
                },
                textFormat: {
                  bold: true,
                  foregroundColorStyle: {
                    rgbColor: black,
                  },
                  fontSize: 8,
                },
                horizontalAlignment: 'LEFT',
                verticalAlignment: 'MIDDLE',
                wrapStrategy: 'LEGACY_WRAP',
              },
            },
            {
              userEnteredValue: {
                numberValue: data,
              },
            },
          ],
        },
      ],
    }
  }
}
