import { myFetch } from './my-fetch';

/**
 * Function to get the ata object
 *
 * @param {string} url - url to send the data
 */
async function get (url) {
  try {
    const response = await myFetch(url);

    return response.json();
  } catch (err) {
    return false;
  }
}

export default get;
