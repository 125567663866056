/* eslint-disable */
import imgNiku from "../../../assets/niku-here.png";
import nikuLogo from "../../../assets/niku-logo.png";
import styles from "./LoginImages.module.css";

export function LoginImages() {
  return (
    <div className={styles.loginImages}>
      <img alt="img-niku" className={styles.loginNikuHere} src={imgNiku} />
      <img alt="logo-niku" className={styles.loginNikuLogo} src={nikuLogo} />
    </div>
  );
}
