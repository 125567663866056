import { getShopProductsNT } from '../common/query-functions';
import { nikuProduct } from '../../models/niku-product';

export async function getShopProducts (
  products = [nikuProduct], limit = 100000, next = null
) {
  const { items, nextToken } = await getShopProductsNT(limit, next);

  if (items.length > 0) {
    const newItems = products.concat(items);

    if (nextToken === null) return newItems;

    return getShopProducts(newItems, limit, nextToken);
  }

  if (items.length === 0 && nextToken !== null) {
    return getShopProducts(products, limit, nextToken);
  }

  return products;
}
