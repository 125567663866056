/* eslint-disable */
import styles from "./LoginLabel.module.css";

export function LoginLabel({ text = "" }) {
  return (
    <label className={styles.loginFormLabel} htmlFor="p">
      {text}
    </label>
  );
}
