import { get } from '../request';
import { isEmptyArray, rebuildProducts } from '../../utilities';

const path = require('../../urls.json');

export async function getRecentlyModifiedProducts () {
  try {
    const url = `${path.REACT_APP_UNSPLASH_URLSERVER}recentlyModifiedProducts`;

    const res = await get(url);

    if (res !== false && !isEmptyArray(res.data)) {
      const rebuiltProducts = rebuildProducts(res.data);

      return rebuiltProducts;
    }

    return [];
  } catch (err) {
    return [];
  }
}
