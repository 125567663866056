import Calculate_status_coms_drivers from "../Calculata_status_coms_drivers/Calculate_status_coms_drivers";
import { destructureDate } from "../Google_spreadsheet_0Auth2.0/CalculateFunctions/CalculateFunctions";
import SendStatusAccountDrivers from "../pdfSendDriver/sendStatusAccount";

export default function SendData() {
  const { send_status_account_DRIVERS } = SendStatusAccountDrivers();
  function comparesDates(a, b) {
    const fechaA = new Date(destructureDate(a[0]));
    const fechaB = new Date(destructureDate(b[0]));
    if (fechaA > fechaB) return -1;
    if (fechaA < fechaB) return 1;
    return 0;
  }

  async function send_status_account(ObjectDriver) {
    try {
      const response_calculate = await Calculate_status_coms_drivers(
        ObjectDriver.travel_matches
      );

      const Driver = ObjectDriver?.driver_info;
      const structure_object_send = {
        email: "alejandro.g.g.developer@gmail.com",
        nameOfDriver: Driver?.name || "",
        phoneNumber: Driver?.phone || "",
        isDebt: response_calculate.isDebt,
        movementData: response_calculate.movementData.sort(comparesDates),
        totalGain: response_calculate.totalGain,
        netAmount: response_calculate.netAmount,
        iva: response_calculate.iva,
        isr: response_calculate.isr,
        platformFees: response_calculate.platformFees,
        totalCommission: response_calculate.totalCommission,
        balanceTotalAmount: response_calculate.balanceTotalAmount,
        applicationFee: response_calculate.applicationFee,
      };

      await send_status_account_DRIVERS(structure_object_send);
    } catch (err) {
      console.log(
        "🚀 ~ file: ModalTravels.js:97 ~ send_status_account ~ err:",
        err
      );
    }
  }
  return {
    send_status_account,
  };
}
