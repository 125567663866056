/* eslint-disable */
import * as React from "react"

const Retry_Icon = (props) => (
  <svg
    width={22}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.091 11.665c-.223-.224-.415-.353-.579-.398a.635.635 0 0 0-.45.063 2.363 2.363 0 0 0-.397.32l-4.521 4.525a2.04 2.04 0 0 0-.31.387.547.547 0 0 0-.063.45c.052.168.188.366.411.59.224.223.415.345.58.37a.574.574 0 0 0 .45-.064c.128-.076.261-.184.397-.32l4.482-4.486c.136-.136.248-.276.335-.412a.613.613 0 0 0 .063-.45c-.035-.153-.167-.345-.398-.575Zm-4.517-.046a2.025 2.025 0 0 0-.387-.307.552.552 0 0 0-.436-.052c-.164.042-.352.174-.58.398-.23.23-.365.429-.41.59a.63.63 0 0 0 .076.463c.087.136.192.269.318.398l4.5 4.5c.128.129.254.23.386.306a.547.547 0 0 0 .45.063c.168-.035.374-.167.604-.397.223-.224.352-.419.387-.59a.55.55 0 0 0-.052-.436 2.258 2.258 0 0 0-.307-.387l-4.549-4.549ZM1.006 10.074c.258 0 .517.094.715.293l1.933 1.908 1.932-1.908c.398-.39 1.04-.39 1.438.007.39.398.39 1.04-.007 1.437l-2.645 2.613c-.394.39-1.029.39-1.426 0L.302 11.81a1.015 1.015 0 0 1-.007-1.437.967.967 0 0 1 .711-.3Z"
      fill="#004990"
    />
    <path
      d="M3.65 14.528a1.014 1.014 0 0 1-1.015-1.015V8.257c0-1.113.217-2.194.65-3.213a8.21 8.21 0 0 1 1.768-2.623A8.21 8.21 0 0 1 7.676.652 8.206 8.206 0 0 1 10.892 0c1.113 0 2.194.216 3.213.649a8.21 8.21 0 0 1 2.623 1.768 8.236 8.236 0 0 1 2.417 5.836v1.915c0 .562-.453 1.015-1.015 1.015a1.014 1.014 0 0 1-1.015-1.015V8.253a6.229 6.229 0 0 0-6.223-6.223 6.235 6.235 0 0 0-6.227 6.227v5.256c0 .562-.453 1.015-1.015 1.015Z"
      fill="#004990"
    />
  </svg>
)

export default Retry_Icon
