/* eslint-disable */
import { useEffect, useState } from 'react';

export function FinishedTravels() {
  const [Travels, setTravels] = useState([]);
  const [Loading, setLoading] = useState(true);

  function getTravelsFinished(url) {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const verifyData = Array.isArray(data);
        if (verifyData) {
          let travel_finished = [];
          for (let i in data) {
            let date = data[i].fecha.split('/');
            let newDateIso = new Date(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]));

            if (
              data[i].estatus === 'Terminado' /* &&
              newDateIso.getTime() >= startDate.getTime() &&
              newDateIso.getTime() <= endDate.getTime() */
            ) {
              travel_finished.push(data[i]);
            }
          }
          setTravels(travel_finished);
        } else setTravels([]);
      })
      .catch((error) => {
        setTravels([]);
      })
      .finally(() => setLoading(false));
  }

  async function filterTravels(url) {
    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return [];
      })
      .finally(() => setLoading(false));
  }

  return { Travels, Loading, getTravelsFinished, filterTravels };
}
