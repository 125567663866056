/* eslint-disable */
import React, { useEffect, useState } from "react";
import GetDataTablePaymentsDrivers from "../../hooks/HistoryPaymentsDrivers/GetDataTable";
import TableHistoryPaymentsDrivers from "../../components/DriversPaymentsHistory/TableHistoryPayments";
import { LoadingModal } from "../../components";
import SelectConcilation from "../../components/SelectCocilation/SelectConcilation";
import TableWalletsGains from "../../components/DriversPaymentsHistory/TableWalletsGains";
import TableWalletsDebts from "../../components/DriversPaymentsHistory/TableWalletsDebt";
export default function ListConcilationDrivers() {
  const [value, setValue] = useState(0);

  const { GetInfoTableDriverPayments, dataTable, isLoading } =
    GetDataTablePaymentsDrivers();

  useEffect(() => {
    GetInfoTableDriverPayments();
  }, []);

  /*   if (isLoading) {
    return (
      <LoadingModal
        onClose={!isLoading}
        open={isLoading}
        text={"Cargando historial de pagos..."}
      />
    );
  } */
  useEffect(() => {
    document.title = `SGFC - Conciliación de pagos`;
  }, []);
  return (
    <>
      <SelectConcilation value={value} setValue={setValue} />

      {value === 0 ? (
        <div style={{ padding: "10px", height: "90vh" }}>
          <TableWalletsDebts />
        </div>
      ) : value === 1 ? (
        <div style={{ padding: "10px", height: "90vh" }}>
          <TableWalletsGains />
        </div>
      ) : (
        <div style={{ padding: "10px", height: "90vh" }}>
          <TableHistoryPaymentsDrivers
            Payments={dataTable}
            GetInfoTableDriverPayments={GetInfoTableDriverPayments}
          />
        </div>
      )}
    </>
  );
}
