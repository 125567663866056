/* eslint-disable */
import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import ExcelColor_Icon from "../icons/ExcelColor_Icon";

const ModalWrapper = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(
      8px
    ); // Ajusta el valor de desenfoque según lo necesites
    z-index: 2;
  }
`;

const ModalContent = styled.div`
  background-color: white;
  width: 300px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999999;
`;

const RadioOptionLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 0.9rem;
  background-color: #f7f8fd;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: justify;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AcceptButton = styled.button`
  background-color: #004990;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
`;

const CancelButton = styled.button`
  background-color: #e95a13;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
`;

const DateRangeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 1rem;
  gap: 15px;
  color: #333;
`;
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

// Estilos para el contenedor de viñetas
const ListaConVinetas = styled.ul`
  list-style-type: none;
  padding: 0;
`;

// Estilos para los elementos de lista con viñetas
const ListItem = styled.li`
  margin: 10px 0;
  display: flex;
  align-items: center;

  &:before {
    content: "\\2022"; /* Código Unicode para el carácter de viñeta */
    margin-right: 10px;
    font-size: 18px;
    animation: ${bounce} 1s infinite; /* Aplicar la animación a las viñetas */
  }
`;
const ViewDocuemnt = styled.a`
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 30px;
  underline: none;
  text-decoration: none;

  &:before {
    content: "\\2022"; /* Código Unicode para el carácter de viñeta */
    margin-right: 10px;
    font-size: 18px;
    animation: ${bounce} 1s infinite; /* Aplicar la animación a las viñetas */
  }
`;

export default function ModalOptionsExport({
  openTravels,
  closeTravels,
  urlDoc,
  setUrlDoc,
  dateRange,
  loading,
  action = () => {},
}) {
  const closeAndAction = () => {
    action();
  };
  const closeAndClearUrl = () => {
    setUrlDoc("");
    closeTravels();
  };

  function GenerateSubtitle(dateRange = [new Date(), new Date()]) {
    if (
      dateRange[0]?.toLocaleDateString() === dateRange[1]?.toLocaleDateString()
    ) {
      return `Periodo a exportar: Sin fecha seleccionada`;
    }
    if (dateRange.length < 2) {
      return "Rango de fechas inválido";
    }
    const fechaInicial = dateRange[0] || new Date();
    const fechaFinal = dateRange[1] || new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    const fechaInicialFormateada = fechaInicial?.toLocaleDateString(
      "es-ES",
      options
    );
    const fechaFinalFormateada = fechaFinal?.toLocaleDateString(
      "es-ES",
      options
    );
    const titulo = `Período a exportar: ${fechaInicialFormateada} a ${fechaFinalFormateada}`;

    return titulo;
  }

  return (
    <ModalWrapper open={openTravels}>
      <ModalContent>
        {/*         <ModalTitle>Hojas de cálculo de google</ModalTitle> */}

        {loading ? (
          <>
            <DateRangeContainer>
              Generando documento...
              <LoadingSpinner />
            </DateRangeContainer>
          </>
        ) : (
          <>
            {urlDoc !== "" ? (
              <>
                {urlDoc !== "Error" ? (
                  <>
                    <RadioOptionLabel>
                      El estado de cuenta ha sido generado correctamente
                    </RadioOptionLabel>

                    <ViewDocuemnt
                      href={urlDoc}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ExcelColor_Icon />
                      Da click aqui para ir al documento
                    </ViewDocuemnt>
                    <ButtonContainer>
                      <CancelButton onClick={closeAndClearUrl}>
                        Cerrar
                      </CancelButton>
                    </ButtonContainer>
                  </>
                ) : (
                  <>
                    <RadioOptionLabel>
                      Tuvimos un error al generar el documento, contacta al
                      administrador.
                    </RadioOptionLabel>

                    <ButtonContainer>
                      <CancelButton onClick={closeAndClearUrl}>
                        Cerrar
                      </CancelButton>
                    </ButtonContainer>
                  </>
                )}
              </>
            ) : (
              <>
                <DateRangeContainer>
                  {GenerateSubtitle(dateRange)}
                </DateRangeContainer>
                <h2>Este documento contiene:</h2>
                <ListaConVinetas>
                  <ListItem>Listado de conductores</ListItem>
                  <ListItem>Resumen de conductores</ListItem>
                  <ListItem>Estado de cuenta global</ListItem>
                  <ListItem>Estado de cuenta global para contaduría</ListItem>
                  <ListItem>Estado de cuenta global con RFC</ListItem>
                  <ListItem>Estado de cuenta global sin RFC</ListItem>
                </ListaConVinetas>
                <RadioOptionLabel>
                  El estado de cuenta a generar requiere autenticación con una
                  cuenta de Gmail específica de la empresa. Por favor, ingrese a
                  través de "nikutecnologiasadecv@gmail.com". Si no tiene
                  acceso, por favor, solicite permisos al administrador.
                </RadioOptionLabel>
                <ButtonContainer>
                  <AcceptButton onClick={closeAndAction}>Aceptar</AcceptButton>
                  <CancelButton onClick={closeAndClearUrl}>
                    Cancelar
                  </CancelButton>
                </ButtonContainer>
              </>
            )}
          </>
        )}
      </ModalContent>
    </ModalWrapper>
  );
}
