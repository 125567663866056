const orange = {
  red: 0.914,
  green: 0.353,
  blue: 0.075,
  alpha: 1,
};

const white = {
  red: 1,
  green: 1,
  blue: 1,
  alpha: 1,
};

function name_conductor_global(name, positions) {
  return {
    startRow: positions[0],
    startColumn: positions[1],
    rowData: [
      {
        values: {
          userEnteredValue: {
            stringValue: name,
          },
          userEnteredFormat: {
            textFormat: {
              bold: true,
              foregroundColorStyle: {
                rgbColor: white,
              },
              fontSize: 10,
            },
            backgroundColorStyle: {
              rgbColor: orange,
            },
            horizontalAlignment: "LEFT",
            verticalAlignment: "MIDDLE",
            wrapStrategy: "CLIP",
          },
        },
      },
    ],
  };
}

export { name_conductor_global };
