export async function getEquipmentsDrivers(offset, result = []) {
  let limit = 10;
  let apiKey = process.env.REACT_APP_HAURA_KEY;

  const endpoint = `https://superb-mackerel-18.hasura.app/api/rest/getequipments/${limit}/${offset}`;
  try {
    const response = await fetch(endpoint, {
      headers: {
        "x-hasura-admin-secret": `${apiKey}`,
      },
    });
    const newData = await response.json();

    if (newData?.equipments.length === 0) {
      return result;
    }

    result = result.concat(newData?.equipments);
    return getEquipmentsDrivers(offset + limit, result);
  } catch (error) {
    console.error("Error al obtener los datos de equipments:", error);
    return result;
  }
}
