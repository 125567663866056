/* eslint-disable */
import { useEffect, useRef } from "react";
import "./common.css";
import Lottie from "lottie-react";
import animationData from "./1697481208123.json";
export default function LoadingModal({ open = false, text = "" }) {
  const ref = useRef(null);

  useEffect(() => {
    if (open) {
      ref.current?.showModal();
      document.body.classList.add("modal-open"); // prevent bg scroll
    } else {
      ref.current?.close();
      document.body.classList.remove("modal-open");
    }
  }, [open]);

  return (
    <dialog className="loading-modal-dialog" open={open}>
      <Lottie
        animationData={animationData}
        style={{
          width: "30%",
          position: "absolute",
          transform: "translateY(-50%) translateX(-50%)",
          top: "50%",
          left: "50%",
        }}
      />

      <div className="loading-modal-container">
        <p className="loading-modal-text loading-modal">{text}</p>
      </div>
    </dialog>
  );
}
