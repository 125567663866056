/* eslint-disable */
export function PowerSettingsNewIcon({ props }) {
  return (
    <svg
      height="1.3em"
      viewBox="0 96 960 960"
      width="1.3em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M450 618V212h60v406h-60Zm30 320q-74 0-139.5-28.5T226 832q-49-49-77.5-114.5T120 578q0-80 34-149.5T250 305l42 42q-53 43-82.5 102.5T180 577.978Q180 703 267.5 790.5 355 878 480 878q125.357 0 212.679-87.5Q780 703 780 577.978 780 509 750.5 448.5 721 388 670 347l43-42q60 51 93.5 122T840 578q0 74-28.5 139.5t-77 114.5q-48.5 49-114 77.5T480 938Z"
        fill="#e95a13"
      />
    </svg>
  );
}
