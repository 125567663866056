import { API, Auth, graphqlOperation } from "aws-amplify";
import { getRolByUser, ListSpecificShops } from "../../graphql/myQueries";

export async function cognitoSignIn(email = "", password = "") {
  try {
    const user = await Auth.signIn(email, password);
    const role = await getUserRole(user.username);
    console.log("🚀 ~ file: auth.js:8 ~ cognitoSignIn ~ role:", role);
    return { username: user.username, role };
  } catch (error) {
    return { username: "", role: "" };
  }
}

export async function cognitoSignOut() {
  try {
    return await Auth.signOut();
  } catch (error) {
    return null;
  }
}

export async function getUserRole(accountID = "") {
  try {
    const response = await API.graphql(
      graphqlOperation(getRolByUser, { id: accountID })
    );

    return response.data.getAccount.role;
  } catch (error) {
    return "";
  }
}

export async function getCurrentUser() {
  try {
    const res = await Auth.currentAuthenticatedUser();
    const role = await getUserRole(res.username);
    return { username: res.username, role };
  } catch (error) {
    return { username: "", role: "" };
  }
}

export async function listSpecificShops() {
  try {
    const response = await API.graphql(graphqlOperation(ListSpecificShops));
    return response.data.listShops.items;
  } catch (error) {
    return [];
  }
}
