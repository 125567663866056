import { listPaymentsHistory } from '../common/query-functions';

async function listPayments (payments = [], limit = 50, next = null) {
  const { items, nextToken } = await listPaymentsHistory(limit, next);

  if (items.length > 0) {
    const newItems = payments.concat(items);

    if (nextToken === null) return newItems;
    return listPayments(newItems, limit, nextToken);
  }

  if (items.length === 0 && nextToken !== null) return listPayments(payments, limit, nextToken);

  return payments;
}

export default listPayments;
