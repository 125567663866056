/* eslint-disable */
import * as React from "react";
const Car_Icon = ({ color = "#45B058" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.271 8.214C6.021 6.312 6.923 5 8.964 5h5.473c2.04 0 2.943 1.312 3.693 3.214l.75 2.035a1.568 1.568 0 0 1 1.348.815c.163.284.248.607.247.936v2.624a1.786 1.786 0 0 1-.797 1.516c-.24.153-.516.234-.798.234H4.52c-.282 0-.558-.081-.797-.234a1.786 1.786 0 0 1-.798-1.516V12a1.868 1.868 0 0 1 .247-.935c.276-.498.79-.808 1.348-.815l.751-2.036Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      d="M6.866 16.375a.75.75 0 0 0-1.5 0h1.5Zm-.75 1.313h-.75a.73.73 0 0 0 0 .023l.75-.023ZM4.92 19l-.027.75a.72.72 0 0 0 .053 0L4.92 19Zm-1.196-1.313.75.024v-.024h-.75Zm.75-1.547a.75.75 0 0 0-1.5 0h1.5Zm.047-6.641a.75.75 0 1 0 0 1.5v-1.5Zm14.36 1.5a.75.75 0 0 0 0-1.5v1.5Zm-.845 5.375a.75.75 0 0 0-1.5 0h1.5Zm-.75 1.313.748.047.002-.047h-.75Zm.575 1.13.396-.637-.396.637Zm1.243 0-.396-.637.396.637Zm.575-1.13h-.75l.002.047.748-.047Zm.75-1.547a.75.75 0 1 0-1.5 0h1.5ZM5.85 12.75a.75.75 0 0 0 0 1.5v-1.5Zm.975 1.5a.75.75 0 0 0 0-1.5v1.5Zm9.75-1.5a.75.75 0 0 0 0 1.5v-1.5Zm.975 1.5a.75.75 0 0 0 0-1.5v1.5ZM5.366 16.375v1.313h1.5v-1.313h-1.5Zm0 1.336a.51.51 0 0 1-.473.54l.052 1.499c1.114-.04 1.955-.99 1.92-2.085l-1.498.046Zm-.42.54a.51.51 0 0 1-.474-.54l-1.499-.048c-.035 1.096.805 2.047 1.92 2.087l.053-1.5Zm-.473-.564V16.14h-1.5v1.547h1.5Zm.047-6.688h14.36v-1.5H4.52v1.5Zm12.015 5.375v1.313h1.5v-1.313h-1.5Zm.001 1.266a1.989 1.989 0 0 0 .928 1.814l.792-1.274a.489.489 0 0 1-.223-.446l-1.497-.094Zm.928 1.814c.625.388 1.41.388 2.035 0l-.792-1.274a.424.424 0 0 1-.45 0l-.793 1.274Zm2.035 0a1.989 1.989 0 0 0 .928-1.814l-1.497.094a.49.49 0 0 1-.223.446l.792 1.274Zm.93-1.767V16.14h-1.5v1.547h1.5ZM5.85 14.25h.975v-1.5H5.85v1.5Zm10.725 0h.975v-1.5h-.975v1.5Z"
    />
  </svg>
);
export default Car_Icon;
