/* eslint-disable */

import * as React from "react";
const Dot_Icon = ({ color = "#008508" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path fill={color} d="M12 9.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z" />
  </svg>
);
export default Dot_Icon;
