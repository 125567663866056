export function FilterByDate(copybundledDrivers, minDate, maxDate) {
  minDate.setHours(0, 0, 0, 0);
  maxDate.setHours(23, 59, 59, 999);

  let filteredByDate = [];
  for (let i = 0; i <= copybundledDrivers.length - 1; i++) {
    let newtravels = copybundledDrivers[i].travel_matches.filter((viaje) => {
      const fechasolicitud = new Date(viaje?.time_request);

      return fechasolicitud >= minDate && fechasolicitud <= maxDate;
    });

    if (newtravels.length > 0) {
      let copyDriver = { ...copybundledDrivers[i] };
      copyDriver.travel_matches = newtravels;
      filteredByDate.push(copyDriver);
    }
  }

  return filteredByDate;
}

export const ChangesOption = (copybundledDrivers, selectedOption) => {
  if (selectedOption === "Mes pasado") {
    const prevMonthStartDate = new Date();
    const prevMonthEndDate = new Date();
    prevMonthStartDate.setDate(1);
    prevMonthStartDate.setHours(0, 0, 0, 0);
    prevMonthStartDate.setMonth(prevMonthStartDate.getMonth() - 1);
    prevMonthEndDate.setDate(0);
    prevMonthEndDate.setHours(23, 59, 59, 999);
    prevMonthEndDate.setMonth(prevMonthEndDate.getMonth());

    return FilterByDate(
      copybundledDrivers,
      prevMonthStartDate,
      prevMonthEndDate
    );
  } else if (selectedOption === "Mes actual") {
    const currentDate = new Date();
    const currentMonthStartDate = new Date();
    currentMonthStartDate.setDate(1);
    currentMonthStartDate.setHours(0, 0, 0, 0);
    /*  setDateRange([currentMonthStartDate, currentDate]); */
    return FilterByDate(copybundledDrivers, currentMonthStartDate, currentDate);
  } else if (selectedOption === "Año pasado") {
    const lastYearStartDate = new Date();
    const lastYearEndDate = new Date();
    lastYearStartDate.setFullYear(lastYearStartDate.getFullYear() - 1);
    lastYearStartDate.setMonth(0);
    lastYearStartDate.setDate(1);
    lastYearStartDate.setHours(0, 0, 0, 0);
    lastYearEndDate.setFullYear(lastYearEndDate.getFullYear() - 1);
    lastYearEndDate.setMonth(11);
    lastYearEndDate.setDate(31);
    lastYearEndDate.setHours(23, 59, 59, 999);
    /* setDateRange([lastYearStartDate, lastYearEndDate]); */
    return FilterByDate(copybundledDrivers, lastYearStartDate, lastYearEndDate);
  } else if (selectedOption === "Año actual") {
    const lastYearStartDate = new Date();
    const lastYearEndDate = new Date();
    lastYearStartDate.setFullYear(lastYearStartDate.getFullYear());
    lastYearStartDate.setMonth(0);
    lastYearStartDate.setDate(1);
    lastYearStartDate.setHours(0, 0, 0, 0);

    /* setDateRange([lastYearStartDate, lastYearEndDate]); */
    return FilterByDate(copybundledDrivers, lastYearStartDate, lastYearEndDate);
  }
};
