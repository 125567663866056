import Openpay from './openpay';

const openpay = new Openpay();
const errors = ['request', 'internal', 'gateway'];

async function deleteSubscriptionOpp (oppID = '', subscriptionID = '') {
  try {
    const data = await openpay.deletedSubscription(oppID, subscriptionID);
    return !errors.includes(data.category);
  } catch (err) {
    return false;
  }
}

export default deleteSubscriptionOpp;
