/* eslint-disable */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import PrsentationCard from "./PresentationCard";
export default function ModalPresentation({ DriverInfo, open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <PrsentationCard DriverInfo={DriverInfo} />
      </Dialog>
    </React.Fragment>
  );
}
