export async function GetWeekDates() {
  let apiKey = process.env.REACT_APP_HAURA_KEY;
  const response = await fetch(
    "https://superb-mackerel-18.hasura.app/api/rest/getweeklydates",
    {
      headers: {
        "x-hasura-admin-secret": `${apiKey}`,
      },
    }
  );
  const data = await response.json();
  return data;
}
