/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TableTravelsDrivers from "../../components/drivers-list-travels/TableTravels";
import { Avatar, Button } from "@mui/material";
import ModalPresentation from "../../components/drivers-list-travels/ModalPresentation";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DrawerOptions from "../../components/drivers-list-travels/DrawerOptions";
import { IconButton, Tooltip } from "@mui/material";
import Snackbar from "../../components/SnackBar/Snackbar";

const ListDriverTravels = () => {
  const location = useLocation();
  const [driverInfo, setDriverInfo] = useState(null);
  const [openSnack, setOpenSnackbar] = useState(false);
  const [travels, setTravels] = useState([]);
  const [allInfo, setAllInfo] = useState(null);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    left: false,
  });

  useEffect(() => {
    const allInfo = JSON.parse(localStorage.getItem("allInfo"));
    const storedDriverInfo = JSON.parse(localStorage.getItem("driverInfo"));
    const storedTravels = JSON.parse(localStorage.getItem("travels"));

    if (storedDriverInfo && storedTravels && allInfo) {
      setAllInfo(allInfo);
      setDriverInfo(storedDriverInfo);
      setTravels(storedTravels);
    }
  }, []);

  useEffect(() => {
    setOpenSnackbar(true);

    setTimeout(() => {
      setOpenSnackbar(false);
    }, 7000);
  }, []);

  useEffect(() => {
    if (location.state && location.state.info) {
      const { driver_info, travel_matches } = location.state.info;
      setAllInfo(location.state.info);
      setDriverInfo(driver_info);
      setTravels(travel_matches);
      localStorage.setItem("driverInfo", JSON.stringify(driver_info));
      localStorage.setItem("travels", JSON.stringify(travel_matches));
      localStorage.setItem("allInfo", JSON.stringify(location.state.info));
    }
  }, [location.state]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    document.title = `SGFC - Viajes conductor`;
  }, []);
  return (
    <>
      <div className="bar-options">
        <Tooltip title="Verifica que puedes hacer en esta pantalla">
          <IconButton
            sx={{
              color: "#FFFFFF",
              border: "1px solid #004990",
              backgroundColor: "#004990",
              "&:hover": {
                backgroundColor: "#003080",
              }
            }}
            aria-label="Abrir"
            onClick={() => setState({ left: true })}
          >
            <ArrowForwardIosIcon color="inherit" />
          </IconButton>
        </Tooltip>
        <DrawerOptions
          setState={setState}
          state={state}
          ObjectDriver={allInfo}
        />
        <Tooltip title="Ver más información">
          <Button
            onClick={handleClickOpen}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "5px",
              cursor: "pointer",
              border: "1px solid rgb(229, 237, 255)",
              borderRadius: "5px",
            }}
          >
            <Avatar
              alt="profile"
              src={driverInfo?.photo}
              sx={{
                width: 40,
                height: 40,
              }}
            />
            <p>{driverInfo?.name + " " + driverInfo?.last_name}</p>
          </Button>
        </Tooltip>
        <ModalPresentation
          DriverInfo={driverInfo}
          open={open}
          setOpen={setOpen}
        />
      </div>
      <div
        style={{
          padding: "10px",
          backgroundColor: "#f7f8fd",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
          gap: "40px",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <TableTravelsDrivers Travels={travels} />
      </div>

      <Snackbar
        showSnackbar={openSnack}
        text="Para salir de esta pantalla, presione el subtítulo Conductores de la barra lateral izquierda."
      />
    </>
  );
};

export default ListDriverTravels;
