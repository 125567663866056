/* eslint-disable */
import { useEffect, useState } from "react";
import { LoadingModal } from "../../components";
import GetDrivers from "../../hooks/DriversNikuCar/getDrivers";
/* import BotonGlobalExportarEstados from "../../components/BotonGlobalExportarEstados/BotonGlobalExportarEstados";
import BotonGlobalCalculateCuotas from "../../components/BotonGlobalUserCuotas/BotonGlobalUserCuotas"; */
import BotonGlobalExportarExcel from "../../components/BotonGlobalExportarExcel/BotonGlobalExportarExcel";
import TableDrivers from "../../components/drivers-list/TableDrivers";
import { GetWeekDates } from "../../hooks/GetWeekDates/GetWeekDates";
import OpenDrawerFilters from "../../components/drivers-list/DrawerFilters";
import { refactoringDates } from "../../hooks/Google_spreadsheet_0Auth2.0/CalculateFunctions/CalculateFunctions";

function ListDrivers() {
  const { getTravelsHasura } = GetDrivers();
  const [drivers_Found, setDriverfound] = useState([]);
  const [Loading, setLoading] = useState(false);
  const copybundledDrivers = [...drivers_Found];
  const [driversFound, setDrivers] = useState([]);
  const [env, setenv] = useState(2);
  const [weeks, setWeeks] = useState([]);
  const [weekselect, setweekselect] = useState(null);
  /* Seleccion de rango de fechas */
  const [datesRanges, setDateRanges] = useState([new Date(), new Date()]);
  const excludedDrivers = [
    "WKQ4T5IaRoX37a3RNtQXjzJJRwT2", // Alejandro Guadalajara
    "KoIFKGeGykWIhAlJ8qJQz05eAR53", // Omar
    "yWI9ZsZIpdROvwoeCfCELwa6eWC3", //Alex Garcia
  ];
  async function getTravelsHasuras(env) {
    setLoading(true);
    try {
      const response_hasura = await getTravelsHasura(0, "Terminado");
      const groupedData = {};

      response_hasura.forEach((data_travel) => {
        const { driver_data, environment } = data_travel;

        if (driver_data === null) return;
        const environmentHasura = Number(environment);
        if (environmentHasura === env) {
          const { driver_id, ...rest } = driver_data;
          if (excludedDrivers.includes(driver_id)) return;
          if (!groupedData[driver_id]) {
            groupedData[driver_id] = {
              driver_info: rest,
              travel_matches: [],
            };
          }
          groupedData[driver_id].travel_matches.push(data_travel);
        }
      });

      for (const driverId in groupedData) {
        if (groupedData.hasOwnProperty(driverId)) {
          groupedData[driverId].travel_matches.sort(
            (a, b) => b.time_request - a.time_request
          );
        }
      }

      const result = Object.keys(groupedData).map((driver_id) => ({
        driver_id: driver_id,
        ...groupedData[driver_id],
      }));
      result.sort((a, b) => {
        if (a.travel_matches.length > 0 && b.travel_matches.length > 0) {
          return (
            b.travel_matches[0].time_request - a.travel_matches[0].time_request
          );
        } else {
          return 0;
        }
      });
      setDriverfound(result);
      setDateRanges([new Date(), new Date()]);
      setLoading(false);
    } catch (error) {
      setDriverfound([]);
      setLoading(false);
    }
  }

  async function getWeekDates() {
    const res = await GetWeekDates();

    setWeeks(
      res?.semanas_del_ano.filter(function (semana) {
        return semana.fecha_inicio.startsWith("2023");
      })
    );
  }

  useEffect(() => {
    getTravelsHasuras(env);
    getWeekDates();
  }, [env]);

  useEffect(() => {
    setDrivers(drivers_Found);
  }, [drivers_Found]);

  useEffect(() => {
    document.title = `SGFC - Conductores`;
  }, []);

  if (Loading) {
    return (
      <LoadingModal
        onClose={!Loading}
        open={Loading}
        text={"Procesando conductores, estamos haciendo cuentas..."}
      />
    );
  }

  return (
    <>
      <section className="dispersion-delivery">
        <div className="dispersion-delivery-top">
          <div className="bar-options">
            <div
              style={{
                marginRight: "10px",
                display: "flex",
                gap: "10px",
                justifyContent: "space-evenly",
                padding: "5px",
              }}
            >
              <OpenDrawerFilters
                setDateRanges={setDateRanges}
                copybundledDrivers={copybundledDrivers}
                setDrivers={setDrivers}
                arre_travels={driversFound}
                weeks={weeks}
                setweekselect={setweekselect}
              />
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  backgroundColor: "white",
                  border: "1px solid rgb(229, 237, 255)",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                Rango de fecha: {refactoringDates(datesRanges, weekselect)}
              </div>
            </div>
          </div>
          <TableDrivers
            Drivers={driversFound}
            refresh={getTravelsHasuras}
            env={env}
          />
        </div>

        {driversFound?.length > 0 ? (
          <BotonGlobalExportarExcel
            arre_travels={driversFound}
            dateRange={datesRanges}
            weekselect={weekselect}
          />
        ) : null}
      </section>
    </>
  );
}
export default ListDrivers;
