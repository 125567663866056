/* eslint-disable */
import * as React from "react"

const Send_Icon = (props) => (
  <svg
    width={14}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.953.985 9.645 12.204a.724.724 0 0 1-.514.445.713.713 0 0 1-.652-.183L6.05 10.124l-1.489 1.5a.358.358 0 0 1-.612-.255V8.098L.222 4.504A.725.725 0 0 1 .539 3.28L13.104.023a.713.713 0 0 1 .715.22.725.725 0 0 1 .133.742Zm-1.747.825L4.33 6.986l1.245 1.201 6.63-6.378Z"
      fill="#004990"
    />
  </svg>
)

export default Send_Icon
