/* eslint-disable */
import * as React from "react";
const ExcelColor_Icon = ({ color = "#45B058" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="-4 0 64 64"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M5.112.006A5.074 5.074 0 0 0 .039 5.08v53.841a5.073 5.073 0 0 0 5.073 5.074h45.774a5.074 5.074 0 0 0 5.074-5.074V20.316L37.058.006H5.112z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M19.429 53.938a.64.64 0 0 1-.54-.27l-3.728-4.97-3.745 4.97a.641.641 0 0 1-.54.27.71.71 0 0 1-.72-.72c0-.144.035-.306.144-.432l3.89-5.131-3.619-4.826a.722.722 0 0 1-.145-.414c0-.342.288-.72.721-.72.216 0 .432.108.576.288l3.438 4.628 3.438-4.646a.643.643 0 0 1 .541-.27c.378 0 .738.306.738.72a.695.695 0 0 1-.127.414l-3.619 4.808 3.891 5.149a.7.7 0 0 1 .125.414c0 .396-.324.738-.719.738zm9.989-.126h-5.455a1.083 1.083 0 0 1-1.081-1.08V42.415c0-.396.324-.72.774-.72.396 0 .721.324.721.72V52.48h5.041c.359 0 .648.288.648.648 0 .396-.289.684-.648.684zm6.982.216c-1.782 0-3.188-.594-4.213-1.495a.71.71 0 0 1-.234-.54c0-.36.27-.756.702-.756.144 0 .306.036.433.144.828.738 1.98 1.314 3.367 1.314 2.143 0 2.826-1.152 2.826-2.071 0-3.097-7.111-1.386-7.111-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.468 3.853 1.278a.73.73 0 0 1 .253.54c0 .36-.307.72-.703.72a.676.676 0 0 1-.432-.162c-.883-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636 0 1.748-1.188 3.53-4.43 3.53z"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M55.953 20.352v1H43.152s-6.312-1.26-6.127-6.707c0 0 .207 5.707 6.002 5.707h12.926z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M37.049 0v14.561c0 1.656 1.104 5.791 6.104 5.791h12.801L37.049 0z"
      clipRule="evenodd"
      opacity={0.5}
    />
  </svg>
);
export default ExcelColor_Icon;
