/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./TableHistoryGlobals.css";
import DropdownGlobals from "../DropdownlistFilterGlobal/Dropdownlist";
import DataTable from "react-data-table-component";

export default function TableHistoryIndividualSends({ Globals }) {
  const [selectedOption, setSelectedOption] = useState(
    "Más reciente al más antiguo"
  );
  const [sortedData, setSortedData] = useState([]);
  const [dataHistory, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    filterData();
  }, [Globals, selectedOption]);

  useEffect(() => {
    setCurrentPage(1); // Resetear la página actual cuando cambia la data filtrada
  }, [sortedData]);

  useEffect(() => {
    window.scrollTo(0, 0); // Desplazar al inicio de la página cuando cambia la página actual
  }, [currentPage]);

  function filterData() {
    let sortedGlobals = [...Globals]; // Crear una copia de Globals para no modificar el estado original directamente

    switch (selectedOption) {
      case "Hace una semana":
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        sortedGlobals = sortedGlobals.filter(
          (item) => new Date(item.timestamp) >= oneWeekAgo
        );
        break;
      case "Hace 15 días":
        const fifteenDaysAgo = new Date();
        fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
        sortedGlobals = sortedGlobals.filter(
          (item) => new Date(item.timestamp) >= fifteenDaysAgo
        );
        break;
      case "Hace un mes":
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        sortedGlobals = sortedGlobals.filter(
          (item) => new Date(item.timestamp) >= oneMonthAgo
        );
        break;
      case "Más reciente al más antiguo":
        sortedGlobals = sortedGlobals.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        break;
      case "Más antiguo al mas reciente":
        sortedGlobals = sortedGlobals.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );
        break;
      default:
        break;
    }

    setSortedData(sortedGlobals);
  }

  const tableCustomStyles = {
    table: {
      style: {
        zIndex: 0,
        height: "70vh",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgba(0, 73, 144, 0.2)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "20px",
        outline: "1px solid #FFFFFF",
      },
    },
  };
  const columns = [
    {
      name: "Enviado por",
      selector: (row) => row.sendby,
      sortable: true,
      cell: (row) => <strong>{row.sendby}</strong>,
    },
    {
      name: "Estatus",
      selector: (row) => row.status,
      sortable: true,
    },

    {
      name: "Fecha",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Periodo",
      selector: (row) => row.period,
      sortable: true,
    },
    {
      name: "Descripción",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Enviado a ",
      selector: (row) => row.sendto,
      sortable: true,
    },
  ];
  useEffect(() => {
    const data = [];
    for (let i = 0; i <= sortedData.length - 1; i++) {
      data.push({
        status: sortedData[i].status,
        sendby: sortedData[i].by.substring(0, 6),
        date: new Date(sortedData[i].timestamp).toLocaleString("es-MX", {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZone: "America/Mexico_City",
        }),
        period: sortedData[i].period,
        description: sortedData[i].description,
        sendto: sortedData[i].send_to,
      });
    }

    setData(data);
  }, [sortedData]);

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };
  const FilterButton = React.useMemo(
    () => (
      <DropdownGlobals
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
    ),
    [selectedOption]
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            backgroundColor: "red",
            borderRadius: "10px",
            backgroundColor: "white",
            /*             boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
             */ padding: "10px",
          }}
        >
          <DataTable
            title="Historial de envios "
            actions={[FilterButton]}
            fixedHeader
            columns={columns}
            data={dataHistory}
            pagination
            customStyles={{
              headCells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "#004990",
                  color: "#FFFFFF",
                },
              },
              table: {
                style: {
                  minWidth: "100%",
                  width: "60vw",
                  borderCollapse: "separate",
                  height: "70vh",
                },
              },
              cells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  border: "1px solid rgb(229, 237, 255)",
                },
              },
            }}
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      </div>
    </>
  );
}
