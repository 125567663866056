/* eslint-disable */
import React, { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import { Get_drivers_wallet } from "../../hooks/WalletDrivers/get_drivers_wallet";
import { TextField } from "@mui/material";
import UpdateButtonPayments from "./UpdateButtonTablePayments";
import { LoadingModal } from "../common";

export default function TableWalletsGains() {
  const [wallets, setWallets] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [Loading, setLoading] = useState(false);
  const filteredItems = wallets.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.lastname &&
        item.lastname.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.driver_id &&
        item.driver_id.toLowerCase().includes(filterText.toLowerCase()))
  );

  const SearchDriver = React.useMemo(() => {
    return (
      <TextField
        sx={{
          width: 200,
        }}
        id="filled-basic"
        label="Buscar conductor"
        variant="standard"
        onChange={(e) => setFilterText(e.target.value)}
        value={filterText}
      />
    );
  }, [filterText]);
  async function getWallets() {
    setLoading(true);
    const wallets = await Get_drivers_wallet(0);
    const filteredWallets = wallets.filter(
      (wallet) =>
        wallet?.wallet_info !== null && wallet?.wallet_info?.balance >= 0
    );
    setWallets(filteredWallets);
    setLoading(false);
  }
  useEffect(() => {
    getWallets();
  }, []);
  const columns = [
    {
      name: "Id",
      selector: (row) => row?.driver_id,
      sortable: true,
      cell: (row) => <strong>{row?.driver_id}</strong>,
    },
    {
      name: "Correo electrónico",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Conductor",
      selector: (row) => (row?.name + " " + row?.last_name).toUpperCase(),
      sortable: true,
      cell: (row) => <p>{(row?.name + " " + row?.last_name).toUpperCase()}</p>,
    },
    {
      name: "Balance",
      selector: (row) => row?.wallet_info?.balance,
      sortable: true,
      cell: (row) => (
        <strong style={{ color: "green" }}>
          ${row?.wallet_info?.balance?.toFixed(2) || 0}
        </strong>
      ),
    },
  ];

  const tableCustomStyles = {
    table: {
      style: {
        zIndex: 0,
        height: "70vh",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };
  const RefreshBtn = React.useMemo(
    () => <UpdateButtonPayments refresh={getWallets} env={null} />,
    []
  );

  if (Loading) {
    return (
      <LoadingModal
        onClose={!Loading}
        open={Loading}
        text={"Buscando conductores si deuda, estamos haciendo cuentas..."}
      />
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            backgroundColor: "red",
            borderRadius: "10px",
            backgroundColor: "white",
            /*             boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
             */ padding: "10px",
          }}
        >
          <DataTable
            title="Conductores con saldos positivos"
            fixedHeader
            columns={columns}
            data={filteredItems}
            pagination
            actions={[SearchDriver, RefreshBtn]}
            highlightOnHover
            customStyles={{
              headCells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "#004990",
                  color: "#FFFFFF",
                },
              },
              table: {
                style: {
                  minWidth: "100%",
                  width: "60vw",
                  borderCollapse: "separate",
                  height: "70vh",
                },
              },
              cells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  border: "1px solid rgb(229, 237, 255)",
                },
              },
            }}
            paginationComponentOptions={paginationComponentOptions}
            /*       expandableRows */
            selectableRows
            /*             expandableRowsComponent={ExpandedComponent} */
          />
        </div>
      </div>
    </>
  );
}
