/* eslint-disable */
export default function createPDF(name_doc_param, information) {
  let binaryString = window.atob(information.data);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  let blob = new Blob([bytes], { type: "application/pdf" });

  saveAs(blob, name_doc_param);
}
