/* eslint-disable */
import * as React from "react";

const Down_Icon = ({ color }) => (
  <svg width={12} height={7} fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 .655c0 .154-.06.303-.172.422L6.452 6.452a.592.592 0 0 1-.837 0L.174 1.012A.592.592 0 1 1 1.01.173l5.02 5.019 4.96-4.96A.592.592 0 0 1 12 .655Z"
      fill={color}
    />
  </svg>
);

export default Down_Icon;
