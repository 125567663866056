/* eslint-disable */
import { OptionButton } from "../button";
import { UpdateType } from "../../models/market-update";
import { useMarketUpdateStore } from "../../stores";

export function ConsortiumOptions() {
  const css =
    "disp-grid three-cols auto-rows col-gap margin-vertical margin-horizontal";
  const setUpdateType = useMarketUpdateStore(
    (state) => state.setMarketUpdateType
  );
  const updateType = useMarketUpdateStore((state) => state.marketUpdateType);

  return (
    <div className={css}>
      <OptionButton
        caption="Esta opción puede tardar"
        isSelected={updateType === UpdateType.All}
        onClick={() => setUpdateType(UpdateType.All)}
      >
        Actualizar todos los productos
      </OptionButton>

      <OptionButton
        caption="Cubre los últimos 2 días (incluyendo el día actual)"
        isSelected={updateType === UpdateType.RecentlyModified}
        onClick={() => setUpdateType(UpdateType.RecentlyModified)}
      >
        Actualizar productos recientemente modificados
      </OptionButton>

      <OptionButton
        caption="Se actualizará el estatus de los productos"
        isSelected={updateType === UpdateType.OutOfStock}
        onClick={() => setUpdateType(UpdateType.OutOfStock)}
      >
        Actualizar productos agotados
      </OptionButton>
    </div>
  );
}
