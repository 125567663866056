/* eslint-disable */
import { useState } from "react";
import "firebase/firestore";
import { dbFinance } from "../../services/firebaseFinance/firebaseFinance";
import { collection, getDocs } from "firebase/firestore";

function GetDataTable() {
  const [isLoading, setisLoading] = useState(false);
  const [dataTable, setdataTable] = useState([]);

  async function GetInfoTable(FilterBy) {
    setisLoading(true);
    const dataTable = [];
    const initial_colecction = FilterBy === 2 ? "Sendings" : "Exports";
    const sub_colecction =
      FilterBy === 0
        ? "GlobalStatementsDrivers"
        : FilterBy === 1
        ? "StatementsDrivers"
        : "StatementsDrivers";
    const subCollectionRef = collection(
      dbFinance,
      `project_CMS/${initial_colecction}/${sub_colecction}`
    );

    const querySnapshot = await getDocs(subCollectionRef);
    querySnapshot.forEach((doc) => {
      dataTable.push(doc.data());
    });
    setdataTable(dataTable);
    setisLoading(false);
    console.log("getData001", FilterBy, dataTable);
  }

  return { GetInfoTable, dataTable, isLoading };
}
export default GetDataTable;
