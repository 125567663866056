export const UpdateProductByID = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      aisle
      description
      id
      keywords
      mature
      netcontent
      offerprice
      price
      product_name
      product_type
      shop_id {
        id
      }
      status
      subtag
      tags
    }
  }
`;

export const CreateProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      aisle
      description
      id
      keywords
      mature
      netcontent
      offerprice
      price
      product_name
      product_type
      shop_id {
        id
      }
      status
      subtag
      tags
      url_image
    }
  }
`;

export const CreateUpdatesMarketLog = /* GraphQL */ `
  mutation CreateUpdatesMarketLog(
    $date: AWSDateTime!
    $from: String!
    $status: String!
    $type: String!
    $userId: String!
  ) {
    createUpdatesMarketLog(
      input: { date: $date, from: $from, status: $status, type: $type, userId: $userId }
    ) {
      id
    }
  }
`;
