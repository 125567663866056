/* eslint-disable */
import * as React from "react";

const Up_Icon = (props) => (
  <svg
    width={14}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m.819 6.196-.01.009C.613 6.414.5 6.685.5 6.97a1.092 1.092 0 0 0 1.863.775L6.97 3.14l4.665 4.666A1.091 1.091 0 1 0 13.18 6.26L7.739.821a1.092 1.092 0 0 0-1.545 0L.82 6.195Z"
      fill="#4F4F4F"
      stroke="#4F4F4F"
    />
  </svg>
);

export default Up_Icon;
