import { listShopName } from '../common/query-functions';

async function listShopNamed (shops = [], limit = 50, next = null, name) {
  const { items, nextToken } = await listShopName(limit, next, name);

  if (items.length > 0) {
    const newItems = shops.concat(items);
    if (nextToken === null) return newItems;
    return listShopNamed(newItems, limit, nextToken, name);
  }

  if (items.length === 0 && nextToken !== null) return listShopNamed(shops, limit, nextToken, name);

  return shops;
}

export default listShopNamed;
