/* eslint-disable */
import "./upload.css";
import * as Components from "../../components";
import { Toaster } from "react-hot-toast";
import { UpdateStep } from "../../models/market-update";
import { useMarketUpdateStore } from "../../stores";

export default function ConsortiumUpdate() {
  const marketUpdateStep = useMarketUpdateStore(
    (state) => state.marketUpdateStep
  );

  return (
    <div className="upload">
      <Toaster />

      <Components.ConsortiumUpdateHeader />

      <Components.ConsortiumUpdateStepper />

      {marketUpdateStep === UpdateStep.First && <Components.FirstStep />}

      {marketUpdateStep === UpdateStep.Second && <Components.SecondStep />}

      {marketUpdateStep === UpdateStep.Third && <Components.ThirdStep />}
    </div>
  );
}
