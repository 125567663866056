import { compareProducts, isEmptyArray } from '../../utilities';
import { createNikuProducts, updateNikuProducts } from '..';

export async function performUpdateProcess (products = []) {
  try {
    const updatedProducts = await updateNikuProducts(products);
    if (updatedProducts === null) throw new Error('ERROR: UPDATE PRODUCTS');

    const cleanProducts = updatedProducts.filter((product) => product !== null);
    if (products.length === cleanProducts.length) return 'SUCCESS';

    const remainingProducts = compareProducts(products, cleanProducts);
    if (isEmptyArray(remainingProducts)) return 'SUCCESS';

    const productsCreated = await createNikuProducts(remainingProducts);
    if (productsCreated === null) throw new Error('ERROR: CREATE PRODUCTS (NIKU-DB)');

    return 'SUCCESS';
  } catch (error) {
    return error.message;
  }
}
