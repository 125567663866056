/* eslint-disable */

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyA7sog1Q02SgDsTc_ztIQg9Av73JTVDOZE",
  authDomain: "niku-finance.firebaseapp.com",
  projectId: "niku-finance",
  storageBucket: "niku-finance.appspot.com",
  messagingSenderId: "1044218978623",
  appId: "1:1044218978623:web:7eb5ceb9be1f240019f38a",
  measurementId: "G-ZRME1ZZS7F",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, "Finance");

// Obtiene una instancia de Firestore
const dbFinance = getFirestore(app);

export { dbFinance };
