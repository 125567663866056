export async function Get_drivers_wallet(offset, result = []) {
  let limit = 1000;
  let apiKey = process.env.REACT_APP_HAURA_KEY;

  const endpoint = `https://superb-mackerel-18.hasura.app/api/rest/get-drivers-wallet/${limit}/${offset}`;
  try {
    const response = await fetch(endpoint, {
      headers: {
        "x-hasura-admin-secret": `${apiKey}`,
      },
    });
    const newData = await response.json();

    if (newData?.drivers.length === 0) {
      return result;
    }

    result = result.concat(newData?.drivers);
    return Get_drivers_wallet(offset + limit, result);
  } catch (error) {
    console.error("Error al obtener los datos de viajes:", error);
    return result;
  }
}
