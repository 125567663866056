/* eslint-disable */
import * as React from 'react';

const Download_Icon = (props) => (
  <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.274 11.836H3.979c-.365 0-.677-.128-.936-.386a1.262 1.262 0 0 1-.39-.929V1.315c0-.362.13-.671.39-.929.26-.257.571-.386.936-.386h4.095a1.322 1.322 0 0 1 .928.378l3.217 3.19a1.277 1.277 0 0 1 .381.92v6.033c0 .362-.13.672-.39.93-.26.257-.572.385-.936.385ZM7.958 1.316H3.979v9.205h7.295V4.603H8.62a.644.644 0 0 1-.472-.189.634.634 0 0 1-.191-.469v-2.63Zm-6.632 13.15c-.364 0-.677-.128-.936-.385a1.262 1.262 0 0 1-.39-.93V4.604c0-.186.064-.343.191-.469a.644.644 0 0 1 .472-.189.646.646 0 0 1 .663.658v8.549h6.632a.645.645 0 0 1 .663.657.645.645 0 0 1-.663.658H1.326Z"
      fill="#fff"
    />
  </svg>
);

export default Download_Icon;
