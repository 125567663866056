/* eslint-disable */
import * as React from "react";
import Search_Icon from "../icons/Search_Icon";

export default function InputSearch({
  onClick = () => null,
  setName,
  name,
  placeholder,
  controlWidth,
}) {
  const [isInputVisible, setInputVisible] = React.useState(false);

  const searchFormControl = {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    gap: "5px",
    width: isInputVisible ? "20rem" : "auto",
    borderRadius: "10px",
    backgroundColor: "#E5EDFF",
    float: isInputVisible ? "left" : "none",
    padding: "15px",
    height: "1.3rem",
    marginLeft: 10,
    cursor: "pointer",
  };

  const inputStyle = {
    backgroundColor: "#E5EDFF",
    border: "none",
    outline: "none",
    fontSize: "18px",
    fontWeight: "500",
    display: isInputVisible ? "block" : "none",
  };

  const closeButtonStyle = {
    backgroundColor: "#E5EDFF",
    border: "none",
    outline: "none",
    fontSize: "18px",
    fontWeight: "500",
    cursor: "pointer",
  };

  const handleToggleInput = () => {
    setInputVisible(!isInputVisible);
  };

  return (
    <div style={searchFormControl}>
      {!isInputVisible && <Search_Icon onClick={handleToggleInput} />}
      {isInputVisible && (
        <>
          <input
            style={inputStyle}
            type="text"
            placeholder={placeholder}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(event) =>
              event.key === "Enter" ? onClick(name) : null
            }
            value={name}
          />
          <button style={closeButtonStyle} onClick={handleToggleInput}>
            X
          </button>
        </>
      )}
    </div>
  );
}
