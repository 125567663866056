import { create } from 'zustand';

import { createInputFieldsSlice } from './create-input-fields-slice';
import { createLoginProcessSlice } from './create-login-process-slice';
import { createLoginErrorSlice } from './create-login-error-slice';
import { createLoginResetSlice } from './create-login-reset-slice';

export const errorLoginMessages = [
  'Por favor, ingrese su correo electrónico y contraseña.',
  'El correo electrónico o la contraseña son incorrectos.'
];

export const useLoginStore = create((...args) => ({
  ...createInputFieldsSlice(...args),
  ...createLoginProcessSlice(...args),
  ...createLoginErrorSlice(...args),
  ...createLoginResetSlice(...args)
}));
