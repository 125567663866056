const start = new Date().toISOString();
const end = new Date().toISOString();

function buildCuttingStatusForm (dateStart = start, dateEnd = end) {
  const formData = new FormData();
  formData.append('dateStart', dateStart);
  formData.append('dateEnd', dateEnd);
  return formData;
}

export default buildCuttingStatusForm;
