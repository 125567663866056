/* eslint-disable */
import { Fragment, useState } from "react";

import { ArrowDropDownIcon, ArrowRightIcon } from "../icons";

export function AccordionBtn({ text = "", children = <span>Continue</span> }) {
  const [isOpen, setIsOpen] = useState(true);
  const handleClick = () => setIsOpen(!isOpen);

  return (
    <>
      <button className="accordion-btn" onClick={handleClick}>
        <span className="accordion-btn-lbl">
          {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          {text}
        </span>
      </button>
      {isOpen && <div className="accordion-btn-content">{children}</div>}
    </>
  );
}
