const orange = {
  red: 0.914,
  green: 0.353,
  blue: 0.075,
  alpha: 1,
};

const white = {
  red: 1,
  green: 1,
  blue: 1,
  alpha: 1,
};

const green = {
  red: 0.573,
  green: 0.816,
  blue: 0.314,
  alpha: 1,
};

const yellow = {
  red: 1,
  green: 0.753,
  blue: 0,
  alpha: 1,
};

const cells = [
  "Id de viaje",
  "Fecha",
  "Forma de pago",
  "Datos fiscales",
  "RFC",
  "Estado",
  "Tarifa dinamica",
  "Tarifa base",
  "Tarifa por duración de viaje",
  "Tarifa por distancia",
  "Tarifa del viaje",
  "Cobro comisiones",
  "Base cobro",
  "Tarjeta crédito",
  "Openpay/Conekta",
  "Total viaje sin iva",
  "IVA (16%)",
  "IVA (8%)",
  "IVA (16%)",
  "ISR(2.1)",
  "ISR(20%)",
  "PAGO P",
  "COMISIÓN (15%)",
  "Cobranza efectivo",
  "Cobranza Tarjeta cre",
];

function CheckColorinTitle(title) {
  const specifiedgreenCells = [
    "Tarifa del viaje",
    "Cuota de solicitud",
    "IVA (16%)",
    "DIF.RET IVA",
    "ISR(2.1)",
    "ISR(20%)",
    "DIF RET ISR",
    "Uso de plataforma (5%) INCLUYE IVA",
    "COMISIÓN (15%)",
  ];
  const specifiedyellowCells = [
    "TOTAL NETO DEPÓSITO",
    "Total a recibir conductor",
    "Total a recibir niku",
  ];
  if (specifiedgreenCells.includes(title)) {
    return green;
  } else if (specifiedyellowCells.includes(title)) {
    return yellow;
  } else {
    return orange;
  }
}

function CellConstruction() {
  const newCells = [];
  cells.forEach((element) => {
    newCells.push({
      userEnteredValue: {
        stringValue: element,
      },
      userEnteredFormat: {
        backgroundColorStyle: {
          rgbColor: CheckColorinTitle(element),
        },
        textFormat: {
          bold: true,
          foregroundColorStyle: {
            rgbColor: white,
          },
          fontSize: 9,
        },
        horizontalAlignment: "CENTER",
        verticalAlignment: "MIDDLE",
        wrapStrategy: "LEGACY_WRAP",
      },
    });
  });
  return newCells;
}

export const header_global = {
  startRow: 1,
  startColumn: 0,
  rowData: [
    {
      values: CellConstruction(),
    },
  ],
};
