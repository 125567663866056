const blue = {
  red: 0.851,
  green: 0.945,
  blue: 0.953,
  alpha: 1,
};
const black = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 1,
};

const cells = [
  "Conductor",
  "Banco",
  "Cuenta clabe",
  "Semana",
  "Saldo final a favor del conductor",
  "Saldo final por cobrar al conductor",
  "Estatus",
  "Fecha de pago",
  "Importe depositado",
  "Saldo final",
];

function CellConstruction() {
  const newCells = [];
  cells.forEach((element) => {
    newCells.push({
      userEnteredValue: {
        stringValue: element,
      },
      userEnteredFormat: {
        backgroundColorStyle: {
          rgbColor: blue,
        },
        textFormat: {
          bold: true,
          foregroundColorStyle: {
            rgbColor: black,
          },
          fontSize: 10,
        },
        horizontalAlignment: "CENTER",
        verticalAlignment: "MIDDLE",
        wrapStrategy: "LEGACY_WRAP",
      },
    });
  });
  return newCells;
}

export const header_listDriversWeek = {
  startRow: 5,
  startColumn: 1,
  rowData: [
    {
      values: CellConstruction(),
    },
  ],
};
