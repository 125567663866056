/* eslint-disable */
import { listDeliverymenName } from '../common/query-functions';

async function listDeliverymenNamed(deliverymen = [], limit = 50, next = null, name) {

  const { items, nextToken } = await listDeliverymenName(limit, next, name);

  if (items.length > 0) {
    const newItems = deliverymen.concat(items);
    if (nextToken === null) return newItems;
    return listDeliverymenNamed(newItems, limit, nextToken, name);
  }

  if (items.length === 0 && nextToken !== null)
    return listDeliverymenNamed(deliverymen, limit, nextToken, name);

  return deliverymen;
}

export default listDeliverymenNamed;
