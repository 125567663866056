/* eslint-disable */
import style from "./TopBar.module.css";
import { PowerSettingsNewIcon } from "../../layout/icons";
import { useAuth } from "../../hooks";

export function TopBar() {
  const { signOutUser } = useAuth();

  return (
    <nav className={style.topBarLay}>
      <div className={style.topBar}>
        <p className="black-txt medium-txt subtitle-txt title-common">
          Actualizar productos
        </p>
        <div>
          <button
            className={style.topBarBtn}
            type="button"
            onClick={signOutUser}
          >
            <PowerSettingsNewIcon />
            <p className={style.topBarBtnLbl}>Cerrar sesión</p>
          </button>
        </div>
      </div>
    </nav>
  );
}
