/* eslint-disable */

import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function DatePickerMaterialUI({
  label,
  initialDate,
  setInitialDate,
}) {
  const today = dayjs();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} localeText={"es"}>
      <DatePicker
        value={initialDate}
        onChange={(newValue) => setInitialDate(newValue?.$d)}
        label={label}
        displayWeekNumber
        orientation="portrait"
        /*        maxDate={today.toDate()} */
      />
    </LocalizationProvider>
  );
}
