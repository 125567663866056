/* eslint-disable */
import { useLoginStore } from "../../../stores/login/use-login-store";
import "./auth-components.css";

export function EmailInput() {
  const email = useLoginStore((state) => state.email);
  const setEmail = useLoginStore((state) => state.setEmail);

  return (
    <input
      className="login-form-input"
      placeholder="correo@ejemplo.com"
      type="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
  );
}
