const blue = {
  red: 0.851,
  green: 0.945,
  blue: 0.953,
  alpha: 1,
};
const black = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 1,
};

export function title_dates(dates) {
  return {
    startRow: 3,
    startColumn: 1,
    rowData: [
      {
        values: [
          {
            userEnteredValue: {
              stringValue: "Fecha",
            },
            userEnteredFormat: {
              textFormat: {
                bold: true,
                foregroundColorStyle: {
                  rgbColor: black,
                },
                fontSize: 10,
              },
              horizontalAlignment: "LEFT",
              verticalAlignment: "MIDDLE",
              wrapStrategy: "LEGACY_WRAP",
            },
          },
          {
            userEnteredValue: {
              stringValue:
                dates[0].toISOString().substring(0, 10) +
                " a " +
                dates[1].toISOString().substring(0, 10),
            },
            userEnteredFormat: {
              textFormat: {
                bold: true,
                foregroundColorStyle: {
                  rgbColor: black,
                },
                fontSize: 10,
              },
              horizontalAlignment: "LEFT",
              verticalAlignment: "MIDDLE",
              wrapStrategy: "LEGACY_WRAP",
            },
          },
        ],
      },
    ],
  };
}
