/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import Close_Icon from "../icons/Close_Icon";
import getDatesOrders from "../../services/db/list-deliveryOrders";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import "../../components/styled/style-cardDelivery.css";
import TableTravelsDelivery from "./TableTravelsDelivery";

const style_loading = {
  position: "absolute",
  top: "50%",
  left: "50%",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
};

export default function HistoryModalDelivery({
  openShowOrders,
  closeShowOrders,
  item,
}) {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState(item.orders);
  const [copyOrders, setCopyOrders] = useState([]);
  const levelDelivery = item?.levels_byAccount || {};
  const levelName = levelDelivery?.items[0]?.id_level?.name || "Bronce";

  const ref = useRef(null);

  useEffect(() => {
    if (openShowOrders) {
      ref.current?.showModal();
      document.body.classList.add("modal-open"); // prevent bg scroll
    } else {
      ref.current?.close();
      document.body.classList.remove("modal-open");
    }
  }, [openShowOrders]);

  /* async function getOrders(idDealer) {
    setIsLoading(true);
    const orders = await getDatesOrders(1000, null, idDealer);
    const copyOrders = [].concat(orders);
    setCopyOrders(copyOrders);
    setOrders(orders);
    setIsLoading(false);
  }

  useEffect(() => {
    getOrders(item.id);
  }, [item.id]); */
  async function FilterTravels() {
    if (startDate && endDate) {
      var objetosFiltrados = item.orders.filter(function (objeto) {
        var fecha = new Date(objeto?.updatedAt).getTime();
        return fecha >= startDate?.getTime() && fecha <= endDate?.getTime();
      });

      setOrders(objetosFiltrados);
    }
  }

  /* if (isLoading) {
    return (
      <dialog
        className="modal"
        open={openShowOrders}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style_loading}>
          <LoadingSpinner />
        </div>
      </dialog>
    );
  } */
  function checkLevel(level) {
    if (level === "training") return "Bronce";
    if (level === "copper") return "Bronce";
    if (level === "levelName") return "Plata";
    if (level === "gold") return "Oro";
  }

  return (
    <dialog className="modal" open={openShowOrders} contentLabel="Modal">
      <div className="content-dialog">
        <div className="header-dialog">
          <div className="tooltip">
            <span className="tooltiptext">
              <h2>Nombre:{item.name + " " + item.last_name}</h2>
              <p>
                <span className="label">Id:</span> {item.id}
              </p>
              <p>
                <span className="label">Vehículo:</span>{" "}
                {item.delivery_transport}
              </p>
              <p>
                <span className="label">Registro aprobado:</span>{" "}
                {item.register_approved ? "Si" : "No"}
              </p>
              <p>
                <span className="label">Nivel:</span>{" "}
                {checkLevel(levelName) || "Bronce"}
              </p>
            </span>
            Información de repartidor
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setDateRange={setDateRange}
              disabled={false}
              maxDate={new Date()}
            />
            <button
              style={{
                border: "none",
                backgroundColor: "#e95a13",
                color: "white",
                fontSize: "14px",
                fontWeight: "600",
                borderRadius: "5px",
                cursor: "pointer",
                width: "100px",
              }}
              onClick={() => FilterTravels()}
            >
              Filtrar
            </button>
          </div>
          <button
            style={{
              border: "none",
              backgroundColor: "#e95a13",
              color: "white",
              fontSize: "14px",
              fontWeight: "600",
              borderRadius: "5px",
              cursor: "pointer",
              width: "100px",
              height: "2.5rem",
            }}
            onClick={() => setOrders(item.orders)}
          >
            Ver todos
          </button>
          <div>
            <button
              className="button-close"
              aria-label="Close"
              onClick={closeShowOrders}
            >
              <Close_Icon />
            </button>
          </div>
        </div>

        <div className="content-table">
          {orders.length > 0 ? (
            <TableTravelsDelivery
              Travels={orders}
              levelDelivery={levelDelivery || ""}
            />
          ) : null}

          {/*      <div className="footer-card-modal">
            <p>
              La ordenes que van en ceros cuentan con mala alimentación desde la
              base de datos, no se pueden calcular.
            </p>
          </div> */}
        </div>
      </div>
    </dialog>
  );
}
