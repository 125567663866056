import React, { useEffect, useState } from "react";
import {
  FaChartBar,
  FaUsers,
  FaMoneyBill,
  FaShoppingCart,
  FaSpinner,
  FaIdCard,
} from "react-icons/fa";
import DatePickerMaterialUI from "../../components/DatePickers/DatePickerMaterialUI";
import DatePickerEndMaterialUI from "../../components/DatePickers/DatePickerEndMaterialUI";
import GetDrivers from "../../hooks/DriversNikuCar/getDrivers";
import { totalgetNiku } from "../../hooks/Google_spreadsheet_0Auth2.0/CalculateFunctions/CalculateFunctions";
import { getEquipmentsDrivers } from "../../hooks/List_Equipments_Drivers/ListEquipments";
import { Tooltip } from "@mui/material";
import { Get_drivers_wallet } from "../../hooks/WalletDrivers/get_drivers_wallet";
import TableTravelsDrivers from "../../components/TableTravelsDrivers/TableTravelsDrivers";

const Analytics = () => {
  const [drivers_Found, setDriverfound] = useState([]);
  const [LoadingDrivers, setLoading] = useState(false);
  const { getTravelsHasura } = GetDrivers();
  const [initialDate, setInitialDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [Gains, setGains] = useState(0);
  const [countTravel, setCountTravel] = useState(0);
  const [equipment, setEquipments] = useState([]);
  const [datesRanges, setDateRanges] = useState([new Date(), new Date()]);
  const [LodingDebtsWallet, setLoadingDebtsWallet] = useState(false);
  const [countCard, setCountCard] = useState(0);
  const [wallets, setWallets] = useState([]);
  const [allTravels, setAllTravels] = useState([]);
  async function getEquipments() {
    const equipments = await getEquipmentsDrivers(0);
    setEquipments(equipments);
  }
  useEffect(() => {
    getEquipments();
  }, []);
  const excludedDrivers = [
    "WKQ4T5IaRoX37a3RNtQXjzJJRwT2", // Alejandro Guadalajara
    "KoIFKGeGykWIhAlJ8qJQz05eAR53", // Omar
    "yWI9ZsZIpdROvwoeCfCELwa6eWC3", //Alex Garcia
  ];
  async function getTravelsHasuras(env) {
    setLoading(true);
    try {
      const response_hasura = await getTravelsHasura(0, "Terminado");
      const groupedData = {};
      let gains = 0;
      let count = 0;
      let countCard = 0;
      let Travels = [];
      response_hasura.forEach((data_travel) => {
        const {
          driver_data,
          environment,
          cuota_de_solicitud,
          tarifa_iva,
          impuesto_isr,
          uso_plataforma,
          tarifa_comision,
          payment_method,
        } = data_travel;

        if (driver_data === null) return;
        const environmentHasura = Number(environment);
        if (environmentHasura === env) {
          const { driver_id, ...rest } = driver_data;
          if (excludedDrivers.includes(driver_id)) return;
          Travels = [...Travels, data_travel];
          if (!groupedData[driver_id]) {
            groupedData[driver_id] = {
              driver_info: rest,
              travel_matches: [],
            };
          }

          groupedData[driver_id].travel_matches.push(data_travel);
          gains =
            gains +
            totalgetNiku(
              cuota_de_solicitud,
              tarifa_iva,
              impuesto_isr,
              uso_plataforma,
              tarifa_comision
            );
          count = count + 1;
          if (payment_method.toUpperCase() !== "EFECTIVO") {
            countCard = countCard + 1;
          }
        }
      });

      for (const driverId in groupedData) {
        if (groupedData.hasOwnProperty(driverId)) {
          groupedData[driverId].travel_matches.sort(
            (a, b) => b.time_request - a.time_request
          );
        }
      }

      const result = Object.keys(groupedData).map((driver_id) => ({
        driver_id: driver_id,
        ...groupedData[driver_id],
      }));
      result.sort((a, b) => {
        if (a.travel_matches.length > 0 && b.travel_matches.length > 0) {
          return (
            b.travel_matches[0].time_request - a.travel_matches[0].time_request
          );
        } else {
          return 0;
        }
      });

      setAllTravels(Travels.sort((a, b) => b.time_request - a.time_request));
      setCountCard(countCard);
      setCountTravel(count);
      setGains(gains);
      setDriverfound(result);
      setDateRanges([new Date(), new Date()]);
      setLoading(false);
    } catch (error) {
      setDriverfound([]);
      setLoading(false);
    }
  }
  async function getWallets() {
    setLoadingDebtsWallet(true);
    const wallets = await Get_drivers_wallet(0);
    const filteredWallets = wallets.filter(
      (wallet) =>
        wallet?.wallet_info !== null && wallet?.wallet_info?.balance < 0
    );
    setWallets(filteredWallets);
    setLoadingDebtsWallet(false);
  }

  useEffect(() => {
    document.title = `SGFC - Analítica`;
    getTravelsHasuras(2);
    getWallets();
  }, []);

  return (
    <div style={{ padding: "10px", backgroundColor: "white", height: "100vh" }}>
{/* 
      <div style={{ gap: "20px", display: "flex", marginBottom: "20px" }}>
        <DatePickerMaterialUI
          label="Fecha inicial"
          initialDate={initialDate}
          setInitialDate={setInitialDate}
        />
        <DatePickerEndMaterialUI
          label="Fecha final"
          initialDate={endDate}
          setEndDate={setEndDate}
        />
      </div> */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: "20px",
        }}
      >
        {renderCard(
          "Conductores con actividad económica",
          <FaUsers />,
          LoadingDrivers ? <FaSpinner /> : drivers_Found.length
        )}
        {renderCard(
          "Viajes pagados con tarjeta",
          <FaIdCard />,
          LoadingDrivers ? <FaSpinner /> : countCard
        )}
        {renderCard(
          "Saldos pendientes por cobrar",
          <FaChartBar />,
          LodingDebtsWallet ? <FaSpinner /> : wallets.length
        )}
        {renderCard(
          "Total de viajes terminados y pagados",
          <FaShoppingCart />,
          LoadingDrivers ? <FaSpinner /> : countTravel
        )}
        {renderCard(
          "Equipamientos otorgados",
          <FaChartBar />,
          LoadingDrivers ? <FaSpinner /> : equipment.length
        )}
        {renderCard(
          "Ingresos estimados (Suma de comisiones)",
          <FaMoneyBill />,
          LoadingDrivers ? <FaSpinner /> : "$" + Gains.toFixed(2)
        )}
      </div>
      <TableTravelsDrivers Travels={allTravels} />
    </div>
  );
};

const renderCard = (title, icon, value) => {
  return (
    <Tooltip title={title} placement="top" arrow>
      <div
        style={{
          border: "1px solid #ddd",
          padding: "20px",
          borderRadius: "8px",
          border: "1px solid rgb(229, 237, 255)",
          textAlign: "center",
          cursor: "pointer",
          transition: "all 0.3s ease",
        }}
      >
        <h4>{title}</h4>
        <div
          style={{ fontSize: "24px", marginBottom: "10px", color: "#004990" }}
        >
          {icon}
        </div>
        <p style={{ color: "#004990", fontWeight: "bold" }}>{value}</p>
      </div>
    </Tooltip>
  );
};

export default Analytics;
