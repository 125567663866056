import { refactoringDates } from "../CalculateFunctions/CalculateFunctions";

const black = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 1,
};

function TitleConstruction() {
  const newCells = [];
  newCells.push({
    userEnteredValue: {
      stringValue: "RESUMEN SEMANAL",
    },
    userEnteredFormat: {
      textFormat: {
        bold: true,
        foregroundColorStyle: {
          rgbColor: black,
        },
        fontSize: 8,
      },
      horizontalAlignment: "CENTER",
      verticalAlignment: "MIDDLE",
      wrapStrategy: "LEGACY_WRAP",
    },
  });
  return newCells;
}

function DateConstruction(rangesDate, weekselect) {
  const newDateCells = [];
  let date = refactoringDates(rangesDate, weekselect);


  newDateCells.push({
    userEnteredValue: {
      stringValue: date,
    },
    userEnteredFormat: {
      textFormat: {
        bold: true,
        foregroundColorStyle: {
          rgbColor: black,
        },
        fontSize: 8,
      },
      horizontalAlignment: "CENTER",
      verticalAlignment: "MIDDLE",
      wrapStrategy: "LEGACY_WRAP",
    },
  });
  return newDateCells;
}

export const titleheader_driversummary = {
  startRow: 1,
  startColumn: 2,
  rowData: [
    {
      values: TitleConstruction(),
    },
  ],
};

export function dateheader_driversummary(rangesDate, weekselect) {
  return {
    startRow: 2,
    startColumn: 2,
    rowData: [
      {
        values: DateConstruction(rangesDate, weekselect),
      },
    ],
  };
}
