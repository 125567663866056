import { buildCuttingStatusForm } from '../../utilities';
import { post } from '../request';

const path = require('../../urls.json');

async function updateCuttingStatus (dateStart = '', dateEnd = '') {
  try {
    const url = `${path.REACT_APP_UNSPLASH_URLSERVER}cuttingStatus`;
    const cuttingStatusForm = buildCuttingStatusForm(dateStart, dateEnd);
    const res = await post(url, cuttingStatusForm);
    return res.success;
  } catch (error) {
    return false;
  }
}

export default updateCuttingStatus;
