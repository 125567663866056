/* eslint-disable */
import { Outlet, Navigate } from "react-router-dom";

export function ProtectedRoute({
  isAllowed = false,
  redirectPath = "/",
  location = null,
  children = null,
}) {
  if (!isAllowed) {
    return <Navigate replace state={{ from: location }} to={redirectPath} />;
  }

  return children || <Outlet />;
}
