import path from '../../urls.json';
import { post } from '../request';

import { createConsortiumLog } from './create-consortium-log';

export async function uploadErpFile ({ file, fileName }) {
  try {
    const url = `${path.REACT_APP_UNSPLASH_URLSERVER}upload`;
    const formData = new FormData();
    formData.append('file', file, fileName);

    const res = await post(url, formData);

    return res;
  } catch (err) {
    await createConsortiumLog({ status: 'ERROR', type: 'UPLOAD_FILE' }); 
    return false;
  }
}
