export const createUiSlice = (set) => ({
  marketUpdateButtonIsDisabled: true,
  marketUpdateInputIsDisabled: false,
  marketUpdateStarted: false,
  setMarketUpdateButtonIsDisabled: (isDisabled) =>
    set({ marketUpdateButtonIsDisabled: isDisabled }),
  setMarketUpdateInputIsDisabled: (isDisabled) =>
    set({ marketUpdateInputIsDisabled: isDisabled }),
  setMarketUpdateStarted: (marketUpdateStarted) => set({ marketUpdateStarted }),
  resetMarketUpdateUi: () => set({
    marketUpdateButtonIsDisabled: true,
    marketUpdateInputIsDisabled: false,
    marketUpdateStarted: false
  })
});
