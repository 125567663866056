import { buildEmailForm } from '../../utilities/build-data';
import { post } from '../request';
const path = require('../../urls.json');

const defaultData = {
  email: 'luisdhr.11@gmail.com',
  recipientName: 'Usuario de prueba',
  subject: 'Niku Tecnologías SA de CV',
  bodyText: 'Este es un correo de prueba',
  bodyHtml: '<h1>Este es un correo de prueba</h1>'
};

async function sendEmail (emailData = defaultData) {
  try {
    const url = `${path.REACT_APP_UNSPLASH_URLSERVER}email`;
    const emailForm = buildEmailForm(emailData);
    const res = await post(url, emailForm);
    return res.message;
  } catch (error) {
    return 'Error al enviar el correo electrónico.';
  }
}

export default sendEmail;
