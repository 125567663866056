/* eslint-disable */
import React from "react";
import "./UpdateButton.css"; // Importa el archivo CSS para los estilos
import CachedIcon from "@mui/icons-material/Cached";

const UpdateButtonPayments = ({ refresh = () => {}, env = 2 }) => {
  return (
    <button onClick={() => refresh(env)} className="update-button">
      <CachedIcon />
    </button>
  );
};

export default UpdateButtonPayments;
