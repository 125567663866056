/**
 * It checks if the user is logged in and if they are, it checks if they are
 * a CRM user or a market user. If they are a CRM user, it returns the origin
 * of the page they were trying to access. If they are a market user, it
 * returns the updateProducts page. If they are not logged in, it returns an
 * empty string and the user is redirected to the login page.
 */
export function checkAuth(
  user = { username: "", role: "" },
  location = { state: { from: { pathname: "" } } }
) {
  if (user.role === "crm") {
    let origin = location.state?.from?.pathname || "/ListShops";

    if (origin === "/updateProducts" || origin === "/ListDrivers") {
      origin = "/ListShops";
    }

    return origin;
  }
  if (user.role === "accountant") {
    let origin = location.state?.from?.pathname || "/ListShops";
    if (origin === "/updateProducts" || origin === "/ListShops") {
      origin = "/ListDrivers";
    }
    return origin;
  }
  if (user.username === process.env.REACT_APP_MARKET_ID) {
    return "/updateProducts";
  }

  return "";
}
