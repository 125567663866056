/* eslint-disable */
function Gatewaycalculatecoms() {
  let comPasarelaMXN = 2.5;
  let tasaPasarela = 0.029;

  function calculatetarifserviceIva(amount) {
    let numerator = 25000;
    let denominator = 24159;
    let secondNum = 72500;
    let result = amount * (numerator / denominator) + secondNum / denominator;
    return (result * 0.029 + 2.5) * 0.16;
  }

  function calculatetarifservice(amount) {
    let numerador = 25000;
    let denominador = 24159;
    let secondNum = 72500;
    let result = amount * (numerador / denominador) + secondNum / denominador;

    return result - amount - calculatetarifserviceIva(amount);
  }

  function checkPorcentage(porcentage) {
    let comDelivery = 0;
    let comFacetoFace = 0;
    if (porcentage !== '') {
      let jsonPorcentage = JSON.parse(porcentage);
      comDelivery = jsonPorcentage.delivery.substring(0, jsonPorcentage.delivery.length - 1).trim();
      comFacetoFace = jsonPorcentage.faceToface
        .substring(0, jsonPorcentage.faceToface.length - 1)
        .trim();
      if (comDelivery.length === 2) {
        comDelivery = parseFloat('0.' + comDelivery);
      } else {
        comDelivery = parseFloat('0.0' + comDelivery);
      }
      if (comFacetoFace.length === 2) {
        comFacetoFace = parseFloat('0.' + comFacetoFace);
      } else {
        comFacetoFace = parseFloat('0.0' + comFacetoFace);
      }
      return [comDelivery, comFacetoFace];
    }
  }

  function checkZone(zone) {
    let zoneId = zone || '';
    let newZone = '';
    if (zoneId !== '') {
      newZone = zoneId;
    } else { 
      newZone = 'No tiene zona asignada';
    }
    return newZone;
  }

  function checkdocuments(docs) {
    let newDocs = [];
    if (docs !== '') {
      newDocs = JSON.parse(docs);
    } else {
      newDocs = [];
    }
    return newDocs;
  }

  function checkNameDealer(order) {
    let idDealerDb = order.delivery_man_id || '';
    let name = '';

    if (idDealerDb !== '') {
      name = order.delivery_man_id.name;
    } else {
      name = 'No hay repartidor en esta orden';
    }

    return name;
  }

  function calculateReten(IVA, comIva, isr, subtotalmcc) {
    let subtractionmcc = subtotalmcc / IVA;
    let iva8 = subtractionmcc * comIva;
    let iva1 = subtractionmcc * isr;
    return { ivas: [iva8, iva1], sum: iva8 + iva1, subwithoutIVAs: subtractionmcc };
  }

  function validatedDocs(docs, rfc, subtotalmcc) {
    let reten = {};
    if (docs !== []) {
      // Entra a esta condición si el campo ya existe
      if (docs.contrato_fisica !== '' && docs.contrato_fisica !== undefined) {
        // Condición de persona FISICA
        // Cálculo de retenciones sin importar método de pago IVA 8% E ISR 1%
        // Persona fisica sin RFC aumentan sus retenciones 20% de ISR Y 16% DE IVA+
        // console.log('Este es el resultado de res', rfc, idshop);
        if (rfc !== null && rfc !== undefined && rfc !== '') {
          reten = calculateReten(1.16, 0.08, 0.021, subtotalmcc);
        } else {
          reten = calculateReten(1.16, 0.16, 0.2, subtotalmcc);
        }
      } else if (docs.contrato_moral !== '' && docs.contrato_moral !== undefined) {
        // Condición de persona MORAL
        // Cálculo de retenciones sin importar método de pago IVA 8% E ISR 1%
        reten = calculateReten(1.16, 0.08, 0.021, subtotalmcc);
      } else {
        // LLega el JSON pero con datos vacios
        // El calculo se realiza tomandolas en cuenta como personas FISICAS por defecto
        reten = calculateReten(1.16, 0.08, 0.021, subtotalmcc);
      }
    } else {
      // No existe el json en la bd
      // El calculo se realiza tomandolas en cuenta como personas FISICAS por defecto
      reten = calculateReten(1.16, 0.08, 0.021, subtotalmcc);
    }
    return { sum: reten.sum, iva: reten.ivas, subwithoutIVAs: reten.subwithoutIVAs };
  }

  function calculatetarife(amount) {
    let numerador = 25000;
    let denominador = 24159;
    let secondNum = 72500;
    let result = amount * (numerador / denominador) + secondNum / denominador;
    return result - amount;
  }

  function formatDate(d) {
    return d.substring(0, 10);
  }

  function checkorderType(data) {
    let newOrderType = '';
    if (data.orderType === 'PRODUCTO') {
      if (!data.require_service) {
        newOrderType = 'RECOGER';
      } else {
        if (data.order_case === 5) {
          newOrderType = 'TICKET';
        } else {
          if (data.shop_comments === 'SOLUTIONS' || data.shop_comments === 'WISHES') {
            newOrderType = data.shop_comments || '';
          } else {
            newOrderType = 'REPARTIDOR';
          }
        }
      }
    } else {
      if (data.orderType.toUpperCase() === 'SERVICE') {
        newOrderType = 'SERVICIO';
      } else {
        newOrderType = 'PRESENCIAL';
      }
    }
    return newOrderType || '';
  }

  function cnvFixed(num) {
    let newNum = num || 0;
    return newNum.toFixed(2);
  }
  function cnvNum(string) {
    return parseFloat(string) || 0;
  }

  function checkOrders(idShop, info_orders, categoryShop, percentage, docs, rfc, info_shop) {

    let platform_use = info_orders.platform_use || 0;
    console.log("🚀 ~ file: calculate-Coms.js:166 ~ checkOrders ~ platform_use:", platform_use)

    let docsValidated = Array.isArray(docs) ? docs : [];
    let category = categoryShop || '';
    let newArreOrders = [];

    /* Totales por tienda */
    let total_import_sale = 0;
    let total_discount_cl = 0;
    let total_mcc = 0;
    let total_com_niku = 0;
    let total_subwithoutIVAs = 0;
    let total_retentionIVA = 0;
    let total_retentionISR = 0;
    let comSendCL = 0;
    let comSendShop = 0;
    let total_propine = 0;
    let total_dispersion = 0;
    let total_collect = 0;

    newArreOrders.push({
      idShop: idShop,
      shopName: info_shop.shop_name || '',
      category: info_shop.category || '',
    });

    for (let i in info_orders) {
      let order = info_orders[i] || '';
      let orderType = '';
      let tasa = 0;
      let ivaPas = 0;
      let moneyOnpasarela = 0;
      let commisionPasarela = 0;
      let sendShop = 0;
      let method_pay = '';
      let totalDb = 0;
      let discount = 0;
      let mcc = 0;
      let subAmount = 0;
      let subAmountWithoutmcc = 0;
      let amountPlusServiceCom = 0;
      let commission = 0;
      let totalDispersion = 0;
      let subwithoutIVAs = 0;
      let final_total = 0;
      let colletTotal = 0;
      let retenIva = 0;
      let retenIsr = 0;

      if (order !== '') {
        let cededPercentage = order.shop_id?.percentage_traded.value || 0;
        final_total = order.final_total || 0;
        tasa = final_total * tasaPasarela || 0;
        ivaPas = (final_total * tasaPasarela + comPasarelaMXN) * 0.16 || 0;
        moneyOnpasarela = final_total - (tasa + comPasarelaMXN + ivaPas) || 0;
        commisionPasarela = tasa + comPasarelaMXN + ivaPas || 0;
        orderType = checkorderType({
          orderType: order.order_type || '',
          require_service: order.require_service || false,
          order_case: order.order_case || 1,
          shop_comments: order.shop_comments || '',
        });
        sendShop = order.com_shop || 0;
        method_pay = order.method_pay || '';
        totalDb = order.total || 0;
        discount = method_pay === 'EFECTIVO' ? Math.ceil(order.discount) || 0 : order.discount || 0;
        mcc = ((totalDb * cededPercentage) / 100) * 0.25 || 0;
        subAmount = totalDb - discount || 0;
        subAmountWithoutmcc = subAmount - mcc || 0;
        amountPlusServiceCom = subAmount + (order.com_customer || 0) || 0;

        if (category === 'Gas') {
          commission = subAmountWithoutmcc * 0.01;
        } else if (idShop.substring(0, 3) === '2am') {
          commission = subAmountWithoutmcc * 0.04 * 1.16;
        } else {
          commission =
            subAmountWithoutmcc * (orderType === 'PRESENCIAL' ? percentage[1] : percentage[0]);
        }
        subwithoutIVAs = validatedDocs(docsValidated, rfc, subAmountWithoutmcc).subwithoutIVAs;
        retenIva = validatedDocs(docsValidated, rfc, subAmountWithoutmcc).iva[0];
        retenIsr = validatedDocs(docsValidated, rfc, subAmountWithoutmcc).iva[1];

        if (method_pay.toUpperCase() === 'EFECTIVO') {
          colletTotal = mcc + commission + sendShop;
        } else {
          if (sendShop > 0) {
            totalDispersion = subAmountWithoutmcc - (sendShop + commission + retenIva + retenIsr);
          } else {
            totalDispersion = subAmountWithoutmcc - (commission + retenIva + retenIsr + mcc);
          }
        }

        /* Suma de totales por tienda */
        total_import_sale = total_import_sale + totalDb; // Total de importe de venta
        total_discount_cl = total_discount_cl + discount; // Total de descuento otorgado al cliente
        total_mcc = total_mcc + mcc; // Total de monedero electrónico en tienda
        total_com_niku = total_com_niku + commission; // Total de comisión
        total_subwithoutIVAs = total_subwithoutIVAs + subwithoutIVAs; // Total de sibtotales
        total_retentionIVA = total_retentionIVA + retenIva; // Suma de retención de IVA
        total_retentionISR = total_retentionISR + retenIsr; // Suma de  retención de ISR
        comSendShop = comSendShop + sendShop; //Total de comisiones de envio pagadas por la tienda
        comSendCL = comSendCL + order.com_customer || 0; //Total de comisiones de envio pagadas por el cliente
        total_propine = total_propine + order.propina || 0; //Suma total de las propinas
        total_dispersion = total_dispersion + totalDispersion;
        total_collect = total_collect + colletTotal || 0;
      }

      const CalculatedObj = {
        id: '' /* id de la orden */,
        orderCreated: '' /* Creación de la orden */,
        date: '' /* Formato de la fecha*/,
        method_Pay: '',
        hour: '' /* Hora de emisión de la orden */,
        withoutDiscount: 0 /* Importe de venta sin descuento de cliente */,
        saleAmount: 0 /* Importe de venta */,
        salePlusClComm: 0 /* Importe de venta + comisión de cliente */,
        tarifeservice: 0 /* Tarifa de servicio */,
        discountCl: 0 /* Descuento de cliente */,
        mcc: 0 /* Monedero electrónico */,
        subTotal: 0 /* Sub total de la orden */,
        commissionNiku: 0 /* Comision de niku */,
        subwithoutIVA: 0 /* Subtotal sin iva */,
        IVA8: 0 /* Retención de IVA  */,
        ISR1: 0 /* Retención de ISR */,
        comSend: 0 /* Comisión de envió pagada por la tienda */,
        totalDispersion: 0 /* Total que se depositara a la tienda por esta orden*/,
        totalCollect: 0 /* Total a cobrarle a la tienda por esta orden*/,
        typeOrder: '' /* Tipo de orden */,
        comSendCl: 0 /* Comisión de envió pagada por el cliente */,
        propine: 0 /* Propina */,
        deliveryman: '' /* Nombre del repartidor */,
        idzona: '' /* De que zona viene */,
        endTotalbd: '' /*Final total de la orden en Base de datos*/,
        tasaFee: '' /* Tasa de interes de la pasarela de pago */,
        comNXN: '' /* Pesos MXN sumado a la tasafee  */,
        ivaPasarela: '' /* IVA de la pasarela de pago */,
        com_opp: '' /* Total de la tarifa de transacción  */,
        moneyInopp: '' /* Dinero que se reflejará en la pasarela de pago   */,
        less_send_pasarela: '' /* Total en pasarela - la comisión de cliente */,
        less_more_discount: '' /* Total en pasarela - comClient + el descuento orden*/,
        addition_retentions: 0 /* Suma de las retenciones*/,
        addition_comnkdiscount: 0 /* Descuento - monedero  */,
      };

      const hourDb = new Date(order.updatedAt);
      CalculatedObj.id = order.id;
      CalculatedObj.date = formatDate(order.updatedAt);
      CalculatedObj.hour = hourDb.getHours() + ':' + hourDb.getMinutes();
      CalculatedObj.method_Pay = method_pay.toUpperCase();
      CalculatedObj.typeOrder = orderType;
      CalculatedObj.withoutDiscount = cnvNum(cnvFixed(subAmount));
      CalculatedObj.saleAmount = cnvNum(cnvFixed(totalDb));
      CalculatedObj.salePlusClComm = cnvNum(cnvFixed(amountPlusServiceCom));
      CalculatedObj.tarifeservice = cnvNum(cnvFixed(calculatetarife(amountPlusServiceCom)));
      CalculatedObj.discountCl = cnvNum(cnvFixed(discount));
      CalculatedObj.mcc = cnvNum(cnvFixed(mcc));
      CalculatedObj.subTotal = cnvNum(cnvFixed(subAmountWithoutmcc));
      CalculatedObj.commissionNiku = cnvNum(cnvFixed(commission));
      CalculatedObj.subwithoutIVA = cnvNum(cnvFixed(subwithoutIVAs));
      CalculatedObj.IVA8 = cnvNum(cnvFixed(retenIva));
      CalculatedObj.ISR1 = cnvNum(cnvFixed(retenIsr));
      CalculatedObj.comSend = cnvNum(cnvFixed(sendShop));
      CalculatedObj.comSendCl = cnvNum(cnvFixed(order.com_customer)) || 0;
      CalculatedObj.totalCollect = colletTotal > 0 ? cnvNum(cnvFixed(colletTotal)) || 0 : 0;
      CalculatedObj.totalDispersion =
        totalDispersion > 0 ? cnvNum(cnvFixed(totalDispersion)) || 0 : 0;
      CalculatedObj.propine = order.propina || 0;
      CalculatedObj.deliveryman = checkNameDealer(order);
      CalculatedObj.idzona = checkZone(order.shop_id.zone);
      CalculatedObj.endTotalbd = method_pay !== 'TARJETA' ? '' : cnvNum(cnvFixed(final_total));
      CalculatedObj.tasaFee = method_pay !== 'TARJETA' ? '' : cnvNum(cnvFixed(tasa));
      CalculatedObj.comNXN = method_pay !== 'TARJETA' ? '' : cnvNum(cnvFixed(comPasarelaMXN));
      CalculatedObj.ivaPasarela = method_pay !== 'TARJETA' ? '' : cnvNum(cnvFixed(ivaPas));
      CalculatedObj.com_opp = method_pay !== 'TARJETA' ? '' : cnvNum(cnvFixed(commisionPasarela));
      CalculatedObj.moneyInopp = method_pay !== 'TARJETA' ? '' : cnvNum(cnvFixed(moneyOnpasarela));
      CalculatedObj.less_send_pasarela =
        method_pay !== 'TARJETA'
          ? ''
          : cnvNum(cnvFixed(moneyOnpasarela - (order.com_customer || 0)));
      CalculatedObj.less_more_discount =
        method_pay !== 'TARJETA'
          ? ''
          : cnvNum(cnvFixed(moneyOnpasarela - (order.com_customer || 0) + discount));
      CalculatedObj.addition_comnkdiscount = cnvNum(cnvFixed(discount - mcc));
      CalculatedObj.addition_retentions = cnvNum(cnvFixed(retenIva + retenIsr));
      newArreOrders.push(CalculatedObj);
    }

    newArreOrders.push({ totalDispersion: 'Total a depositar', totalCollect: 'Total a cobrar' });
    newArreOrders.push({
      deliveryman: 'SUBTOTAL',
      totalDispersion: cnvNum(cnvFixed(total_dispersion)),
      totalCollect: cnvNum(cnvFixed(total_collect)),
    });
    newArreOrders.push({
      deliveryman: 'COMISIONES',
      totalDispersion: 9.28,
      totalCollect: cnvNum(cnvFixed(calculatetarifservice(total_collect))),
    });
    newArreOrders.push({
      deliveryman: 'IVA(16%)',
      totalDispersion: 0,
      totalCollect: cnvNum(cnvFixed(calculatetarifserviceIva(total_collect))),
    });
    newArreOrders.push({
      deliveryman: 'TOTAL',
      totalDispersion:
        total_dispersion > 9.8
          ? cnvNum(cnvFixed(total_dispersion - 9.28))
          : cnvNum(cnvFixed(total_dispersion)),
      totalCollect:
        total_collect > 0
          ? cnvNum(
              cnvFixed(
                total_collect +
                  calculatetarifservice(total_collect) +
                  calculatetarifserviceIva(total_collect)
              )
            )
          : 0,
    });

    newArreOrders.push({
      saleAmount: 'Total importe de venta',
      discountCl: 'Total descuento',
      mcc: 'Total monedero',
      commissionNiku: 'Total comisión Niku',
      subwithoutIVA: 'Total subtotal',
      IVA8: 'Total retención IVA',
      ISR1: 'Total retención ISR',
      comSend: 'Total de envios por tienda',
      comSendCl: 'Total de envios por cliente',
      propine: 'Total de propinas',
    });
    newArreOrders.push({
      saleAmount: cnvNum(cnvFixed(total_import_sale)),
      discountCl: cnvNum(cnvFixed(total_discount_cl)),
      mcc: cnvNum(cnvFixed(total_mcc)),
      commissionNiku: cnvNum(cnvFixed(total_com_niku)),
      subwithoutIVA: cnvNum(cnvFixed(total_subwithoutIVAs)),
      IVA8: cnvNum(cnvFixed(total_retentionIVA)),
      ISR1: cnvNum(cnvFixed(total_retentionISR)),
      comSend: cnvNum(cnvFixed(comSendShop)),
      comSendCl: cnvNum(cnvFixed(comSendCL)),
      propine: cnvNum(cnvFixed(total_propine)),
    });

    return newArreOrders;
  }

  function calculateOrders(info_orders, info_shop) {
    let idShop = info_shop.id || '';
    let categoryShop = info_shop.category || '';
    let docShops = info_shop.url_documents || '';
    let porcentageShop = info_shop.list_fees || '';
    let rfcShop = info_shop.rfc || '';
    let docValidated = checkdocuments(docShops) || [];

    return (
      checkOrders(
        idShop,
        info_orders,
        categoryShop,
        checkPorcentage(porcentageShop) || [0, 0],
        docValidated,
        rfcShop,
        info_shop
      ) || []
    );
  }

  return { calculateOrders };
}
export default Gatewaycalculatecoms;
