import { emailAttachment } from '../../models/email';
import { buildAttachmentForm } from '../../utilities';
import { post } from '../request';

const path = require('../../urls.json');

async function sendAttachment (emailData = emailAttachment) {
  try {
    const url = `${path.REACT_APP_UNSPLASH_URLSERVER}email/receipt`;
    const attachmentForm = buildAttachmentForm(emailData);
    const res = await post(url, attachmentForm);
    console.log('🚀 ~ file: send-attachment.js ~ line 12 ~ sendAttachment ~ res', res);
    return res.message;
  } catch (error) {
    return 'Error al enviar el correo electrónico.';
  }
}

export default sendAttachment;
