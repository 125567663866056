/* eslint-disable */
import React, { useState } from "react";
import "./Dropdown.css";

const options = [
  "Más reciente al más antiguo",
  "Más antiguo al mas reciente",
  "Hace una semana",
  "Hace 15 días",
  "Hace un mes",
];

const DropdownGlobals = ({ selectedOption, setSelectedOption }) => {
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div className="dropdown">
      <select value={selectedOption} onChange={handleOptionChange}>
        {options.map((option, index) => (
          <option style={{ color: "#333" }} key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownGlobals;
