/* eslint-disable */
import { ConsortiumOptions } from "./ConsortiumOptions";
import { LoadingModal } from "../common";
import { MainButton, SecondaryButton } from "../button";
import { Subtitle } from "../font";
import { UpdateState } from "../../models/market-update";
import { useMarketUpdateStore } from "../../stores";
import { useUpdateProcess } from "../../hooks";

export function SecondStep() {
  const css = "disp-flex dir-row start-center step";
  const updateState = useMarketUpdateStore((state) => state.marketUpdateState);
  const resetMarketUpdate = useMarketUpdateStore(
    (state) => state.resetMarketUpdate
  );
  const { onUpdateProcess } = useUpdateProcess();

  return (
    <>
      <LoadingModal
        open={updateState === UpdateState.Loading}
        text="Actualización en proceso."
      />

      <div className={css}>
        <Subtitle>Para continuar, seleccione una opción:</Subtitle>
      </div>

      <ConsortiumOptions />

      <div className="disp-flex dir-row right-corner margin-corner">
        <SecondaryButton onClick={resetMarketUpdate}>Cancelar</SecondaryButton>
        <span>&nbsp;&nbsp;</span>
        <MainButton onClick={onUpdateProcess}>Continuar</MainButton>
      </div>
    </>
  );
}
