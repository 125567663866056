/* eslint-disable */
import React, { useState } from "react";
import HistoryModalShop from "./HistoryModalShop";
/* import BtnExportExcel from "./BtnExportExcel"; */
import PDFfunctions from "../../hooks/pdfSend/pdfHandler";
import Pdf_Icon from "../icons/Pdf_Icon";
import Send_Icon from "../icons/Send_Icon";
import Up_Icon from "../icons/Up_Icon";
import "../styled/style-cardShop.css";
import BtnHistory from "./BtnHistory";

export default function ShopCardExpand({
  setOpenProfile,
  info,
  id,
  arre_historyExist,
  name,
  arrayAllOrders,
  objInfoshop,
  email,
  idCard,
  idClabe,
  availability,
  totalCollect,
  totaldebts,
  createdAt,
}) {
  const { exportPdf, sendPdf } = PDFfunctions();
  const [open_History, setOpen_History] = useState(false);
  const handleClose = () => setOpen_History(false);
  const handleOpen = () => {
    setOpen_History(true);
  };
  const style_modal = {
    txtAvailability: {
      color: availability ? "green" : "red",
      fontSize: "15px",
      fontWeight: "bold",
      fontFamily: " Montserrat,sans-serif",
    },
    txtBalance: {
      fontSize: "15px",
      fontWeight: "bold",
      fontFamily: " Montserrat,sans-serif",
    },
  };

  function calculateBalance(gains, debts) {
    if (gains > debts) {
      return gains - debts;
    } else if (debts > gains) {
      return -(debts - gains);
    } else {
      return 0;
    }
  }

  function exportPDF(arre, obj, dates) {
    exportPdf(arre, obj, dates);
  }
  function sendPDF(arre, obj, dates, email) {
    sendPdf(arre, obj, dates, email);
  }

  return (
    <div className="fade-in grand-box">
      <div className="header-grand-box">
        <button
          className="button-upArrow"
          aria-label="ExpandCircleDownIcon"
          onClick={() => setOpenProfile(false)}
        >
          <Up_Icon />
        </button>
        <p style={style_modal.txtAvailability}>
          {availability ? "Tienda activa" : "Tienda desactivada"}
        </p>
        <BtnHistory
          onClick={handleOpen}
          disabled={arre_historyExist.length > 0 ? true : false}
        />
        {open_History ? (
          <HistoryModalShop
            id={info.id}
            arre_historyExist={arre_historyExist}
            name={name}
            open={open_History}
            handleClose={handleClose}
          />
        ) : null}
      </div>
      <hr className="solid"></hr>

      <div className="fade-in body-grandbox">
        <div className="fade-in box-content">
          <h4 className="title-grand-box">{name || "Sin nombre"}</h4>
          <div className="fade-in box-data">
            <p className="title-grand-box subtitle">ID: {id.slice(0, 6)}</p>
            <p className="title-grand-box subtitle">
              Afiliación: {createdAt.substring(0, 10)}{" "}
            </p>
          </div>
          <div className="fade-in box-data">
            <p className="title-grand-box subtitle">RFC:</p>
            <p className="title-grand-box">{info.rfc}</p>
          </div>

          <div className="fade-in box-data">
            <p className="title-grand-box subtitle">Dirección:</p>
            <p className="title-grand-box title-address">{info.address}</p>
          </div>
          <div className="fade-in box-data">
            <p className="title-grand-box subtitle">Número de celular:</p>
            <p className="title-grand-box">{info.clientNumber}</p>
          </div>
          <div className="fade-in box-data">
            <p className="title-grand-box subtitle">Correo electrónico:</p>
            <p className="title-grand-box">{info.email}</p>
          </div>
          <div className="fade-in box-data">
            <p className="title-grand-box subtitle">
              CLABE Interbancaria registrada:
            </p>
            <p className="title-grand-box">{idClabe !== "" ? "Si" : "No"}</p>
          </div>
          <div className="fade-in box-data">
            <p className="title-grand-box subtitle">Tarjeta registrada:</p>
            <p className="title-grand-box">{idCard !== "" ? "Si" : "No"}</p>
          </div>
          <div className="fade-in box-data">
            <p className="title-grand-box subtitle">Periodo:</p>
            <p className="title-grand-box">
              {info.newDates[0].substring(0, 10) +
                " a " +
                info.newDates[1].substring(0, 10)}
            </p>
          </div>

          <div className="fade-in box-data">
            <p className="title-grand-box subtitle">Total a cobrar:</p>
            <p className="title-grand-box">${totalCollect}</p>
          </div>

          <div className="fade-in box-data">
            <p className="title-grand-box subtitle">Total a pagar:</p>
            <p className="title-grand-box">${totaldebts}</p>
          </div>
        </div>
        <div className="fade-in box-data">
          {/*           <BtnExportExcel from={'option-btn'} arrayAllOrders={arrayAllOrders} name={'Exportar'} /> */}
          <button
            className="button-action-cardShop"
            disabled={arrayAllOrders.length === 0 ? true : false}
            onClick={() =>
              exportPDF(arrayAllOrders, objInfoshop, info.betweenDates)
            }
            variant="outlined"
          >
            <Pdf_Icon />
            <div>PDF</div>
          </button>
          <button
            className="button-action-cardShop"
            disabled={arrayAllOrders.length === 0 ? true : false}
            onClick={() =>
              sendPDF(arrayAllOrders, objInfoshop, info.betweenDates, email)
            }
            variant="outlined"
          >
            <Send_Icon />
            <div>Enviar</div>
          </button>
        </div>

        <div className="fade-in box-data">
          <p style={style_modal.txtBalance}>Balance</p>
          <p style={style_modal.txtBalance}>
            ${calculateBalance(totaldebts, totalCollect).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
}
