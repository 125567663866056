import Openpay from './openpay';

const openpay = new Openpay();
const errors = ['request', 'internal', 'gateway'];

async function depositMoneyOpp (oppID = '', depositObj = { amount: 0 }) {
  try {
    const data = await openpay.moneyDeposit(oppID, depositObj);
    if (errors.includes(data.category)) return { success: false, data };
    return { success: true, data };
  } catch (err) {
    return null;
  }
}

export default depositMoneyOpp;
