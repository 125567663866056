/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:d7fffc6c-89a3-447c-87f1-30a1f31f55c2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_FxjCAUnyX",
    "aws_user_pools_web_client_id": "10th6r5fgst4ihbf0gvpelpbjf",
    "oauth": {
        "domain": "nikuclientee4a0656-ee4a0656-devniku.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://svxp5ujzfzfdzev3s5444gfasy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "nikuclient7fb0fc63cd7044ee98360471ae60351d220141-devniku",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_mobile_analytics_app_id": "6dc41e7f16fa4277a82f93585609bfeb",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
