export const GetShopProducts = /* GraphQL */ `
  query GetShopProducts($limit: Int!, $nextToken: String) {
    getShop(id: "75po9u2m-618o-5701-p811-5y675192847d") {
      products(limit: $limit, nextToken: $nextToken) {
        items {
          id
        }
        nextToken
      }
    }
  }
`;
