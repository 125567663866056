const initialLogin = {
  email: '',
  password: '',
  authIsProcessed: false,
  authProcessStarted: false,
  loginErrorMessage: 'Por favor, ingrese su correo electrónico y contraseña.'
};

export const createLoginResetSlice = (set) => ({
  resetLogin: () => set(initialLogin)
});
