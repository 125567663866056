import { create } from 'zustand';
import { createErrorSlice } from './create-error-slice';
import { createMarketUpdateReset } from './create-market-update-reset';
import { createMarketUpdateSlice } from './create-market-update-slice';
import { createUiSlice } from './create-ui-slice';
import { createUploadFileSlice } from './create-upload-file-slice';

export const useMarketUpdateStore = create((...args) => ({
  ...createUploadFileSlice(...args),
  ...createUiSlice(...args),
  ...createErrorSlice(...args),
  ...createMarketUpdateSlice(...args),
  ...createMarketUpdateReset(...args)
}));
