/* eslint-disable */
import "./assets/global-styles.css";
import { ConsortiumUpdate, Login, NotFound, pagesRoutes } from "./pages";
import { MainLayout, Navbar } from "./layout";
import { ProtectedRoute, TopBar } from "./components";
import { Routes, Route, useLocation } from "react-router-dom";
import { useCurrentUserRole } from "./hooks";
import { useEffect } from "react";
import { useLoginStore, useMarketUpdateStore, useUserStore } from "./stores";

let didInit = false;

export function App() {
  const location = useLocation();
  const resetLogin = useLoginStore((state) => state.resetLogin);
  const resetMarketUpdate = useMarketUpdateStore(
    (state) => state.resetMarketUpdate
  );
  const resetUser = useUserStore((state) => state.resetUser);

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      resetUser();
      resetLogin();
      resetMarketUpdate();
    }
  }, []);

  const { isCRM, isSeller, isAccountant } = useCurrentUserRole();



  return (
    <>
      {(isCRM || isAccountant) && <Navbar />}
      {isSeller && <TopBar />}
      <MainLayout isAuth={isCRM || isAccountant}>
        <Routes>
          <Route index element={<Login />} />
          <Route element={<Login />} path="login" />
          <Route
            element={
              <ProtectedRoute
                isAllowed={isCRM || isAccountant}
                location={location}
              />
            }
          >
            {pagesRoutes.map((page) => (
              <Route
                key={page.id}
                element={<page.component />}
                path={page.path}
              />
            ))}
          </Route>
          <Route
            element={
              <ProtectedRoute isAllowed={isSeller} location={location}>
                <ConsortiumUpdate />
              </ProtectedRoute>
            }
            path="/updateProducts"
          />
          <Route element={<NotFound />} path="*" />
        </Routes>
      </MainLayout>
    </>
  );
}
