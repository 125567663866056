/* eslint-disable */
export default function Subtitle({ children = <span>Subtitle</span> }) {
  const css = "black-txt body-txt title-common";

  return (
    <p className={css} style={{ fontFamily: "Montserrat,sans-serif" }}>
      {children}
    </p>
  );
}
