const nikuProduct = {
  id: '',
  aisle: '',
  description: '',
  keywords: '',
  mature: false,
  netcontent: '',
  offerprice: 0,
  price: 0,
  product_name: '',
  product_type: '',
  productShop_idId: '',
  status: true,
  subtag: '',
  tags: ''
};

export { nikuProduct };
