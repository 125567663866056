const emailAttachment = {
  email: 'luisdhr.11@gmail.com',
  sellerData: {
    address: '',
    clientNumber: '',
    businessName: '',
    rfc: 'XXXXXXXXX',
    zipCode: ''
  },
  totalToReceive: 0,
  totalPayable: 0,
  datesOfMovements: 'DD/MM/AAAA - DD/MM/AAAA',
  deliveryData: [],
  faceToFaceData: [],
  deliveryDetailsData: [],
  faceToFaceDetailsData: []
};

export { emailAttachment };
