/* eslint-disable */
import { AccordionBtn } from "./accordion-btn.component";
import { LinkMenu } from "./link-menu.component";
import { PowerSettingsNewIcon } from "../icons";
import { useAuth, useCurrentUserRole } from "../../hooks";
import "../layout.css";

export function Sidebar() {
  const { signOutUser } = useAuth();
  const nikuLogo = "https://niku.com.mx/public/images/imgForApp/nikucash.png";
  const { isAccountant } = useCurrentUserRole();
  return (
    <div className="sidebar">
      <div className="sidebar-item-top">
        <img alt="niku-logo" className="sidebar-img" src={nikuLogo} />
      </div>
      {isAccountant ? (
        <AccordionBtn text="Niku Car">
          <LinkMenu pathname="/ListDrivers" title="Conductores" />
          {/*           <LinkMenu pathname="/ListHistoryDriver" title="Historial" /> */}
        </AccordionBtn>
      ) : (
        <>
          {/*       <AccordionBtn text="Negocios">
            <LinkMenu pathname="/ListShops" title="Tiendas" />
          </AccordionBtn>

          <AccordionBtn text="Repartidores">
            <LinkMenu pathname="/ListDelivery" title="Repartidores" />
          </AccordionBtn> */}
          <AccordionBtn text="Niku Car">
            <LinkMenu pathname="/Analytics" title="Analítica" />
            <LinkMenu pathname="/ListDrivers" title="Conductores" />
            <LinkMenu pathname="/Equipments" title="Equipamientos" />
            <LinkMenu
              pathname="/ListConcilationDrivers"
              title="Conciliación de pagos"
            />
            <LinkMenu
              pathname="/ListHistoryDriver"
              title="Movimientos en el sistema"
            />
            <button
              onClick={() => {
                window.open("https://dashboard.openpay.mx/login", "_blank");
              }}
              className="sidebar-item"
              style={{ backgroundColor: "#ffffff" }}
            >
              <img
                width="100px"
                src="https://documents.openpay.mx/wp-content/uploads/2022/02/openpay-color.png"
              />
            </button>
            <button
              onClick={() => {
                window.open("https://panel.conekta.com/auth", "_blank");
              }}
              className="sidebar-item"
              style={{ backgroundColor: "#0a1437" }}
            >
              <img
                width="100px"
                src="https://assets.conekta.com/cpanel/statics/assets/img/conekta-logo-full.svg"
              />
            </button>
          </AccordionBtn>
        </>
      )}

      <div className="sidebar-item-bottom">
    {/*     <p
          style={{
            fontSize: "0.8rem",
            position: "absolute",
            bottom: 0,
            right: 10,
          }}
        >
          Versión de sistema 1.0.2
        </p> */}
        <button
          className="sidebar-btn-logout"
          type="button"
          onClick={signOutUser}
        >
          <PowerSettingsNewIcon />
          <p className="sidebar-btn-lbl">Cerrar sesión</p>
        </button>
      </div>
    </div>
  );
}
