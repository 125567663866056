import { getShopProducts, updateNikuProducts } from '../../services';
import { isEmptyArray } from '../common';
import { nikuProduct } from '../../models/niku-product';

export async function deactivateProductsNotInErp (products = [nikuProduct]) {
  const nikuProducts = await getShopProducts([]);

  if (!isEmptyArray(nikuProducts)) {
    const remainingProducts = nikuProducts.filter((product) =>
      !products.some((erpProduct) => erpProduct.id === product.id)
    );

    const productsToRemove = remainingProducts.map((product) => ({
      ...product, status: false
    }));

    await updateNikuProducts(productsToRemove);
  }
}
