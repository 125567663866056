import Openpay from './openpay';

const openpay = new Openpay();
const errors = ['request', 'internal', 'gateway'];

async function createPlanOpp (planObj) {
  try {
    const data = await openpay.createPlan(planObj);
    if (errors.includes(data.category)) return null;
    return data.id;
  } catch (err) {
    return null;
  }
}

export default createPlanOpp;
