import {
  calculateCommission,
  calculateUsoPlataforma,
  convertToFixedAndNumber,
  formateDateTitle,
} from "../CalculateFunctions/CalculateFunctions";
import { name_conductor_global } from "../Global/name_global";
import { header_accounting } from "./header_accounting";
import { Import_conductor_accounting } from "./subtotals/imports_accouting";
import { travel_conductor_accounting } from "./travel_accounting";

const orange = {
  red: 0.914,
  green: 0.353,
  blue: 0.075,
  alpha: 1,
};

export function Sheet_Accounting(arre_travels, rangesDate) {
  const bodySheet = [];
  //Seteo de subheader y header del excel global
  bodySheet.push(header_accounting);
  let currentRow = 2;

  for (let i in arre_travels) {
    let sumUsePlataforma = 0;
    let sumCommission = 0;
    const data = arre_travels[i];
    const name_conductor =
      data?.driver_info?.name + " " + data?.driver_info?.last_name;
    const travels = data?.travel_matches;
    const rfc = data?.driver_info?.rfc || "Sin RFC";
    let dateRFC = data?.driver_info?.date || "";
    let dateRegisterRFC = new Date(dateRFC);

    if (rfc === "Sin RFC") {
      dateRegisterRFC = null;
    }

    bodySheet.push(name_conductor_global(name_conductor, [currentRow++, 0]));
    for (let o in travels) {
      const travel = travels[o];
      const tarifa_viaje_total = travel?.subtotal_tarifa_dinamica || 0; //Tarifa total sin iva
      const uso_plataforma = travel?.uso_plataforma || 0;
      bodySheet.push({
        startRow: currentRow++,
        startColumn: 0,
        rowData: travel_conductor_accounting(travel, dateRegisterRFC),
      });
      sumUsePlataforma = sumUsePlataforma + uso_plataforma;
      sumCommission =
        sumCommission + calculateCommission(tarifa_viaje_total, 0.15);
    }
    const sum_results = {
      sumPlatfromIVA: sumUsePlataforma,
      sumCommission: sumCommission,
    };
    const import_results = {
      sumPlatfromIVA: convertToFixedAndNumber(sumUsePlataforma / 1.16),
      sumCommission: convertToFixedAndNumber(sumCommission / 1.16),
    };
    const iva_results = {
      sumPlatfromIVA: convertToFixedAndNumber(sumUsePlataforma * 0.16),
      sumCommission: convertToFixedAndNumber(sumCommission * 0.16),
    };
    const total_results = {
      sumPlatfromIVA: sumUsePlataforma,
      sumCommission: sumCommission,
    };

    bodySheet.push(
      Import_conductor_accounting("SUMA", [currentRow++, 5], sum_results)
    );
    bodySheet.push(
      Import_conductor_accounting("IMPORTE", [currentRow++, 5], import_results)
    );
    bodySheet.push(
      Import_conductor_accounting("IVA", [currentRow++, 5], iva_results)
    );
    bodySheet.push(
      Import_conductor_accounting("TOTAL", [currentRow++, 5], total_results)
    );
  }
  return {
    properties: {
      title: formateDateTitle("CONTABILIDAD", rangesDate),
      tabColorStyle: {
        rgbColor: orange,
      },
    },
    data: bodySheet,
  };
}
