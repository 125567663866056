/* eslint-disable */
import styles from "./LoginHeaderText.module.css";

export function LoginHeaderText() {
  return (
    <>
      <h1 className={styles.loginTitle}>Iniciar sesión</h1>
      <p className={styles.loginParagraph}>
        Bienvenido, introduzca sus credenciales para acceder.
      </p>
    </>
  );
}
