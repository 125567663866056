/* eslint-disable */
import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import getDatesOrders from "../../services/db/list-deliveryOrders";
import Calculatecoms from "../../hooks/comsDealers/CalculateComs";
import HistoryModalDelivery from "./HistoryModalDelivery";
import img from "../../../src/assets/nullMG.jpg";
import Cardyes_Icon from "../icons/Cardyes_Icon";
import CardNot_Icon from "../icons/Cardnot_Icon";

let ComsHook = new Calculatecoms();
export default function DeliveryCard({ item }) {
  const countOrders = item.orders.length;
  const [photo, setPhoto] = useState("../../../src/assets/nullMG.jpg");
  const [openShowOrders, setOpenShowOrders] = useState(false);
  const openShowOrdersModal = () => setOpenShowOrders(true);
  const closeShowOrders = () => setOpenShowOrders(false);

  async function consumeS3Image(url_photoProfile) {
    const image_url = url_photoProfile || "";
    const response = await Storage.get(url_photoProfile);
    setPhoto(image_url && response);
  }

  useEffect(() => {
    consumeS3Image(item.url_photoProfile);
  }, [item.url_photoProfile]);

  const style = {
    txtProfile: {
      color: "#333333",
      fontSize: "1rem",
      fontWeight: "500",
      fontFamily: "Montserrat,sans-serif",
    },
    profileImage: {
      width: 80,
      height: 80,
      objectFit: "cover",
      borderRadius: 5,
    },
  };

  return (
    <>
      <div
        className="box-shop"
        onClick={() => {
          openShowOrdersModal();
        }}
      >
        <div className="header-box">
          {item?.openPay_id != "" &&
          JSON.parse(item.openPay_id)?.idcard !== "" ? (
            <Cardyes_Icon />
          ) : (
            <CardNot_Icon />
          )}
          <p>Viajes: {countOrders} </p>
        </div>
        <hr className="solid"></hr>
        <div className="data-box-body-shopcard">
          <div className="img-shopCard">
            <img
              style={style.profileImage}
              src={photo}
              alt=""
              onError={() => setPhoto(img)}
            />
          </div>

          <div className="data-info-box-shopcard">
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              <p className="txt-title-informationShop">
                {(
                  item?.name + " " + item?.last_name || "Sin nombre"
                ).toUpperCase()}
              </p>
            </div>
            <div className="data-little-box">
              <p className="txt-informationShop">Id:</p>
              <p className="txt-informationShop">
                #{item?.id.substring(0, 6) || 0}{" "}
              </p>
            </div>
          </div>
        </div>
        <hr className="solid"></hr>
        <div className="footer-card-box">
          <p className="txt-informationShop-footer">Vehículo:</p>
          <p className="txt-informationShop-footer">
            {item?.delivery_transport}
          </p>
        </div>
      </div>
      {openShowOrders && (
        <HistoryModalDelivery
          openShowOrders={openShowOrders}
          closeShowOrders={closeShowOrders}
          item={item}
        />
      )}
    </>
  );
}
