/* eslint-disable */
import React, { useState } from "react";
import "./FloatingButtonExcel.css";
import ModalOptionsExport from "../drivers-list/ModalOptionsExport";
import { Create_spreadsheet } from "../../hooks/Google_spreadsheet_0Auth2.0/Create_spreadsheet";
import BorderAllIcon from "@mui/icons-material/BorderAll";

const BotonGlobalExportarExcel = ({ arre_travels, dateRange, weekselect }) => {
  const [openTravels, setOpenTravels] = useState(false);
  const openShowTravelsModal = () => setOpenTravels(true);
  const closeShowTravels = () => setOpenTravels(false);
  const { Create_spreadsheet_function, loading, urlDoc, setUrlDoc } =
    Create_spreadsheet();
  return (
    <div className="floating-button-container-excel">
      <button
        className="floating-button-excel"
        onClick={() => openShowTravelsModal()}
      >
        <BorderAllIcon />
      </button>

      {openTravels ? (
        <ModalOptionsExport
          openTravels={openTravels}
          closeTravels={closeShowTravels}
          dateRange={dateRange}
          loading={loading}
          setUrlDoc={setUrlDoc}
          urlDoc={urlDoc}
          action={() =>
            Create_spreadsheet_function(arre_travels, dateRange, weekselect)
          }
        />
      ) : null}
    </div>
  );
};

export default BotonGlobalExportarExcel;
