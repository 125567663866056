/* eslint-disable */
import { NavLink } from "react-router-dom";
import "../layout.css";

export function LinkMenu({ pathname = "", title = "" }) {
  if (window.location.pathname === pathname) {
    return (
      <NavLink className="sidebar-item-selected" to={pathname}>
        {title}
      </NavLink>
    );
  }

  return (
    <NavLink className="sidebar-item" to={pathname}>
      {title}
    </NavLink>
  );
}
