import { API, graphqlOperation } from 'aws-amplify';

import {
  CreateProduct,
  CreateUpdatesMarketLog,
  UpdateProductByID
} from '../../graphql/consortium-update-mutations';
import { createHystoriPay } from '../../graphql/myMutations';
import { nikuProduct } from '../../models/niku-product';

// * Payment History */
export async function createPaymentHistory (
  input = {
    frequency: 'Semanal',
    paymentHistoryShop_idId: '',
    period: '',
    plan: '',
    status: false,
    total_amount: 0,
    type: ''
  }
) {
  try {
    const response = await API.graphql(graphqlOperation(createHystoriPay, { input }));

    return response.data.createPaymentHistory;
  } catch (exception) {
    return null;
  }
}

// * Product *
export async function updateProductByID (input = nikuProduct) {
  try {
    const res = await API.graphql(graphqlOperation(UpdateProductByID, { input }));

    return res.data.updateProduct;
  } catch (exception) {
    return null;
  }
}

export async function createProduct (
  input = {
    id: '',
    aisle: '',
    description: '',
    keywords: '',
    mature: false,
    netcontent: '',
    offerprice: 0,
    price: 0,
    productShop_idId: '',
    product_name: '',
    product_type: '',
    status: true,
    subtag: '',
    tags: '',
    url_image: ''
  }
) {
  try {
    const res = await API.graphql(graphqlOperation(CreateProduct, { input }));

    return res.data.createProduct;
  } catch (exception) {
    return null;
  }
}

export async function createUpdatesMarketLog ({
  date = '',
  from = '',
  status = '',
  type = '',
  userId = ''
}) {
  try {
    const resp = await API.graphql(
      graphqlOperation(CreateUpdatesMarketLog, { date, from, status, type, userId })
    );

    return resp.data.createUpdatesMarketLog;
  } catch (exception) {
    return null;
  }
}
