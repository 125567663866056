export const ListOrdersByStoreDate = /* GraphQL */ `
  query GetOrdersByStore($id: ID!, $datesBetween: [String], $limit: Int!, $nextToken: String) {
    getShop(id: $id) {
      orders(
        filter: {
          level: { contains: "FINALIZADO" }
          status: { eq: "FINALIZADO" }
          updatedAt: { between: $datesBetween }
        }
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          com_customer
          com_shop
          updatedAt
          customer_id {
            id
          }
          delivery_man_id {
            last_name
            name
          }
          discount
          final_total
          method_pay
          order_type
          propina
          require_service
          shop_comments
          order_case
          platform_use
          shop_id {
            zone
            percentage_traded {
              value
            }
          }
          status
          total
        }
        nextToken
      }
    }
  }
`;

export const ListOrderStore = /* GraphQL */ `
  query GetOrdersByStore($id: ID!, $limit: Int!, $nextToken: String) {
    getShop(id: $id) {
      orders(
        filter: { level: { contains: "FINALIZADO" }, status: { eq: "FINALIZADO" } }
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          com_customer
          com_shop
          createdAt
          customer_id {
            id
          }
          delivery_man_id {
            last_name
            name
          }
          discount
          final_total
          method_pay
          order_type
          propina
          require_service
          shop_comments
          shop_id {
            zone
            percentage_traded {
              value
            }
          }
          status
          total
        }
        nextToken
      }
    }
  }
`;
