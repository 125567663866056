/* eslint-disable */
import { useLoginStore } from "../../../stores/login/use-login-store";

export function PasswordInput() {
  const password = useLoginStore((state) => state.password);
  const setPassword = useLoginStore((state) => state.setPassword);

  return (
    <input
      className="login-form-input"
      placeholder="Ingresa tu contraseña"
      type="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
    />
  );
}
