import { UpdateStep } from '../../models/market-update';
import { showErrorToast } from '../../utilities';
import { uploadErpFile } from '../../services';
import { useMarketUpdateStore } from '../../stores';

export const useUploadFile = () => {
  const file = useMarketUpdateStore((state) => state.marketUpdateFile);
  const fileName = useMarketUpdateStore((state) => state.marketUpdateFileName);
  const resetMarketUpdate = useMarketUpdateStore((state) => state.resetMarketUpdate);
  const setBtnIsDisabled = useMarketUpdateStore((state) => state.setMarketUpdateButtonIsDisabled);
  const setErrorFile = useMarketUpdateStore((state) => state.setMarketUpdateError);
  const setInputIsDisabled = useMarketUpdateStore((state) => state.setMarketUpdateInputIsDisabled);
  const setUpdateStarted = useMarketUpdateStore((state) => state.setMarketUpdateStarted);
  const setUpdateStep = useMarketUpdateStore((state) => state.setMarketUpdateStep);

  const startUploadFile = (flag = true) => {
    setUpdateStarted(flag);
    setBtnIsDisabled(flag);
    setInputIsDisabled(flag);
  };

  const showError = () => {
    resetMarketUpdate();
    setErrorFile('Error al subir el archivo.');
    showErrorToast('Error al subir el archivo.');
  };

  return {
    uploadFile: async () => {
      startUploadFile();
      const success = await uploadErpFile({ file, fileName });

      if (success === false) showError();

      setUpdateStep(UpdateStep.Second);
      startUploadFile(false);
    }
  };
};
