/* eslint-disable */
import { emailAttachment } from '../../models/email';

function buildAttachmentForm(formData = emailAttachment) {
  console.log('🚀 ~ file: build-attachment-form.js ~ line 4 ~ formData', formData);

  const totalToReceive =
    formData.totalToReceive === 0 ? formData.totalToReceive.toFixed(2) : formData.totalToReceive;
  const totalPayable =
    formData.totalPayable === 0 ? formData.totalPayable.toFixed(2) : formData.totalPayable;

  const form = new FormData();
  form.append('email', 'alejandro.g.g.developer@gmail.com' /* formData.email */); // * formData.email
  form.append('sellerData', JSON.stringify(formData.sellerData));
  form.append('totalToReceive', totalToReceive);
  form.append('totalPayable', totalPayable);
  form.append('datesOfMovements', formData.datesOfMovements);
  form.append('deliveryData', JSON.stringify(formData.deliveryData));
  form.append('faceToFaceData', JSON.stringify(formData.faceToFaceData));
  form.append('deliveryDetailsData', JSON.stringify(formData.deliveryDetailsData));
  form.append('faceToFaceDetailsData', JSON.stringify(formData.faceToFaceDetailsData));

  console.log('🚀 ~ file: build-attachment-form.js ~ line 17 ~ form', form);
  return form;
}

export default buildAttachmentForm;
