const defaultProduct = {
  id: '',
  aisle: '',
  description: '',
  keywords: '',
  product_name: '',
  tags: ''
};

export function rebuildProducts (products = [defaultProduct]) {
  const rebuiltProducts = products.map((product) => {
    const productName = window.atob(product.product_name).toLowerCase().replace(/\s+/g, ' ').trim();

    const keywords = window.atob(product.keywords).toLowerCase();
    const keywordsWithoutAccents = keywords
      .replace(/ñ/gi, 'n')
      .replace(/á/gi, 'a')
      .replace(/é/gi, 'e')
      .replace(/í/gi, 'i')
      .replace(/ó/gi, 'o')
      .replace(/ú/gi, 'u');

    const uniqueKeywords = [
      ...new Set(keywords.split(',').concat(keywordsWithoutAccents.split(',')))
    ];

    return {
      ...product,
      id: window.atob(product.id),
      description: window.atob(product.description).toLowerCase(),
      keywords: uniqueKeywords.join(','),
      product_name: productName,
      tags: decodeURIComponent(encodeURIComponent(product.tags))
    };
  });

  return rebuiltProducts;
}
