/* eslint-disable */
import UseAnimations from "react-useanimations";
import loading from "react-useanimations/lib/loading";

export default function MainButton({
  children = <span>Submit</span>,
  disabled = false,
  isLoading = false,
  onClick = () => null,
}) {
  const css =
    "white-txt regular-txt body-txt disp-flex center radius orange-button main-btn";

  if (disabled) {
    return (
      <button
        className={css}
        disabled={disabled}
        type="button"
        onClick={onClick}
      >
        {isLoading ? (
          <UseAnimations animation={loading} size={20} strokeColor="#E95A13" />
        ) : (
          children
        )}
      </button>
    );
  }

  return (
    <button className={css} disabled={disabled} type="button" onClick={onClick}>
      {children}
    </button>
  );
}
