/* eslint-disable */
import { API, graphqlOperation } from "aws-amplify";
import {
  GetShop,
  listCollectHistory,
  listDispersionHistory,
  listShops,
  listShopsIDs,
} from "../../graphql/collect-disp-queries";
import { GetShopProducts } from "../../graphql/consortium-update-queries";
import * as Query from "../../graphql/myQueries";
import {
  ListOrderStore,
  ListOrdersByStoreDate,
} from "../../graphql/orders-queries";

// * Order *
export async function oderFiltering(id = "", datesbetween = ["", ""]) {
  try {
    const res = await API.graphql(
      graphqlOperation(Query.OrdersFilter, { id, datesbetween })
    );
    return res.data.getShop.orders.items;
  } catch (exception) {
    return [];
  }
}

export async function listOrdersByStoreNT(
  id = "",
  limit = 100,
  nextToken = null
) {
  try {
    const res = await API.graphql(
      graphqlOperation(ListOrderStore, { id, limit, nextToken })
    );
    return res.data.getShop.orders;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

export async function listOrdersByDateNT(
  id = "",
  datesBetween = ["", ""],
  limit = 100,
  nextToken = null
) {
  try {
    const res = await API.graphql(
      graphqlOperation(ListOrdersByStoreDate, {
        id,
        datesBetween,
        limit,
        nextToken,
      })
    );

    return res.data.getShop.orders;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

// * Shop *
export async function listShopsNT(limit = 200, nextToken = null) {
  try {
    const res = await API.graphql(
      graphqlOperation(listShops, { limit, nextToken })
    );
    return res.data.listShops;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

export async function listShopsIDsNT(limit = 200, nextToken = null) {
  try {
    const res = await API.graphql(
      graphqlOperation(listShopsIDs, { limit, nextToken })
    );
    return res.data.listShops;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

export async function getShopByID(id = "") {
  try {
    const res = await API.graphql(graphqlOperation(GetShop, { id }));
    return res.data.getShop;
  } catch (exception) {
    return null;
  }
}

export async function listShopsNextToken(limit = 100, nextToken = null) {
  try {
    const res = await API.graphql(
      graphqlOperation(Query.nextlistShops, { limit, nextToken })
    );
    return res.data.listShops;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

export async function listPaymentsHistory(limit = 100, nextToken = null) {
  try {
    const res = await API.graphql(
      graphqlOperation(Query.ListePaymentHistory, { limit, nextToken })
    );
    return res.data.listPaymentHistorys;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

export async function listOrderNextToken(
  id = "",
  limit = 100,
  nextToken = null
) {
  try {
    const res = await API.graphql(
      graphqlOperation(Query.nextListOrdersShop, { id, limit, nextToken })
    );
    return res.data.getShop.orders;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

// * Delivery *
export async function listDeliverymenNT(
  limit = 100,
  nextToken = null,
  orderLimit = 100,
  orderToken = null
) {
  try {
    const res = await API.graphql({
      query: Query.listDeliverymen,
      variables: {
        limit,
        nextToken,
        orderLimit,
        orderNextToken: orderToken,
      },
    });

    return res.data.accountsByRole;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}
export async function listTravelsDelivery(limit = 100, nextToken = null) {
  try {
    const res = await API.graphql({
      query: Query.listTravelsDelivery,
      variables: {
        limit,
        nextToken,
      },
    });

    return res.data.listOrders;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

export async function listDeliverymenName(limit = 100, nextToken = null, name) {
  try {
    /*  console.log("🚀 ~ file: query-functions.js ~ line 97 ~ listDeliverymenName ~ name", limit = 100, nextToken = null, name) */
    const res = await API.graphql(
      graphqlOperation(Query.listDeliverymenName, { limit, nextToken, name })
    );

    return res.data.accountsByRole;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

export async function listShopName(limit = 100, nextToken = null, shop_name) {
  try {
    /*  console.log("🚀 ~ file: query-functions.js ~ line 97 ~ listDeliverymenName ~ name", limit = 100, nextToken = null, name) */
    const res = await API.graphql(
      graphqlOperation(Query.nextlistShopsName, { limit, nextToken, shop_name })
    );
    return res.data.listShops;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

// * Collect */
export async function listCollectHistoryNT(
  status = true,
  limit = 100,
  nextToken = null
) {
  try {
    const frequency = "Semanal";
    const res = await API.graphql(
      graphqlOperation(listCollectHistory, {
        status,
        frequency,
        limit,
        nextToken,
      })
    );
    return res.data.listPaymentHistorys;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

// * Dispersion */
export async function listDispersionHistoryNT(
  status = true,
  limit = 100,
  nextToken = null
) {
  try {
    const frequency = "Semanal";
    const res = await API.graphql(
      graphqlOperation(listDispersionHistory, {
        status,
        frequency,
        limit,
        nextToken,
      })
    );
    return res.data.listPaymentHistorys;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}

// * Product *
export async function getShopProductsNT(limit = 100000, nextToken = null) {
  try {
    const res = await API.graphql(
      graphqlOperation(GetShopProducts, { limit, nextToken })
    );
    return res.data.getShop.products;
  } catch (exception) {
    return { items: [], nextToken: null };
  }
}
