/* eslint-disable */
import fileDisabledImg from "../../assets/file-upload-disabled.png";
import fileUploadImg from "../../assets/file-upload.png";
import styles from "./InputFile.module.css";
import { useFileSelection } from "../../hooks";
import { useMarketUpdateStore } from "../../stores";

export function InputFile() {
  const fileName = useMarketUpdateStore((state) => state.marketUpdateFileName);
  const isDisabled = useMarketUpdateStore(
    (state) => state.marketUpdateInputIsDisabled
  );
  const { selectFile } = useFileSelection();

  return (
    <div className={styles.uploadForm}>
      <input
        accept=".fdb"
        className={isDisabled ? styles.inputDisabled : styles.inputFile}
        disabled={isDisabled}
        id={isDisabled ? "input-disabled" : "input-file"}
        multiple={false}
        name={isDisabled ? "input-disabled" : "input-file"}
        onChange={selectFile}
        type="file"
      />

      <label htmlFor={isDisabled ? "input-disabled" : "input-file"}>
        <div className="input-icon">
          <img
            alt="Subir archivo"
            height={60}
            src={isDisabled ? fileDisabledImg : fileUploadImg}
            width={49}
          />
          {fileName}
        </div>
      </label>
    </div>
  );
}
