import {
  calculateCommission,
  convertDate,
  convertMethodPay,
} from "../Google_spreadsheet_0Auth2.0/CalculateFunctions/CalculateFunctions";
import { Calculate_subtotal_null } from "./Calculate_subtotals_null/Calculate_subtotal_null";

function calcularBalance(efectivoCliente, tarjetaEmpresa) {
  const balance = efectivoCliente - tarjetaEmpresa;
  if (balance > 0) {
    return { balance_total: balance.toFixed(2), isDebt: true };
  } else if (balance < 0) {
    return { balance_total: Math.abs(balance).toFixed(2), isDebt: false };
  } else {
    return { balance_total: "0.00", isDebt: false };
  }
}

export default async function Calculate_status_coms_drivers(Travels) {
  let travel_info = [];
  let sum_gains = 0;
  let sum_gains_card = 0;
  let sum_iva = 0;
  let sum_isr = 0;
  let sum_uso_plataforma = 0;
  let sum_cuota_de_solicitud = 0;
  let sum_comission = 0;

  for (let i in Travels) {
    let Travel = Travels[i];
    const date = convertDate(Travel?.time_request);
    const tarifadelviaje = Travel?.subtotal_tarifa_dinamica || 0;
    const IVA = Travel?.plataforma_iva || 0;
    const ISR = Travel?.impuestos_isr || 0;
    const uso_plataforma = Travel?.uso_plataforma || 0;
    const paymentMethod = convertMethodPay(Travel?.payment_method);
    const total_conductor = Travel?.pago_total || 0;
    const cuota_de_solicitud = Travel?.cuota_de_solicitud || 0;
    const comission = calculateCommission(tarifadelviaje, 0.15);
    travel_info.push([
      date,
      tarifadelviaje + cuota_de_solicitud,
      IVA,
      ISR,
      uso_plataforma,
      cuota_de_solicitud,
      comission,
      paymentMethod,
      total_conductor,
    ]);

    sum_gains = sum_gains + total_conductor;
    sum_iva = sum_iva + IVA;
    sum_isr = sum_isr + ISR;
    sum_uso_plataforma = sum_uso_plataforma + uso_plataforma;
    sum_cuota_de_solicitud = sum_cuota_de_solicitud + cuota_de_solicitud;
    sum_comission = sum_comission + comission;

    if (paymentMethod === "TARJETA") {
      sum_gains_card = sum_gains_card + total_conductor;
    }
  }

  const res = {
    isDebt: calcularBalance(sum_gains, sum_gains_card).isDebt,
    movementData: travel_info,
    totalGain: sum_gains.toFixed(2),
    netAmount: (sum_gains - sum_gains_card).toFixed(2),
    iva: sum_iva.toFixed(2),
    isr: sum_isr.toFixed(2),
    platformFees: sum_uso_plataforma.toFixed(2),
    totalCommission: sum_comission.toFixed(2),
    balanceTotalAmount: calcularBalance(sum_gains, sum_gains_card)
      .balance_total,
    applicationFee: sum_cuota_de_solicitud.toFixed(2),
  };

  return res;
}
