/* eslint-disable */

import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { IconButton, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccordionFilters from "./AccordionFilters";

export default function OpenDrawerFilters({
  setDateRanges,
  copybundledDrivers,
  setDrivers,
  arre_travels,
  weeks,
  setweekselect,
}) {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      <Tooltip title="Ver más opciones">
        <IconButton
          onClick={toggleDrawer}
          aria-label="OpenFilters"
          sx={{
            color: "#FFFFFF",
            border: "1px solid #004990",
            backgroundColor: "#004990",
            "&:hover": {
              backgroundColor: "#003080",
            },
          }}
        >
          <KeyboardArrowDownIcon color="white" fontSize="large" />
        </IconButton>
      </Tooltip>

      <Drawer anchor="top" open={isDrawerOpen} onClose={toggleDrawer}>
        <AccordionFilters
          setDateRanges={setDateRanges}
          copybundledDrivers={copybundledDrivers}
          setDrivers={setDrivers}
          opeDrawer={setIsDrawerOpen}
          arre_travels={arre_travels}
          weeks={weeks}
          setweekselect={setweekselect}
        />
      </Drawer>
    </div>
  );
}
