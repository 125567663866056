import { formateDateTitle } from "../CalculateFunctions/CalculateFunctions";
import { header_driversummary } from "./header_driversummary";
import {
  dateheader_driversummary,
  titleheader_driversummary,
} from "./dateheader_driversummary";
import { name_conductor_weekly } from "./driver_name_weekly";
import { travel_conductor_weekly } from "./travel_weekly";
import { footer_driversummary } from "./driver_summary_footer";
import travel_conductor_weekly_adds from "./weekly_adds";

const black = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 1,
};

export default function Sheet_DriverSummary(
  arre_travels,
  rangesDate,
  weekselect
) {
  const bodySheet = [];

  bodySheet.push(dateheader_driversummary(rangesDate,weekselect));
  bodySheet.push(titleheader_driversummary);

  let currentRow = 3;

  for (let i in arre_travels) {
    const name_driver = (
      arre_travels[i]?.driver_info?.name +
      " " +
      arre_travels[i]?.driver_info?.last_name
    ).toUpperCase();
    const travels = arre_travels[i]?.travel_matches;
    bodySheet.push(header_driversummary(currentRow++));
    bodySheet.push(name_conductor_weekly(name_driver, [currentRow, 0]));
    let tarifaTotal = 0;
    let adds = [];
    for (let o in travels) {
      const travel = travels[o];
      tarifaTotal += travel?.tarifa_total || 0;
      adds = [...travel_conductor_weekly_adds(travels)];
      bodySheet.push({
        startRow: currentRow++,
        startColumn: 1,
        rowData: travel_conductor_weekly(travel, weekselect),
      });
    }

    let diferencias = tarifaTotal - adds[1] - adds[2] - adds[3];
    let driver_money = adds[1] + adds[2];

    bodySheet.push(
      footer_driversummary(currentRow++, "Suma Semanal", tarifaTotal, adds)
    );
    bodySheet.push(
      footer_driversummary(currentRow++, "Diferencias", diferencias)
    );
    bodySheet.push(footer_driversummary(currentRow++, "Resumen Semanal", NaN));
    bodySheet.push(
      footer_driversummary(
        currentRow++,
        "Total a recibir conductor",
        driver_money
      )
    );
    bodySheet.push(
      footer_driversummary(currentRow++, "Cobro Niku (E y T)", adds[3])
    );
    bodySheet.push(
      footer_driversummary(currentRow++, "Cobros Efectivo conductor", adds[4])
    );
    bodySheet.push(
      footer_driversummary(
        currentRow++,
        "Saldo final a favor del conductor",
        driver_money > adds[4] ? driver_money - adds[4] : 0
      )
    );
    bodySheet.push(
      footer_driversummary(
        currentRow++,
        "Saldo final por cobrar al conductor",
        adds[4] > driver_money ? adds[4] - driver_money : 0
      )
    );
  }
  return {
    properties: {
      title: formateDateTitle("RESUMENCONDUCTORSEMANAL", rangesDate),
      tabColorStyle: {
        rgbColor: black,
      },
    },
    data: bodySheet,
  };
}
