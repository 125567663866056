/* eslint-disable */
import UseAnimations from "react-useanimations";
import loading from "react-useanimations/lib/loading";
import styles from "./LoginButton.module.css";
import { useLogin } from "../../../hooks";
import { useLoginStore } from "../../../stores/login/use-login-store";

export function LoginButton() {
  const { login } = useLogin();
  const authProcessStarted = useLoginStore((state) => state.authProcessStarted);

  return (
    <button
      className={styles.loginFormBtn}
      disabled={authProcessStarted}
      type="button"
      onClick={login}
    >
      {authProcessStarted ? (
        <UseAnimations animation={loading} size={20} strokeColor="#E95A13" />
      ) : (
        "Iniciar sesión"
      )}
    </button>
  );
}
