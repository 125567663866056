import {
  calculateBalance,
  calculateNetoDeposito,
  convertMethodPay,
} from "../CalculateFunctions/CalculateFunctions";

const black = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 1,
};

function createCell(
  value,
  isBold = false,
  backgroundColor = null,
  position = "LEFT"
) {
  const cell = {
    userEnteredValue: {},
    userEnteredFormat: {
      textFormat: {
        bold: isBold,
        fontSize: 10,
      },
      horizontalAlignment: position,
      verticalAlignment: "MIDDLE",
      wrapStrategy: "WRAP",
      borders: {
        bottom: {
          color: black,
          style: "SOLID",
        },
        top: {
          color: black,
          style: "SOLID",
        },
        left: {
          color: black,
          style: "SOLID",
        },
        right: {
          color: black,
          style: "SOLID",
        },
      },
    },
  };

  if (typeof value === "number") {
    cell.userEnteredValue.numberValue = value;
    cell.userEnteredFormat.horizontalAlignment = "RIGHT";
  } else {
    cell.userEnteredValue.stringValue = value;
  }

  if (backgroundColor) {
    cell.userEnteredFormat.backgroundColorStyle = { rgbColor: backgroundColor };
  }

  return cell;
}

function item_driver(item, INDEX) {
  const position = parseFloat(INDEX);
  const name =
    (
      item?.driver_info?.name +
      " " +
      item?.driver_info?.last_name
    )?.toUpperCase() || "Sin nombre";

  const Travels = item?.travel_matches || [];

  let sumEfect = 0;
  let sumCard = 0;
  let receivedDriver = 0;
  for (let i in Travels) {
    const methodPay = convertMethodPay(Travels[i]?.payment_method);
    const total_conductor = Travels[i]?.pago_total || 0;
    const tarifa_viaje_total = Travels[i]?.tarifa_total || 0;
    const neto_deposito =
      convertMethodPay(methodPay) === "TARJETA"
        ? calculateNetoDeposito(tarifa_viaje_total)
        : tarifa_viaje_total;

    receivedDriver = receivedDriver + neto_deposito;
    if (methodPay === "EFECTIVO") {
      sumEfect = sumEfect + total_conductor;
    }

    if (methodPay === "TARJETA") {
      sumCard = sumCard + total_conductor;
    }
  }

  const cells = [
    createCell(position + 1, true, null, "CENTER"), //Numero de conductor
    createCell(name, false), //Nombre de conductor
    createCell("BBVA", false, null, "CENTER"), //Banco
    createCell("79 14 23 61 97 42 64 98", false, null, "CENTER"), //CLABE
    createCell("S1", false, null, "CENTER"), //Semana
    createCell(sumCard, false), //Saldo final a favor al conductor
    createCell(receivedDriver - sumEfect, false), //Saldo final por cobrar al conductor
    createCell(calculateBalance(sumEfect, sumCard), false, null, "CENTER"), //Estatus
  ];

  return [{ values: cells }];
}

export { item_driver };
