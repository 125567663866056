/* eslint-disable */

import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectWeeksMaterialUI({
  weeks,
  selected,
  setSelection,
  setDateRanges,
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelection(typeof value === "string" ? value.split(",") : value);

    const initialDate = new Date(value.fecha_inicio);
    initialDate.setHours(0, 0, 0, 0);
    const endDate = new Date(value.fecha_fin);
    endDate.setHours(23, 59, 59, 999);
    setDateRanges([initialDate, endDate]);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="SelectionBlock">Seleccion</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple={false}
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          MenuProps={MenuProps}
        >
          {weeks.map((week) => (
            <MenuItem key={week.numero_semana} value={week}>
              {"Semana#" +
                week.numero_semana +
                ":" +
                week.fecha_inicio +
                "-" +
                week.fecha_fin}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
