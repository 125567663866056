/* eslint-disable */
import React, { useState, useEffect } from "react";
import { listOrdersByDate, listPayments } from "../../services";
import { Storage } from "aws-amplify";
import Gatewaycalculatecoms from "../../services/openpay/calculate-Coms";
import ShopCardExpand from "./ShopCardExpand";
import img from "../../../src/assets/nullMG.jpg";
import Down_Icon from "../icons/Down_Icon";
import "../../components/styled/style-cardShop.css";

export default function ShopCard({
  id = "",
  name = "",
  socialname = "",
  email = "",
  phone_number = "",
  availability = false,
  dataBank = "",
  category = "",
  betweenDates = [],
  list_fees = "",
  rfc = "",
  zipCode = "",
  address = "",
  method_pay = "",
  createdAt = "",
  url_image = "",
}) {
  const [idCard, setCard] = useState("");
  const [idClabe, setCLABE] = useState("");
  const [totalCollect, setTotalCollect] = useState(0);
  const [totaldebts, setTotalDebts] = useState(0);
  const { calculateOrders } = Gatewaycalculatecoms();
  const [arreOrders, setArreOrders] = useState([]);
  const [newDates, setNewdates] = useState([]);
  const objShop = {
    address: address || "-",
    clientNumber: phone_number || "-",
    businessName: socialname || "-",
    rfc: rfc || "-",
    zipCode: zipCode || "-",
  };

  const [arreHistory, setarreHistory] = useState([]);
  const [historyExist, setHistoryExist] = useState([]);
  const [hasDebts, setHasdebts] = useState(false);
  const [openprofile, setOpenProfile] = useState(false);
  const [photo, setPhoto] = useState("../../../src/assets/nullMG.jpg");
  const openProfile = () => setOpenProfile(true);
  const shopProfile = {
    name: name || "Sin nombre",
    socialname: socialname || "Sin razón social",
    address: address || "Sin dirección",
    clientNumber: phone_number || "Sin número",
    rfc: rfc || "Sin RFC",
    zipCode: zipCode || "Sin código postal",
    email: email || "Sin email",
    availability: availability || false,
    dataBank: dataBank || "",
    method_pay: method_pay || "",
    createdAt: createdAt || "",
    url_image: url_image || "",
    gains: totalCollect || 0,
    debts: totaldebts || 0,
    newDates: newDates,
    betweenDates: betweenDates,
  };
  const style = {
    txtAvailability: {
      color: availability ? "green" : "red",
      fontSize: "15px",
      fontWeight: "500",
      fontFamily: " Montserrat,sans-serif",
    },
    txtBalance: {
      fontSize: "15px",
      fontWeight: "500",
      fontFamily: " Montserrat,sans-serif",
    },
    profileImage: {
      width: 80,
      height: 80,
      objectFit: "cover",
      borderRadius: 5,
    },
  };

  async function consumeS3Image(url_photoProfile) {
    const response = await Storage.get(url_photoProfile);
    setPhoto(response);
  }

  useEffect(() => {
    consumeS3Image(url_image);
  }, [url_image]);

  async function checkDates() {
    let date1 = "";
    let date2 = "";
    if (typeof betweenDates[0] === "string") {
      date1 = betweenDates[0];
    } else {
      date1 = betweenDates[0].toISOString();
    }
    if (typeof betweenDates[1] === "string") {
      date2 = betweenDates[1];
    } else {
      date2 = betweenDates[1].toISOString();
    }
    setNewdates([date1, date2]);
    await getOrdersforShop([date1, date2]);
  }

  async function checkBankData() {
    let bankData = dataBank || "";
    if (bankData !== "") {
      let convertBank = JSON.parse(bankData);
      let localCard = convertBank.idcard || "";
      let localCLABE = convertBank.idclabe || "";
      if (localCard !== "") setCard(localCard);
      else setCard("");
      if (localCLABE !== "") setCLABE(localCLABE);
      else setCLABE("");
    } else {
      setCard("");
      setCLABE("");
    }
  }

  async function formatxlsx(arreOrders) {
    let arreOfObjects = [];
    //Recorre el arreglo de arreglos  que van llenos de las  de ordenes calculadas
    for (var i in arreOrders) {
      //Recorre cada arreglo para sacar los objetos por tienda y almacenarlos en un arreglo global de todos los objetos de todas las tiendas
      for (var o in arreOrders[i]) {
        arreOfObjects.push(arreOrders[i][o]);
      }
    }
    setArreOrders(arreOfObjects);
  }

  async function getPayments() {
    const paymentsHistory = await listPayments();
    setarreHistory(paymentsHistory);
  }

  function checkBetweenPayments(date) {
    if (historyExist.length > 0) {
      //Existe un historial de la tienda
      for (var i in historyExist) {
        let period = historyExist[i].period || "";
        let status = historyExist[i].status || false;
        if (period !== "") {
          let date1 = JSON.parse(period).dateStart || "";
          let date2 = JSON.parse(period).dateEnd || "";
          if (
            new Date(date).getTime() >= new Date(date1).getTime() ||
            (new Date(date).getTime() <= new Date(date2).getTime() && status)
          )
            return true;
          else return false;
        }
      }
    } else {
      //No tiene historial pero probablemente tenga adeudos
      return false;
    }
  }

  function validateOrdersPay(orders) {
    let newArre = [];
    for (var i in orders) {
      let date = orders[i].updatedAt;
      if (!checkBetweenPayments(date)) newArre.push(orders[i]);
      else newArre = [];
    }
    return newArre;
  }

  async function getOrdersforShop(dates) {
    let arreOrders = [];
    const orderShop = await listOrdersByDate(id, dates);

    if (validateOrdersPay(orderShop).length > 0) {
      arreOrders.push(
        calculateOrders(validateOrdersPay(orderShop) || [], {
          shop_name: name,
          id: id,
          category: category,
          list_fees: list_fees,
        })
      );
    }
    if (arreOrders.length > 0) {
      for (var i = 0; i < arreOrders[0].length; i++) {
        let arreOrdersItem = arreOrders[0][i];
        if (arreOrdersItem.hasOwnProperty("deliveryman")) {
          if (arreOrdersItem.deliveryman === "TOTAL") {
            setTotalCollect(arreOrdersItem.totalCollect);
            setTotalDebts(arreOrdersItem.totalDispersion);
          } else {
            setTotalCollect(0);
            setTotalDebts(0);
          }
        }
      }
    } else {
      setTotalCollect(0);
      setTotalDebts(0);
    }
    formatxlsx(arreOrders);
  }

  function createObjTransactionExisting(
    id,
    createdAt,
    updatedAt,
    frequency,
    period,
    status,
    type,
    total_amount
  ) {
    return {
      id,
      createdAt,
      updatedAt,
      frequency,
      period,
      status,
      type,
      total_amount,
    };
  }

  async function destructureArrePayments() {
    let hstofthisStore = [];
    for (var i in arreHistory) {
      let idShopHistory = arreHistory[i].shop_id.id || "";
      if (id === idShopHistory) {
        hstofthisStore.push(
          createObjTransactionExisting(
            arreHistory[i].id,
            arreHistory[i].createdAt,
            arreHistory[i].updatedAt,
            arreHistory[i].frequency,
            arreHistory[i].period,
            arreHistory[i].status,
            arreHistory[i].type,
            arreHistory[i].total_amount
          )
        );
      }
    }
    setHistoryExist(hstofthisStore);
  }

  useEffect(() => {
    checkDates();
  }, [betweenDates]);

  useEffect(() => {
    checkBankData();
    getOrdersforShop(newDates);
    getPayments();
    destructureArrePayments();
  }, [id]);

  useEffect(() => {
    if (historyExist.length > 0) {
      for (var i in historyExist) {
        if (historyExist[i].status === false) {
          setHasdebts(true);
        } else {
          setHasdebts(false);
        }
      }
    } else {
      setHasdebts(false);
    }
  }, [historyExist]);

  function calculateBalance(gains, debts) {
    if (gains > debts) {
      return gains - debts;
    } else if (debts > gains) {
      return -(debts - gains);
    } else {
      return 0;
    }
  }

  return (
    <div>
      {openprofile ? (
        <ShopCardExpand
          info={shopProfile}
          id={id}
          arre_historyExist={historyExist}
          name={name}
          arrayAllOrders={arreOrders}
          objInfoshop={objShop}
          email={email}
          idCard={idCard}
          idClabe={idClabe}
          availability={availability}
          totalCollect={totalCollect}
          totaldebts={totaldebts}
          createdAt={createdAt}
          setOpenProfile={setOpenProfile}
        />
      ) : (
        <div className="box-shop" onClick={() => openProfile()}>
          <div className="header-box">
            <p style={style.txtAvailability}>
              {availability ? "Tienda activa" : "Tienda desactivada"}
            </p>
            <p style={style.txtBalance}>
              {hasDebts && availability
                ? "Pesenta deudas, verifica sus movimientos"
                : !hasDebts && availability
                ? "Al corriente"
                : "Venta limitada"}
            </p>
          </div>
          <hr className="solid"></hr>

          <div className="data-box-body-shopcard">
            <div className="img-shopCard">
              <img
                style={style.profileImage}
                src={photo}
                alt=""
                onError={() => setPhoto(img)}
              />
            </div>

            <div className="data-info-box-shopcard">
              <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                <p className="txt-title-informationShop">
                  {name || "Sin nombre"}
                </p>
              </div>
              <div className="data-little-box">
                <p className="txt-informationShop">Afiliación:</p>
                <p className="txt-informationShop">
                  {createdAt.substring(0, 10)}
                </p>
              </div>
              <div className="data-little-box">
                <p className="txt-informationShop">ID: </p>
                <p className="txt-informationShop">{id.slice(0, 6)}</p>
              </div>
              <div className="data-little-box">
                <p className="txt-informationShop">Ganancia:</p>
                <p className="txt-informationShop">${totalCollect}</p>
              </div>
              <div className="data-little-box">
                <p className="txt-informationShop">Deuda: </p>
                <p className="txt-informationShop">${totaldebts}</p>
              </div>
            </div>
          </div>

          <hr className="solid"></hr>
          <div className="footer-card-box">
            <button className="button-DownArrow">
              <Down_Icon color={"#333"} />
            </button>

            <p className="txt-informationShop-footer">Balance</p>
            <p className="txt-informationShop-footer">
              ${calculateBalance(totaldebts, totalCollect).toFixed(2)}
            </p>
          </div>
          <hr className="solid"></hr>
        </div>
      )}
    </div>
  );
}
