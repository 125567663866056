import Openpay from './openpay';

const openpay = new Openpay();
const errors = ['request', 'internal', 'gateway'];

async function createSubscriptionOpp (oppID = '', subscriptionObj = { plan_id: '' }) {
  try {
    const data = await openpay.createSubscription(oppID, subscriptionObj);

    if (errors.includes(data.category)) {
      return { success: false, data };
    }

    return { success: true, data };
  } catch (err) {
    return null;
  }
}

export default createSubscriptionOpp;
