import { UpdateState, UpdateStep, UpdateType } from '../../models/market-update';

export const createMarketUpdateReset = (set) => ({
  resetMarketUpdate: () => set({
    marketUpdateStep: UpdateStep.First,
    marketUpdateType: UpdateType.Unselected,
    marketUpdateState: UpdateState.Waiting,
    marketUpdateError: '...',
    marketUpdateButtonIsDisabled: true,
    marketUpdateInputIsDisabled: false,
    marketUpdateStarted: false,
    marketUpdateFile: null,
    marketUpdateFileName: 'Seleccionar archivo...'
  })
});
