export const GetUserAddress = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      user_addresses {
        items {
          address_id {
            country
            outdoor_number
            street
          }
        }
      }
    }
  }
`;

export const orderproducts = /* GraphQL */ `
  query orderproducts($limit: Int) {
    listOrderProducts(limit: $limit) {
      items {
        order_id {
          shop_id {
            id
            shop_name
          }
          id
          products {
            items {
              product_id {
                product_name
              }
              price
              quantity
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const nextorderproducts = /* GraphQL */ `
  query orderproducts($limit: Int, $nextToken: String) {
    listOrderProducts(limit: $limit, nextToken: $nextToken) {
      items {
        order_id {
          shop_id {
            id
            shop_name
          }
          id
          products {
            items {
              product_id {
                product_name
              }
              price
              quantity
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops($limit: Int) {
    listShops(limit: $limit) {
      items {
        id
        shop_name
        phone_number
        discountsArray
        acceptCard
        interclb
        category
        status
        zone
        method_pay
        createdAt
        seller_id {
          email
        }
        list_fees
        business_name
        address_shop
        postal_code
      }
      nextToken
    }
  }
`;

export const nextlistShops = /* GraphQL */ `
  query ListShops($limit: Int, $nextToken: String) {
    listShops(limit: $limit, nextToken: $nextToken) {
      items {
        id
        availability
        shop_name
        phone_number
        discountsArray
        acceptCard
        interclb
        category
        url_image
        status
        zone
        createdAt
        method_pay
        seller_id {
          rfc
          email
        }
        list_fees
        url_documents
        business_name
        address_shop
        postal_code
      }
      nextToken
    }
  }
`;

export const nextListOrdersShop = /* GraphQL */ `
  query ListOrders($id: String, $limit: Int, $nextToken: String) {
    getShop(id: $id) {
      orders(nextToken: $nextToken, limit: $limit) {
        items {
          com_customer
          com_shop
          createdAt
          url_image
          delivery_man_id {
            id
            last_name
            name
          }
          id
          level
          method_pay
          propina
          shipping_fees
          status
          total
        }
      }
    }
  }
`;

export const nextlistShopsName = /* GraphQL */ `
  query ListShops($limit: Int, $nextToken: String, $shop_name: String) {
    listShops(
      filter: { shop_name: { contains: $shop_name } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        availability
        shop_name
        phone_number
        discountsArray
        acceptCard
        interclb
        category
        createdAt
        status
        zone
        method_pay
        seller_id {
          rfc
          email
        }
        list_fees
        url_documents
        business_name
        address_shop
        postal_code
      }
      nextToken
    }
  }
`;

export const getMyOrders = /* GraphQL */ `
  query getMyOrders($id: ID!) {
    getShop(id: $id) {
      url_documents
      orders(
        filter: {
          level: { contains: "FINALIZADO" }
          status: { eq: "FINALIZADO" }
        }
      ) {
        items {
          id
          createdAt
          discount
          total
          final_total
          status
          method_pay
          com_shop
          com_customer
          order_type
          propina
          shop_id {
            percentage_traded {
              value
            }
          }
          delivery_man_id {
            name
            last_name
          }
          products {
            items {
              product_id {
                product_name
                price
              }
            }
          }
        }
      }
      seller_id {
        id
        rfc
      }
    }
  }
`;

export const OrdersFilter = /* GraphQL */ `
  query OrdersFilter($id: ID!, $datesbetween: [String]) {
    getShop(id: $id) {
      url_documents
      orders(
        filter: {
          updatedAt: { between: $datesbetween }
          level: { contains: "FINALIZADO" }
          status: { eq: "FINALIZADO" }
        }
      ) {
        items {
          id
          createdAt
          discount
          total
          final_total
          status
          method_pay
          com_shop
          com_customer
          order_type
          shop_id {
            percentage_traded {
              value
            }
          }
          delivery_man_id {
            name
            last_name
          }
          customer_id {
            id
          }
        }
      }
      seller_id {
        id
        rfc
      }
    }
  }
`;

export const getShopByUserID = /* GraphQL */ `
  query getShopByUserID($id: ID!) {
    getAccount(id: $id) {
      shops {
        items {
          interclb
          description
          address_shop
          business_name
          category
          id
          phone_number
          price
          schedule
          shop_name
          shop_type
          url_image
          url_pdf
          category_shop {
            items {
              category_id {
                category_name
                tags
                id
              }
            }
          }
          availability
          seller_id {
            id
            user_addresses {
              items {
                address_id {
                  id
                  latitude
                  longitude
                }
              }
            }
          }
          products {
            items {
              id
              netcontent
              offerprice
              price
              product_name
              url_image
              description
              tags
              status
            }
          }
        }
      }
    }
  }
`;
// get comments using id account
export const ordersByUpdatedDate = /* GraphQL */ `
  query ordersByUpdatedDate($order_type: String!) {
    ordersByUpdatedDate(
      filter: { order_type: { notContains: $order_type } }
      sortDirection: DESC
      status: "ENVIADA"
    ) {
      items {
        address
        createdAt
        updatedAt
        status
        id
        order_case
        shop_id {
          id
          shop_name
        }
        customer_id {
          id
          name
          notification_token
        }
        shop_id {
          seller_id {
            id
            notification_token
          }
        }
      }
    }
  }
`;

export const getRolByUser = /* GraphQL */ `
  query getRolByUser($id: ID!) {
    getAccount(id: $id) {
      id
      name
      last_name
      role
    }
  }
`;

export const ListSpecificShops = /* GraphQL */ `
  query ListSpecificShops {
    listShops(
      filter: { shop_name: { contains: "Mi Mercadito de la 14" } }
      limit: 100000
    ) {
      items {
        id
        seller_id {
          id
        }
        business_name
      }
    }
  }
`;

export const getpayment = /* GraphQL */ `
  query getpayment($limit: Int!, $eq: Boolean!) {
    listPaymentHistorys(filter: { status: { eq: $eq } }, limit: $limit) {
      items {
        id
        createdAt
        plan
        frequency
        period
        status
        total_amount
        type
        shop_id {
          business_name
          phone_number
          seller_id {
            email
          }
        }
      }
      nextToken
    }
  }
`;

export const nextgetpayment = /* GraphQL */ `
  query getpayment($limit: Int!, $eq: Boolean!, $nextToken: String) {
    listPaymentHistorys(
      filter: { status: { eq: $eq } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        plan
        frequency
        period
        status
        total_amount
        type
        shop_id {
          business_name
          phone_number
          seller_id {
            email
          }
        }
      }
      nextToken
    }
  }
`;

export const getPaymentHistorys = /* GraphQL */ `
  query getPaymentHistory($frequency: String!, $type: String!, $limit: Int!) {
    listPaymentHistorys(
      filter: {
        status: { eq: false }
        frequency: { eq: $frequency }
        type: { eq: $type }
      }
      limit: $limit
    ) {
      items {
        id
        createdAt
        plan
        frequency
        period
        status
        total_amount
        type
        shop_id {
          id
          business_name
          phone_number
          seller_id {
            email
          }
        }
      }
      nextToken
    }
  }
`;

export const getNextPaymentHistorys = /* GraphQL */ `
  query getNextPaymentHistory(
    $frequency: String!
    $type: String!
    $limit: Int!
    $nextToken: String
  ) {
    listPaymentHistorys(
      filter: {
        status: { eq: false }
        frequency: { eq: $frequency }
        type: { eq: $type }
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        plan
        frequency
        period
        status
        total_amount
        type
        shop_id {
          id
          business_name
          phone_number
          seller_id {
            email
          }
        }
      }
      nextToken
    }
  }
`;

export const filterHistoryByPeriod = /* GraphQL */ `
  query filterHistoryByPeriod(
    $frequency: String!
    $type: String!
    $period: String!
    $limit: Int!
  ) {
    listPaymentHistorys(
      filter: {
        status: { eq: true }
        frequency: { eq: $frequency }
        type: { eq: $type }
        period: { eq: $period }
      }
      limit: $limit
    ) {
      items {
        id
        createdAt
        plan
        frequency
        period
        status
        total_amount
        type
        shop_id {
          business_name
          phone_number
          seller_id {
            email
          }
        }
      }
      nextToken
    }
  }
`;

export const filterNextHistoryByPeriod = /* GraphQL */ `
  query filterNextHistoryByPeriod(
    $frequency: String!
    $type: String!
    $period: String!
    $limit: Int!
    $nextToken: String
  ) {
    listPaymentHistorys(
      filter: {
        status: { eq: true }
        frequency: { eq: $frequency }
        type: { eq: $type }
        period: { eq: $period }
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        plan
        frequency
        period
        status
        total_amount
        type
        shop_id {
          business_name
          phone_number
          seller_id {
            email
          }
        }
      }
      nextToken
    }
  }
`;

export const listOrdersByDate = /* GraphQL */ `
  query ListOrdersByDate(
    $datesBetween: [String]
    $limit: Int!
    $nextToken: String
  ) {
    listOrders(
      filter: {
        level: { contains: "FINALIZADO" }
        status: { eq: "FINALIZADO" }
        updatedAt: { between: $datesBetween }
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        discount
        total
        final_total
        status
        method_pay
        com_shop
        com_customer
        order_type
        propina
        shop_id {
          percentage_traded {
            value
          }
        }
        delivery_man_id {
          id
          name
          last_name
        }
        products {
          items {
            product_id {
              product_name
              price
            }
          }
        }
        shipping_fees
      }
      nextToken
    }
  }
`;

export const listTravelsDelivery = /* GraphQL */ `
  query ListOrdersDelivery($limit: Int!, $nextToken: String) {
    listOrders(
      limit: $limit
      nextToken: $nextToken
      filter: { level: { eq: "FINALIZADO" }, status: { eq: "FINALIZADO" } }
    ) {
      items {
        id
        id
        order_case
        order_type
        method_pay
        nc
        level
        status
        total
        final_total
        com_customer
        com_shop
        createdAt
        propina
        shipping_fees
        updatedAt
        platform_use
        delivery_man_id {
          register_approved
          id
          name
          last_name
          role
          url_photoProfile
          createdAt
          delivery_transport
          openPay_id
          levels_byAccount {
            items {
              id_level {
                id
                name
                platform_usage
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listDeliverymen = /* GraphQL */ `
  query ListDeliverymen(
    $limit: Int!
    $nextToken: String
    $orderLimit: Int!
    $orderNextToken: String
  ) {
    accountsByRole(
      role: "deliveryman"
      filter: {
        register_approved: { attributeExists: true }
        email: { notContains: "registerDelivery@tochange.som" }
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        register_approved
        id
        name
        last_name
        role
        url_photoProfile
        createdAt
        delivery_transport
        openPay_id
        levels_byAccount {
          items {
            id_level {
              id
              name
              platform_usage
            }
          }
        }
        deliveryman_orders(limit: $orderLimit, nextToken: $orderNextToken) {
          items {
            id
            order_case
            order_type
            method_pay
            nc
            level
            status
            total
            final_total
            com_customer
            com_shop
            createdAt
            propina
            shipping_fees
            updatedAt
            platform_use
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listDeliverymenName = /* GraphQL */ `
  query ListDeliverymen($limit: Int!, $nextToken: String, $name: String) {
    accountsByRole(
      role: "deliveryman"
      filter: {
        register_approved: { attributeExists: true }
        name: { contains: $name }
        email: { notContains: "registerDelivery@tochange.som" }
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        register_approved
        id
        name
        last_name
        role
        url_photoProfile
        createdAt
        delivery_transport
        openPay_id
      }
      nextToken
    }
  }
`;

export const ListorderDeliverySum =
  /* GraphQL */
  `
    query ListorderDelivery($id: ID!, $nextToken: String, $limit: Int) {
      getAccount(id: $id) {
        deliveryman_orders(
          filter: { status: { eq: "FINALIZADO" }, level: { eq: "FINALIZADO" } }
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            order_case
            order_type
            method_pay
            nc
            level
            status
            total
            final_total
            com_customer
            com_shop
            createdAt
            propina
            shipping_fees
            updatedAt
            platform_use
          }
          nextToken
        }
      }
    }
  `;

export const ListePaymentHistory =
  /* GraphQL */
  `
    query listePaymentHistory($limit: Int, $nextToken: String) {
      listPaymentHistorys(limit: $limit, nextToken: $nextToken) {
        items {
          id
          period
          plan
          createdAt
          frequency
          status
          total_amount
          type
          updatedAt
          shop_id {
            id
          }
        }
        nextToken
      }
    }
  `;
