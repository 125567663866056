import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';

Amplify.configure(awsconfig);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
