/* eslint-disable */
import { Fragment } from "react";
import "./success.css";

export default function SuccessCheckMark({ hide = false }) {
  return (
    <>
      <div className="success-checkmark">
        <div className="check-icon" hidden={!hide}>
          <span className="icon-line line-tip" />
          <span className="icon-line line-long" />
          <div className="icon-circle" />
          <div className="icon-fix" />
        </div>
      </div>
      <div className="spacing" />
    </>
  );
}
