/* eslint-disable */
import React, { useState } from "react";
import "./Snackbar.css"; // Estilos CSS para el Snackbar

const Snackbar = ({ showSnackbar, text }) => {
  return (
    <div className="snackbar-container">
      {showSnackbar && (
        <div className="snackbar">
          <span>{text}</span>
        </div>
      )}
    </div>
  );
};

export default Snackbar;
