/* eslint-disable */
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Calendar_Icon from "../icons/Calendar_Icon";
import Down_Icon from "../icons/Down_Icon";

export default function DateRangePicker({
  startDate,
  endDate,
  setDateRange,
  disabled,
  maxDate,
  controlWidth,
}) {
  const showStart = startDate?.toISOString()?.substring(0, 10) || "";
  const showEnd = endDate?.toISOString()?.substring(0, 10) || "";
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const style_btn = {
    backgroundColor: "#ffffff",
    color: "#333",
    height: "4vh",
    fontSize: "15px",
    borderRadius: "8px",
    border: "1px solid rgb(229, 237, 255)",
    cursor: "pointer",
    fontWeight: "500",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "15px",
    flexDirection: "row",
    display: "flex",
    transition: "background-color 0.3s, color 0.3s, transform 0.3s",
    transform: isHovered ? "scale(1.05)" : "scale(1)",
  };
  /* >= 1194 ? */
  return (
    <div>
      <DatePicker
        /*     className="datepickerRender" */
        portalId="root-portal"
        locale={es}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        customInput={
          <button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={style_btn}
          >
            <Calendar_Icon color={"#333"} />

            {controlWidth >= 1194 ? (
              <>
                <p>
                  Filtra:{" "}
                  {showStart === showEnd
                    ? "Sin seleccionar"
                    : showStart + " - " + showEnd}
                </p>
                <Down_Icon color={"#333"} />
              </>
            ) : (
              <Down_Icon color={"#333"} />
              )}
          </button>
        }
        disabled={disabled}
        maxDate={maxDate}
        showYearDropdown
        showMonthDropdown
      />
    </div>
  );
}
