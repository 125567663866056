/* eslint-disable */
import { SendIcon } from "../icons/SendIcon";

export function BulletPoint({ children = <span>Bullet Point</span> }) {
  const css = "black-txt body-txt disp-flex regular-txt start-top bullet-point";

  return (
    <p className={css}>
      <SendIcon />
      &nbsp;
      {children}
    </p>
  );
}
