import { showErrorToast } from '../../utilities';
import { useMarketUpdateStore } from '../../stores';

export const useFileSelection = () => {
  const resetFile = useMarketUpdateStore((state) => state.resetMarketUpdateFile);
  const setBtnIsDisabled = useMarketUpdateStore((state) => state.setMarketUpdateButtonIsDisabled);
  const setErrorFile = useMarketUpdateStore((state) => state.setMarketUpdateErrorFile);
  const setFile = useMarketUpdateStore((state) => state.setMarketUpdateFile);
  const setFileName = useMarketUpdateStore((state) => state.setMarketUpdateFileName);
  const setUpdateStarted = useMarketUpdateStore((state) => state.setMarketUpdateStarted);

  const onResetFile = () => {
    resetFile();
    setBtnIsDisabled(true);
  };

  const onChangeFile = (fileSelected) => {
    setFile(fileSelected);
    setFileName(fileSelected.name);
    setBtnIsDisabled(false);
  };

  const onErrorFile = () => {
    const message = 'El archivo seleccionado no es compatible.';
    setErrorFile(message);
    showErrorToast(message);
    setUpdateStarted(false);
  };

  return {
    selectFile: (e) => {
      e.preventDefault();

      const fileSelected = e.target.files[0];
      if (!fileSelected) return onResetFile();

      const extension = fileSelected.name.split('.').pop().toLowerCase();
      if (['fdb', 'fbk'].includes(extension)) return onChangeFile(fileSelected);

      onErrorFile();
    }
  };
};
