const white = {
  red: 1,
  green: 1,
  blue: 1,
  alpha: 1,
};
const green = {
  red: 0.573,
  green: 0.816,
  blue: 0.314,
  alpha: 1,
};
const yellow = {
  red: 1,
  green: 0.753,
  blue: 0,
  alpha: 1,
};
const black = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 1,
};

export function Subtotal_conductor_global(postions, import_results) {
  return {
    startRow: postions[0],
    startColumn: postions[1],
    rowData: [
      {
        values: [
          {
            userEnteredValue: {
              stringValue: "SUMA",
            },
            userEnteredFormat: {
              textFormat: {
                bold: true,
                foregroundColorStyle: {
                  rgbColor: black,
                },
                fontSize: 10,
              },
              backgroundColorStyle: {
                rgbColor: green,
              },
              horizontalAlignment: "LEFT",
              verticalAlignment: "MIDDLE",
              wrapStrategy: "CLIP",
            },
          },
          {
            userEnteredValue: {
              stringValue: "",
            },
          },
          {
            userEnteredValue: {
              stringValue: "",
            },
          },
          {
            userEnteredValue: {
              numberValue: import_results.sumPlatfromIVA,
            },
            userEnteredFormat: {
              textFormat: {
                bold: true,
                foregroundColorStyle: {
                  rgbColor: black,
                },
                fontSize: 10,
              },
              backgroundColorStyle: {
                rgbColor: green,
              },
              horizontalAlignment: "RIGHT",
              verticalAlignment: "MIDDLE",
              wrapStrategy: "CLIP",
            },
          },
          {
            userEnteredValue: {
              numberValue: import_results.sumCommission,
            },
            userEnteredFormat: {
              textFormat: {
                bold: true,
                foregroundColorStyle: {
                  rgbColor: black,
                },
                fontSize: 10,
              },
              backgroundColorStyle: {
                rgbColor: green,
              },
              horizontalAlignment: "RIGHT",
              verticalAlignment: "MIDDLE",
              wrapStrategy: "CLIP",
            },
          },
        ],
      },
    ],
  };
}
