import { UpdateState } from '../../models/market-update';
import { updateMarketProducts } from '../../services';
import { useMarketUpdateStore } from '../../stores';

export const useUpdateProcess = () => {
  const marketUpdateType = useMarketUpdateStore(state => state.marketUpdateType);
  const setMarketUpdateStep = useMarketUpdateStore(state => state.setMarketUpdateStep);
  const setUpdateStarted = useMarketUpdateStore(state => state.setMarketUpdateStarted);
  const setUpdateState = useMarketUpdateStore(state => state.setMarketUpdateState);

  return {
    onUpdateProcess: async () => {
      setUpdateState(UpdateState.Loading);
      const res = await updateMarketProducts(marketUpdateType);
      setUpdateStarted(false);

      if (res === 'ERROR') {
        setUpdateState(UpdateState.Error);
        return;
      }

      setUpdateState(UpdateState.Success);
      setMarketUpdateStep(2);
    }
  };
};
