/* eslint-disable */
import { BulletPoint } from "./BulletPoint";
import { InputFile } from "./InputFile";
import { LoadingModal } from "../common";
import { MainButton } from "../button";
import { Subtitle } from "../font";
import { useMarketUpdateStore } from "../../stores";
import { useUploadFile } from "../../hooks";

export function FirstStep() {
  const css = "disp-flex dir-row start-center step";
  const btnIsDisabled = useMarketUpdateStore(
    (state) => state.marketUpdateButtonIsDisabled
  );
  const updateStarted = useMarketUpdateStore(
    (state) => state.marketUpdateStarted
  );
  const { uploadFile } = useUploadFile();

  return (
    <>
      <LoadingModal open={updateStarted} text="Subiendo archivo" />

      <div className={css}>
        <Subtitle>
          Seleccione el archivo con las siguientes especificaciones y presione
          el botón "Continuar".
        </Subtitle>
      </div>

      <BulletPoint>Debe tener la extensión ".fdb".</BulletPoint>
      <BulletPoint>
        {'Se encuentra en la ruta: "C:\\Microsip datos".'}
      </BulletPoint>
      <BulletPoint>Se llama "MARKET.FDB".</BulletPoint>

      <InputFile />

      <div className="disp-flex dir-row right-corner margin-corner">
        <MainButton
          disabled={btnIsDisabled}
          isLoading={updateStarted}
          onClick={uploadFile}
        >
          Continuar
        </MainButton>
      </div>
    </>
  );
}
