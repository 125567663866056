import { createUpdatesMarketLog } from '../common/mutation-functions';

export async function createConsortiumLog ({ status = '', type = '' }) {
  try {
    const from = 'CMS-WEB-CLIENT';
    const userId = process.env.REACT_APP_MARKET_ID;
    const date = new Date().toISOString();
    const resp = await createUpdatesMarketLog({ date, from, status, type, userId });

    return resp;
  } catch (exception) {
    return null;
  }
}
