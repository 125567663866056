/* eslint-disable */
import { useState } from 'react';
import 'firebase/firestore';
import { dbFinance } from '../../services/firebaseFinance/firebaseFinance';
import { collection, addDoc } from 'firebase/firestore';

const RegisterToCollectionFinance = (collectionName) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addDocument = async (data) => {
    setLoading(true);
    setError(null);

    try {
      const docRef = await addDoc(collection(dbFinance, collectionName), data);
      console.log('Document written with ID: ', docRef.id);

      setLoading(false);
    } catch (error) {
      console.log('🚀 ~ file: RegisterToCollectionFinance.js:18 ~ addDocument ~ error:', error);
      setError(error);
      setLoading(false);
    }
  };

  return [addDocument, loading, error];
};

export default RegisterToCollectionFinance;
