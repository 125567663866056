import {
  calculateCommission,
  calculateDifIVA,
  convertDate,
  convertMethodPay,
  convertToFixedAndNumber,
  totalgetNiku,
} from "../CalculateFunctions/CalculateFunctions";

const blueGainNiku = {
  red: 0.427,
  green: 0.62,
  blue: 0.922,
  alpha: 1,
};

const green = {
  red: 0.573,
  green: 0.816,
  blue: 0.314,
  alpha: 1,
};

const yellow = {
  red: 1,
  green: 0.753,
  blue: 0,
  alpha: 1,
};

function createCell(value, isBold = false, backgroundColor = null) {
  const cell = {
    userEnteredValue: {},
    userEnteredFormat: {
      textFormat: {
        bold: isBold,
        fontSize: 10,
      },
      horizontalAlignment: "LEFT",
      verticalAlignment: "MIDDLE",
      wrapStrategy: "CLIP",
    },
  };

  if (typeof value === "number") {
    cell.userEnteredValue.numberValue = value;
    cell.userEnteredFormat.horizontalAlignment = "RIGHT";
  } else {
    cell.userEnteredValue.stringValue = value;
  }

  if (backgroundColor) {
    cell.userEnteredFormat.backgroundColorStyle = { rgbColor: backgroundColor };
  }

  return cell;
}

function travel_conductor_accounting(item, dateRegisterRFC) {
  const tripId = item.trip_id || "Sin trip_id";
  const date = convertDate(item?.time_request);
  const paymentMethod = item?.payment_method || "Ninguno";
  let rfc = item?.driver_data?.rfc || "Sin RFC";
  const tarifadelviaje = item?.subtotal_tarifa_dinamica || 0;
  const cuota_de_solicitud = item?.cuota_de_solicitud || 0;
  const total_conductor = item?.pago_total || 0;
  const subtotal_sin_impuestos = item?.subtotal_sin_impuestos || 0;
  const dateTravel = new Date(item?.time_request);
  const iva8 = item?.plataforma_iva || 0;
  const isr2_1 = item?.impuestos_isr || 0;
  const uso_plataforma = item?.uso_plataforma || 0;

  if (dateRegisterRFC !== null) {
    if (dateTravel < dateRegisterRFC) {
      rfc = "Sin RFC";
    }
  }

  const cells = [
    createCell(tripId, true), //Id del viaje
    createCell(date), //Fecha del viaje
    createCell(convertMethodPay(paymentMethod)), //Metodo de pago
    createCell(rfc), //RFC
    createCell(convertToFixedAndNumber(tarifadelviaje), true, green), //Tarifa del viaje
    createCell(convertToFixedAndNumber(cuota_de_solicitud), true, blueGainNiku), //Cuota de solicitud
    createCell(
      calculateDifIVA(subtotal_sin_impuestos, [0.16, 0.08]),
      true,
      blueGainNiku
    ), //DIF RET IVA
    createCell(convertToFixedAndNumber(isr2_1), true, blueGainNiku), //ISR 2.1
    createCell(uso_plataforma, true, blueGainNiku), //Uso de plataforma 5%
    createCell(calculateCommission(tarifadelviaje, 0.15), true, blueGainNiku), //Comisión 15%
    createCell(total_conductor, true, yellow), //Total a recibir conductor
    createCell(
      totalgetNiku(
        cuota_de_solicitud,
        iva8,
        isr2_1,
        uso_plataforma,
        calculateCommission(tarifadelviaje, 0.15)
      ),
      true,
      blueGainNiku
    ), //Total a recibir niku
  ];

  return [{ values: cells }];
}

export { travel_conductor_accounting };
