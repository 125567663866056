/* eslint-disable */
import * as React from "react";

const Calendar_Icon = ({ color }) => (
  <svg width={14} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.23 14.465H1.768A1.769 1.769 0 0 1 0 12.698V3.284c0-.975.792-1.767 1.767-1.767h10.466c.975 0 1.767.792 1.767 1.767v9.414a1.772 1.772 0 0 1-1.77 1.767ZM1.768 2.581a.706.706 0 0 0-.705.705V12.7c0 .389.317.705.705.705h10.466a.706.706 0 0 0 .705-.705V3.284a.706.706 0 0 0-.705-.705H1.767v.002Z"
      fill={color}
    />
    <path
      d="M13.467 3.284v2.515H.53V3.284c0-.682.552-1.236 1.236-1.236h10.466c.68.002 1.234.554 1.234 1.236Z"
      fill={color}
    />
    <path
      d="M9.96 3.717h-.166a.958.958 0 0 1-.958-.958V.958c0-.529.43-.958.958-.958h.166c.529 0 .958.43.958.958v1.801c0 .53-.43.958-.958.958ZM4.246 3.717h-.165a.958.958 0 0 1-.958-.958V.958c0-.529.429-.958.958-.958h.165c.53 0 .958.43.958.958v1.801c0 .53-.429.958-.958.958ZM4.189 9.412H2.676a.426.426 0 0 1-.425-.425V7.475c0-.234.192-.425.425-.425H4.19c.233 0 .424.191.424.425v1.512a.426.426 0 0 1-.424.425ZM7.789 9.412H6.277a.426.426 0 0 1-.425-.425V7.475c0-.234.191-.425.425-.425h1.512c.234 0 .425.191.425.425v1.512a.426.426 0 0 1-.425.425ZM11.39 9.412H9.877a.426.426 0 0 1-.425-.425V7.475c0-.234.192-.425.425-.425h1.513c.233 0 .424.191.424.425v1.512a.426.426 0 0 1-.424.425Z"
      fill={color}
    />
  </svg>
);

export default Calendar_Icon;
