import {
  convertDate,
  convertMethodPay,
  calculateCommission,
  totalgetNiku,
} from "../CalculateFunctions/CalculateFunctions";

function createCell(
  value,
  isBold = false,
  backgroundColor = null,
  position = "LEFT"
) {
  const cell = {
    userEnteredValue: {},
    userEnteredFormat: {
      textFormat: {
        bold: isBold,
        fontSize: 8,
      },
      horizontalAlignment: position,
      verticalAlignment: "MIDDLE",
      wrapStrategy: "LEGACY_WRAP",
    },
  };

  if (typeof value === "number") {
    cell.userEnteredValue.numberValue = value;
    cell.userEnteredFormat.horizontalAlignment = "RIGHT";
  } else {
    cell.userEnteredValue.stringValue = value;
  }

  if (backgroundColor) {
    cell.userEnteredFormat.backgroundColorStyle = { rgbColor: backgroundColor };
  }

  return cell;
}

function travel_conductor_weekly(item, weekselect) {
  const tripId = item.trip_id || "Sin trip_id";

  const date =
    weekselect !== null
      ? weekselect?.numero_semana
      : convertDate(item?.time_request);

  const paymentMethod = item?.payment_method || "Ninguno";
  const tarifa_viaje_total = item?.tarifa_total || 0;
  const pago_total = item?.pago_total || 0;
  const iva8 = item?.plataforma_iva || 0;
  const isr2_1 = item?.impuestos_isr || 0;
  const uso_plataforma = item?.uso_plataforma || 0;
  const cuota_de_solicitud = item?.cuota_de_solicitud || 0;

  const cells = [
    createCell(date), //Fecha del viaje
    createCell(tripId, true), //Id del viaje
    createCell(convertMethodPay(paymentMethod)), //Metodo de pago
    createCell(tarifa_viaje_total, true), //Total del viaje
    createCell(
      convertMethodPay(paymentMethod) !== "TARJETA" ? pago_total : 0,
      true
    ), //Total pagado efectivo
    createCell(
      convertMethodPay(paymentMethod) === "TARJETA" ? pago_total : 0,
      true
    ), //Total pagado tarjeta
    createCell(
      totalgetNiku(
        cuota_de_solicitud,
        iva8,
        isr2_1,
        uso_plataforma,
        calculateCommission(tarifa_viaje_total, 0.15)
      )
    ),
    createCell(
      convertMethodPay(paymentMethod) === "TARJETA" ? 0 : tarifa_viaje_total
    ),
  ];

  return [{ values: cells }];
}

export { travel_conductor_weekly };
