/* eslint-disable */

import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function DatePickerEndMaterialUI({
  label,
  endDate,
  setEndDate,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={endDate}
        onChange={(newValue) => setEndDate(newValue?.$d)}
        label={label}
        displayWeekNumber
        orientation="portrait"
      />
    </LocalizationProvider>
  );
}
