/* eslint-disable */
import React from "react";
import DataTable from "react-data-table-component";
import "react-tabs/style/react-tabs.css";
export default function TableEquipments({ Equipments }) {
  const columns = [
    {
      name: "Fecha de registro",
      selector: (row) => row.date,
    },
    {
      name: "Conductor",
      selector: (row) => row.driver?.name + " " + row.driver?.last_name,
      sortable: true,
      cell: (row) => (
        <p>{(row.driver?.name + " " + row.driver?.last_name).toUpperCase()}</p>
      ),
    },
    {
      name: "Equipo",
      selector: (row) => row.equipment,
    },
    {
      name: "Pagado",
      selector: (row) => row.paid,
    },
    {
      name: "Lugar de instalación",
      selector: (row) => row.place_install,
    },
    {
      name: "Pago",
      selector: (row) => row.amount,
      cell: (row) => <p>${row.amount}</p>,
    },
  ];

  const tableCustomStyles = {
    table: {
      style: {
        zIndex: 0,
        height: "70vh",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Viajes por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          backgroundColor: "red",
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "10px",
        }}
      >
        <DataTable
          title="Lista de conductores equipados"
          columns={columns}
          data={Equipments}
          fixedHeader
          pagination
          paginationComponentOptions={paginationComponentOptions}
          customStyles={{
            headCells: {
              style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                backgroundColor: "#004990",
                color: "#FFFFFF",
              },
            },
            table: {
              style: {
                minWidth: "100%",
                width: "60vw",
                borderCollapse: "separate",
                height: "70vh",
              },
            },
            cells: {
              style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                border: "1px solid rgb(229, 237, 255)",
              },
            },
          }}        />
      </div>
    </div>
  );
}
