/* eslint-disable */
import * as React from 'react';

const Search_Icon = (props) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.793 14.06c3.2 0 5.793-2.7 5.793-6.03S10.992 2 7.793 2C4.593 2 2 4.7 2 8.03s2.594 6.03 5.793 6.03ZM16 15.568l-3.15-3.28"
      stroke="#4F4F4F"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Search_Icon;
