/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import TableHIstoryShop from "./TableHIstoryShop";
import Close_Icon from "../icons/Close_Icon";
import DateRangePicker from "../DateRangePicker/DateRangePicker";

export default function HistoryModalShop({
  id,
  arre_historyExist,
  name,
  open,
  handleClose,
}) {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const ref = useRef(null);

  useEffect(() => {
    if (open) {
      ref.current?.showModal();
      document.body.classList.add("modal-open"); // prevent bg scroll
    } else {
      ref.current?.close();
      document.body.classList.remove("modal-open");
    }
  }, [open]);

  return (
    <div>
      <dialog className="modal" open={open}>
        <div className="content-dialog">
          <div className="header-dialog">
            <p id="modal-modal-title" variant="h6" component="h2">
              Historial de movimientos - {name}
            </p>
            <div>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setDateRange={setDateRange}
                disabled={false}
                maxDate={new Date()}
              />
            </div>

            <button
              className="button-close"
              aria-label="delete"
              onClick={handleClose}
            >
              <Close_Icon />
            </button>
          </div>

          <TableHIstoryShop arreHistory={arre_historyExist} />
        </div>
      </dialog>
    </div>
  );
}
