import { toast } from 'react-hot-toast';

const OPTIONS = {
  duration: 4000,
  position: 'top-center',
  style: {
    borderRadius: '8px',
    background: '#333',
    color: '#fff'
  }
};

export function showErrorToast (message) {
  toast.error(message, OPTIONS);
}

export function showSuccessToast (message) {
  toast.success(message, OPTIONS);
}
