/* eslint-disable */
import { useEffect, useState } from "react";
import "./Dashboard.css";
import HeaderSelectionHistoryDrivers from "../../components/HeaderSelectionHistoryDrivers/HeaderSelectionHistoryDrivers";
import GetDataTable from "../../hooks/HistoryDrivers/GetDataTable.js";
import TableHistoryGlobals from "../../components/DriverHistory/TableHistoryGlobals";
import TableHistoryIndividual from "../../components/DriverHistory/TableHistoryIndividual";
import TableHistoryIndividualSends from "../../components/DriverHistory/TableHistorySends";
import { LoadingModal } from "../../components";
export default function ListHistoryDriver() {
  const [FilterBy, setFilterBy] = useState(0);

  const { GetInfoTable, dataTable, isLoading } = GetDataTable();

  useEffect(() => {
    GetInfoTable(FilterBy);
  }, [FilterBy]);
  useEffect(() => {
    document.title = `SGFC - Historial de movimientos en el sistema`;
  }, []);
  return (
    <>
      <HeaderSelectionHistoryDrivers
        FilterBy={FilterBy}
        setFilterBy={setFilterBy}
      />
      {isLoading ? (
        <LoadingModal
          onClose={!isLoading}
          open={isLoading}
          text={"Cargando historial de movimientos..."}
        />
      ) : FilterBy === 0 ? (
        <div style={{ padding: "10px", height: "90vh" }}>
          <TableHistoryGlobals Globals={dataTable} />
        </div>
      ) : FilterBy === 1 ? (
        <div style={{ padding: "10px", height: "90vh" }}>
          <TableHistoryIndividual Globals={dataTable} />
        </div>
      ) : (
        <div style={{ padding: "10px", height: "90vh" }}>
          <TableHistoryIndividualSends Globals={dataTable} />
        </div>
      )}
    </>
  );
}
