import { myFetch } from './my-fetch';

/**
 * Function to post data to the email service
 *
 * @param {string} url - url to send the data
 * @param {object} data - information to send
 */
async function post (url, data) {
  try {
    const response = await myFetch(url, { method: 'POST', body: data });

    return response.json();
  } catch (err) {
    return false;
  }
}

export default post;
