/* eslint-disable */
async function post(url, data) {
  try {
    const response = await fetch(url, { method: 'POST', body: JSON.stringify(data) });
    return response.json();
  } catch (err) {
    return {
      code: 500,
      message: err.message,
      success: false,
      data: null,
    };
  }
}
async function download_status_account_DRIVERS(raw) {
  const url = 'https://niku-cms.com/file/receipt/driver';

  return await post(url, raw);
}

export default download_status_account_DRIVERS;
