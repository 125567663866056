import { UpdateType } from '../../models/market-update';
import { createConsortiumLog } from './create-consortium-log';
import { deactivateProductsNotInErp, isEmptyArray, showErrorToast } from '../../utilities';
import { getErpProducts } from './get-erp-products';
import { performUpdateProcess } from './perform-update-process';

export async function updateMarketProducts (marketUpdateType = UpdateType.Unselected) {
  try {
    if (marketUpdateType === UpdateType.Unselected) {
      showErrorToast('Seleccione un tipo de actualización.');
      throw new Error();
    }

    const erpProducts = await getErpProducts(marketUpdateType);
    if (isEmptyArray(erpProducts)) {
      showErrorToast('No se encontraron productos para actualizar. Seleccione otra opción.');
      throw new Error('NO PRODUCTS TO UPDATE');
    }

    if (marketUpdateType === UpdateType.All || marketUpdateType === UpdateType.OutOfStock) {
      await deactivateProductsNotInErp(erpProducts);
    }

    const updatedProductsState = await performUpdateProcess(erpProducts);
    await createConsortiumLog({ status: updatedProductsState, type: marketUpdateType });

    return 'SUCCESS';
  } catch (error) {
    if (error.message !== '') {
      await createConsortiumLog({ status: `ERROR: ${error.message}`, type: marketUpdateType });
    }

    return 'ERROR';
  }
}
