/* eslint-disable */
import { Component } from "react";
const path = require("../../urls.json");

class Openpay extends Component {
  /* Peticiones de api */
  //!PETICION DE CREACION DE DISPERSIONES
  moneyDeposit = (idcl, informationaccount) =>
    new Promise(async (resolve, reject) => {
      try {
        const sendMoney = await fetch(
          path.REACT_APP_UNSPLASH_URLSERVER +
            "dispersion/" +
            idcl +
            "/" +
            JSON.stringify(informationaccount) +
            "",
          {
            method: "GET",
            headers: {},
            mode: "cors",
          }
        );
        const money = await sendMoney.json();
        resolve(money);
      } catch (error) {
        reject(error);
      }
    });

  //!PETICION DE CREACION DE PLANES
  createPlan = (information) =>
    new Promise(async (resolve, reject) => {
      try {
        const plan = await fetch(
          path.REACT_APP_UNSPLASH_URLSERVER +
            "createPlan/" +
            JSON.stringify(information) +
            "",
          {
            method: "GET",
            headers: {},
            mode: "cors",
          }
        );
        const response = await plan.json();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });

  //!PETICION DE CREACION DE SUSCRIPCIONES
  createSubscription = (idcliente, data) =>
    new Promise(async (resolve, reject) => {
      try {
        const subscription = await fetch(
          path.REACT_APP_UNSPLASH_URLSERVER +
            "createSuscription/" +
            idcliente +
            "/" +
            JSON.stringify(data) +
            "",
          {
            method: "GET",
            headers: {},
            mode: "cors",
          }
        );
        const ressubs = await subscription.json();
        resolve(ressubs);
      } catch (error) {
        reject(error);
      }
    });

  //!PETICION DE CREACION DE CARGOS
  createCharges = (data, idcliente) =>
    new Promise(async (resolve, reject) => {
      try {
        const charge = await fetch(
          path.REACT_APP_UNSPLASH_URLSERVER +
            "createPdfpaynet/" +
            JSON.stringify(data) +
            "",
          {
            method: "GET",
            headers: {},
            mode: "cors",
          }
        );
        const resch = await charge.json();
        resolve(resch);
      } catch (error) {
        reject(error);
      }
    });

  //!PETICION DE ELIMINACION DE SUSCRIPCIONES
  deletedSubscription = (idcliente, idsuscription) =>
    new Promise(async (resolve, reject) => {
      try {
        const subscription = await fetch(
          path.REACT_APP_UNSPLASH_URLSERVER +
            "cancelSuscription/" +
            idcliente +
            "/" +
            idsuscription +
            "",
          {
            method: "GET",
            headers: {},
            mode: "cors",
          }
        );
        const ressubs = await subscription.json();
        resolve(ressubs);
      } catch (error) {
        reject(error);
      }
    });

  //DELETE https://sandbox-api.openpay.mx/v1/{MERCHANT_ID}/payouts/{TRANSACTION_ID}
  deletedtRANSACTION = () =>
    new Promise(async (resolve, reject) => {
      try {
        const subscription = await fetch(
          "https://api.openpay.mx/v1/" +
            path.REACT_APP_UNSPLASH_MERCHANTID +
            "/payouts/trxlfpop3idudb38dr00",
          {
            method: "DELETE",
            headers: {},
            mode: "cors",
          }
        );
        const ressubs = await subscription.json();
        resolve(ressubs);
      } catch (error) {
        reject(error);
      }
    });
  //!PETICION DE CREACION DE PLANES
  deletePlan = (idplan) =>
    new Promise(async (resolve, reject) => {
      try {
        const plan = await fetch(
          path.REACT_APP_UNSPLASH_URLSERVER + "deletePlan/" + idplan + "",
          {
            method: "GET",
            headers: {},
            mode: "cors",
          }
        );
        const response = await plan.json();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });

  //!PETICION DE ELIMINACION DE SUSCRIPCIONES
  deleteShops = (idcliente) =>
    new Promise(async (resolve, reject) => {
      try {
        const subscription = await fetch(
          path.REACT_APP_UNSPLASH_URLSERVER + "deletedShop/" + idcliente + "",
          {
            method: "GET",
            headers: {},
            mode: "cors",
          }
        );
        const ressubs = await subscription.json();
        resolve(ressubs);
      } catch (error) {
        reject(error);
      }
    });
}

export default Openpay;
