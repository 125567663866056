import { formateDateTitle } from "../CalculateFunctions/CalculateFunctions";
import { header_listDriversWeek } from "./header_listDrivers";
import { item_driver } from "./item_driver";
import { title_dates } from "./title_dates";

const orange = {
  red: 0.914,
  green: 0.353,
  blue: 0.075,
  alpha: 1,
};
export default function Sheet_listdriversweek(
  arre_travels,
  dateRange,
  weekselect
) {
  const bodySheet = [];

  bodySheet.push(title_dates(dateRange), header_listDriversWeek);
  let currentRow = 6;

  for (let i in arre_travels) {
    bodySheet.push({
      startRow: currentRow++,
      startColumn: 0,
      rowData: item_driver(arre_travels[i], i),
    });
  }

  return {
    properties: {
      title: formateDateTitle("LISTADOSEMANAL", dateRange),
      tabColorStyle: {
        rgbColor: orange,
      },
    },
    data: bodySheet,
  };
}
