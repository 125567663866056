const gray = {
  red: 0.8470588235294118,
  green: 0.8470588235294118,
  blue: 0.8470588235294118,
  alpha: 1,
}

const cyan = {
  red: 0.7686274509803922,
  green: 0.8862745098039215,
  blue: 0.9490196078431372,
  alpha: 1,
}

const black = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 1,
}

const cells = [
  'Conductor',
  'Semana',
  'ID del viaje', 
  'Forma de pago',
  'Total neto',
  'Total a recibir conductor efectivo',
  'Total a recibir conductor tarjeta',
  'Total a recibir Niku',
  'Cobros efectivo conductor viajes realizados',
  'Diferencia',
  'Estatus',
  'Fecha de pago',
  'Importe depositado',
  'Saldo final semanal',
]

function CheckColorinTitle(title) {
  const specifiedcyanCells = [
    'Conductor',
    'Semana',
    'ID del viaje',
    'Forma de pago',
    'Total neto',
    'Total a recibir conductor efectivo',
    'Total a recibir conductor tarjeta',
    'Total a recibir Niku',
    'Cobros efectivo conductor viajes realizados',
    'Diferencia'
  ]

  if (specifiedcyanCells.includes(title)) {
    return cyan
  } else {
    return gray
  }
}

function CellConstruction() {
  const newCells = []
  cells.forEach((element) => {
    newCells.push({
      userEnteredValue: {
        stringValue: element,
      },
      userEnteredFormat: {
        backgroundColorStyle: {
          rgbColor: CheckColorinTitle(element),
        },
        textFormat: {
          bold: true,
          foregroundColorStyle: {
            rgbColor: black,
          },
          fontSize: 8,
        },
        horizontalAlignment: 'CENTER',
        verticalAlignment: 'MIDDLE',
        wrapStrategy: 'LEGACY_WRAP',
      },
    })
  })
  return newCells
}

export function header_driversummary(pos) {
  return {
    startRow: pos,
    startColumn: 0,
    rowData: [
      {
        values: CellConstruction(),
      },
    ],
  }
}
