/* eslint-disable */
import * as React from "react"

const Check_Icon = (props) => (
  <svg
    width={17}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.115 0c-.494 0-.934.201-1.309.547L13.8.553 5.73 8.6 3.208 6.086c-.361-.36-.802-.56-1.305-.56-.501 0-.947.197-1.326.546l-.007.007-.007.007C.2 6.446 0 6.886 0 7.389c0 .501.2.947.549 1.324l.006.008L4.4 12.554c.352.378.808.567 1.33.567.52 0 .977-.189 1.33-.567l9.385-9.359.006-.007c.35-.378.549-.823.549-1.324 0-.503-.201-.944-.563-1.304L16.43.553l-.007-.006C16.048.2 15.608 0 15.115 0Z"
      fill="#3FA012"
    />
  </svg>
)

export default Check_Icon
