/* eslint-disable */
import * as React from 'react';
import Retry_Icon from '../icons/Retry_Icon';
import Download_Icon from '../icons/Download_Icon';
import X_Icon from '../icons/X_Icon';
import Check_Icon from '../icons/Check_Icon';
import '../styled/style-cardShop.css';

export default function TableHIstoryShop({ arreHistory }) {
  return (
    <>
      <table>
        <tr>
          <th>Estado</th>
          <th>Descripcion</th>
          <th>ID transacción</th>
          <th>Creada</th>
          <th>Corte</th>
          <th>Entre fechas</th>
          <th>Tipo </th>
          <th>Total + costo de transacción</th>
          <th>Ultima vez </th>
          <th>Realizada por</th>
          <th>Acción</th>
          <th>Descargar</th>
        </tr>
        {arreHistory.map((row, index) => (
          <tr key={index}>
            <td align="center">{row.status ? <Check_Icon /> : <X_Icon />}</td>

            <td align="center">{row.status ? 'Completada' : 'Fallida'}</td>
            <td scope="row">{row.id.slice(0, 6)}</td>
            <td component="th" scope="row">
              {row.createdAt.substring(0, 10)}
            </td>
            <td>{row.frequency}</td>
            <td>
              {JSON.parse(row.period).dateStart.substring(0, 10) +
                '-' +
                JSON.parse(row.period).dateEnd.substring(0, 10)}
            </td>

            <td>{row.type.toUpperCase()}</td>
            <td>${row.total_amount}</td>
            <td>{row.updatedAt.substring(0, 10)}</td>
            <td>{'45DFGF'}</td>
            {!row.status ? (
              <td onClick={() => console.log('holaa')} sx={{ cursor: 'pointer' }} align="center">
                <Retry_Icon />
              </td>
            ) : (
              <td></td>
            )}

            <td onClick={() => console.log('holaa')} sx={{ cursor: 'pointer' }} align="center">
              <Download_Icon />
            </td>
          </tr>
        ))}
      </table>
    </>
  );
}
