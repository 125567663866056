import { checkAuth, cognitoSignIn, cognitoSignOut } from "../../services";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserStore } from "../../stores";

export const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setCurrentUser = useUserStore((state) => state.setCurrentUser);

  const signInUser = async (email = "", password = "") => {
    const user = await cognitoSignIn(email, password);

    const origin = checkAuth(user, location);
    console.log("🚀 ~ file: use-auth.js:14 ~ signInUser ~ origin:", origin);

    if (origin !== "") {
      setCurrentUser(user);
      navigate(origin);
      return true;
    }

    await cognitoSignOut();
    setCurrentUser(null);
    return false;
  };

  async function signOutUser() {
    await cognitoSignOut();
    setCurrentUser(null);
    navigate("/");
  }

  return { signInUser, signOutUser };
};
