/* eslint-disable */
import GetpdfDoc from './getPdf';
import { saveAs } from 'file-saver';
import { sendAttachment } from '../../services';

export default function PDFfunctions() {
  const { getPDF } = GetpdfDoc();

  function calculatetarifservice(amount) {
    var numerador = 25000;
    var denominador = 24159;
    var secondNum = 72500;
    var result = amount * (numerador / denominador) + secondNum / denominador;
    return result - amount;
  }
  async function getBinaryRes(obj) {
    const res = await getPDF(obj);
    if (res.success) {
      let binaryString = window.atob(res.data);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      let blob = new Blob([bytes], { type: 'application/pdf' });
      saveAs(blob, `comisiones_envio_${obj.sellerData.businessName || ''}.pdf`);
    } else {
      alert(res.message);
    }
  }
  function toFix(amount) {
    return amount.toFixed(2);
  }
  function toNumber(string) {
    return parseFloat(string);
  }

  async function sendEmailAccount(emailData) {
    const res = await sendAttachment(emailData);
    console.log('Enviar emails a mi email xD', res);
  }
  function exportPdf(array, objShop, betweenDates) {
    let arrayofordersDelive = []; //Este es un arreglo solo para ordenar los items en el pdf DELIVERY
    let arrayofordersFace = []; //Este es un arreglo solo para ordenar los items en el pdf PRESENCIAL
    let arrelittlePdfDelive = []; //Este es un arreglo solo para ordenar los items en el pdf de menor cantidad de newItems
    let arrelittlePdfFace = []; //Este es un arreglo solo para ordenar los items en el pdf de menor cantidad de newItems
    let totalDispersion = 0;
    let totalCollect = 0;
    // El arreglo que llega esta compuesto para imprimir exceles (Puedes iomprimir el array para ver como llega)
    // en este for solo incrmento el primer indice para saltar el primer objeto correspondiente al encabezado del excel
    // y reduzco la longitud a -7 para evitar los objetos de totales de nuestro excel
    // de esta manera obtengo el arreglo como debe mandarse para obtener el bin del nuevo pdf a descargar
    for (let i = 1; i < array.length - 7; i++) {
      //Hago el recorrido de los arreglos que me devuelve el cálculo de estado de cuenta
      //Esta es la asignación
      if (array[i].typeOrder === 'PRESENCIAL') {
        var littleArrays = [
          array[i].saleAmount,
          array[i].addition_comnkdiscount,
          array[i].commissionNiku,
          array[i].addition_retentions,
          array[i].totalDispersion,
          array[i].totalCollect,
        ];

        var arrayFacetoFace = [
          array[i].date,
          array[i].hour,
          array[i].method_Pay,
          array[i].saleAmount,
          array[i].discountCl,
          array[i].mcc,
          array[i].subTotal,
          array[i].commissionNiku,
          array[i].subwithoutIVA,
          array[i].IVA8,
          array[i].ISR1,
          array[i].comSend,
          array[i].comSendCl,
          array[i].propine,
          array[i].totalDispersion,
          array[i].totalCollect,
        ];
        arrelittlePdfFace.push(littleArrays || []);
        arrayofordersFace.push(arrayFacetoFace || []);
      } else {
        var littleArraysDelivery = [
          array[i].saleAmount,
          array[i].addition_comnkdiscount,
          array[i].commissionNiku,
          array[i].addition_retentions,
          array[i].totalDispersion,
          array[i].totalCollect,
        ];

        var arrayDelivery = [
          array[i].date,
          array[i].hour,
          array[i].method_Pay,
          array[i].saleAmount,
          array[i].discountCl,
          array[i].mcc,
          array[i].subTotal,
          array[i].commissionNiku,
          array[i].subwithoutIVA,
          array[i].IVA8,
          array[i].ISR1,
          array[i].comSend,
          array[i].comSendCl,
          array[i].propine,
          array[i].totalDispersion,
          array[i].totalCollect,
        ];

        arrelittlePdfDelive.push(littleArraysDelivery || []);
        arrayofordersDelive.push(arrayDelivery || []);
      }
      totalDispersion = totalDispersion + array[i].totalDispersion;
      totalCollect = totalCollect + array[i].totalCollect;
    }

    const objsend = {
      datesOfMovements: detectedDate(betweenDates)[0] + '-' + detectedDate(betweenDates)[1],
      sellerData: objShop,
      faceToFaceDetailsData: arrayofordersFace,
      deliveryDetailsData: arrayofordersDelive,
      faceToFaceData: arrelittlePdfFace,
      deliveryData: arrelittlePdfDelive,
      totalToReceive:
        toNumber(toFix(totalDispersion)) > 9.28 ? totalDispersion - 9.28 : 0 || '0.00',
      totalPayable: toNumber(toFix(totalCollect)) + calculatetarifservice(totalCollect) || '0.00',
    };
    getBinaryRes(objsend);
  }

  function detectedDate(betweenDates) {
    let date1 = '';
    let date2 = '';

    if (typeof betweenDates[0] === 'string') {
      date1 = betweenDates[0];
    } else {
      date1 = betweenDates[0].toISOString();
    }

    if (typeof betweenDates[1] === 'string') {
      date2 = betweenDates[1];
    } else {
      date2 = betweenDates[1].toISOString();
    }

    return [date1, date2];
  }

  function sendPdf(array, objShop, betweenDates, email) {
    let arrayofordersDelive = []; //Este es un arreglo solo para ordenar los items en el pdf DELIVERY
    let arrayofordersFace = []; //Este es un arreglo solo para ordenar los items en el pdf PRESENCIAL
    let arrelittlePdfDelive = []; //Este es un arreglo solo para ordenar los items en el pdf de menor cantidad de newItems
    let arrelittlePdfFace = []; //Este es un arreglo solo para ordenar los items en el pdf de menor cantidad de newItems
    let totalDispersion = 0;
    let totalCollect = 0;
    // El arreglo que llega esta compuesto para imprimir exceles (Puedes iomprimir el array para ver como llega)
    // en este for solo incrmento el primer indice para saltar el primer objeto correspondiente al encabezado del excel
    // y reduzco la longitud a -7 para evitar los objetos de totales de nuestro excel
    // de esta manera obtengo el arreglo como debe mandarse para obtener el bin del nuevo pdf a descargar
    for (let i = 1; i < array.length - 7; i++) {
      //Hago el recorrido de los arreglos que me devuelve el cálculo de estado de cuenta
      //Esta es la asignación
      if (array[i].typeOrder === 'PRESENCIAL') {
        var littleArrays = [
          array[i].saleAmount,
          array[i].addition_comnkdiscount,
          array[i].commissionNiku,
          array[i].addition_retentions,
          array[i].totalDispersion,
          array[i].totalCollect,
        ];

        var arrayFacetoFace = [
          array[i].date,
          array[i].hour,
          array[i].method_Pay,
          array[i].saleAmount,
          array[i].discountCl,
          array[i].mcc,
          array[i].subTotal,
          array[i].commissionNiku,
          array[i].subwithoutIVA,
          array[i].IVA8,
          array[i].ISR1,
          array[i].comSend,
          array[i].comSendCl,
          array[i].propine,
          array[i].totalDispersion,
          array[i].totalCollect,
        ];
        arrelittlePdfFace.push(littleArrays || []);
        arrayofordersFace.push(arrayFacetoFace || []);
      } else {
        var littleArraysDelivery = [
          array[i].saleAmount,
          array[i].addition_comnkdiscount,
          array[i].commissionNiku,
          array[i].addition_retentions,
          array[i].totalDispersion,
          array[i].totalCollect,
        ];

        var arrayDelivery = [
          array[i].date,
          array[i].hour,
          array[i].method_Pay,
          array[i].saleAmount,
          array[i].discountCl,
          array[i].mcc,
          array[i].subTotal,
          array[i].commissionNiku,
          array[i].subwithoutIVA,
          array[i].IVA8,
          array[i].ISR1,
          array[i].comSend,
          array[i].comSendCl,
          array[i].propine,
          array[i].totalDispersion,
          array[i].totalCollect,
        ];

        arrelittlePdfDelive.push(littleArraysDelivery || []);
        arrayofordersDelive.push(arrayDelivery || []);
      }
      totalDispersion = totalDispersion + array[i].totalDispersion;
      totalCollect = totalCollect + array[i].totalCollect;
    }

    const objsend = {
      datesOfMovements: detectedDate(betweenDates)[0] + '-' + detectedDate(betweenDates)[1],
      sellerData: objShop,
      faceToFaceDetailsData: arrayofordersFace,
      deliveryDetailsData: arrayofordersDelive,
      faceToFaceData: arrelittlePdfFace,
      deliveryData: arrelittlePdfDelive,
      totalToReceive:
        toNumber(toFix(totalDispersion)) > 9.28 ? totalDispersion - 9.28 : 0 || '0.00',
      totalPayable: toNumber(toFix(totalCollect)) + calculatetarifservice(totalCollect) || '0.00',
      email: email || '-',
    };
    sendEmailAccount(objsend);
  }

  return {
    exportPdf,
    sendPdf,
  };
}
