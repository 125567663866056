import { errorLoginMessages, useLoginStore } from '../../stores';
import { showErrorToast } from '../../utilities';
import { useAuth } from './use-auth';

export function useLogin () {
  const { signInUser } = useAuth();
  const email = useLoginStore((state) => state.email);
  const password = useLoginStore((state) => state.password);
  const setAuthProcessStarted = useLoginStore((state) => state.setAuthProcessStarted);
  const setLoginErrorMessage = useLoginStore((state) => state.setLoginErrorMessage);

  const startProcess = () => {
    setAuthProcessStarted(true);
    setLoginErrorMessage('');
  };

  const showErrorMessage = (message = '') => {
    setLoginErrorMessage(message);
    showErrorToast(message);
  };

  return {
    login: async (e) => {
      e.preventDefault();
      startProcess();

      if (!email || !password) {
        showErrorMessage(errorLoginMessages[0]);
        setAuthProcessStarted(false);
        return;
      }

      const res = await signInUser(email, password);
      if (!res) showErrorMessage(errorLoginMessages[1]);

      setAuthProcessStarted(false);
    }
  };
}
