/* eslint-disable */
export function ArrowDropDownIcon({ props }) {
  return (
    <svg
      height="1em"
      viewBox="0 96 960 960"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M480 696 280 497h400L480 696Z" />
    </svg>
  );
}
