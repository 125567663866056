/* eslint-disable */
import { ErrorCheckMark, SuccessCheckMark } from "../common";
import { MainButton, SecondaryButton } from "../button";
import { Subtitle } from "../font";
import { UpdateState, UpdateStep } from "../../models/market-update";
import { useMarketUpdateStore } from "../../stores";

export function ThirdStep() {
  const css = "disp-flex dir-row center text-center";
  const marketUpdateState = useMarketUpdateStore(
    (state) => state.marketUpdateState
  );
  const resetMarketUpdate = useMarketUpdateStore(
    (state) => state.resetMarketUpdate
  );
  const resetMarketUpdateStep = useMarketUpdateStore(
    (state) => state.resetMarketUpdateStep
  );

  const error = marketUpdateState === UpdateState.Error;
  const success = marketUpdateState === UpdateState.Success;

  return (
    <>
      <span className="spacing" />
      <SuccessCheckMark hide={success} />
      <ErrorCheckMark hide={error} />
      <span className="spacing" hidden={!success || !error} />

      <div className={css}>
        <Subtitle>
          {success && "La actualización se ha realizado correctamente."}
          {error &&
            "Lo sentimos, ocurrió un error al actualizar los datos. Inténtelo de nuevo más tarde."}
        </Subtitle>
      </div>

      <div className="disp-flex dir-row right-corner margin-corner">
        <SecondaryButton onClick={resetMarketUpdate}>
          Reiniciar proceso
        </SecondaryButton>

        <span>&nbsp;&nbsp;</span>

        <MainButton onClick={() => resetMarketUpdateStep(UpdateStep.Second)}>
          Seleccionar otra actualización
        </MainButton>
      </div>
    </>
  );
}
