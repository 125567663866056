/* eslint-disable */
import { useEffect, useState } from "react";
import { LoadingModal } from "../../components";
import { DeliveryCard } from "../../components/delivery-list";
import listDeliverymenValidated from "../../services/db/list-deliveryValidated";
import listDeliverymenName from "../../services/db/list-deliverySearch";
import InputSearch from "../../components/InputSearch/InputSearch";
import DropDownListSelection from "../../components/DropDownListSelection/DropSelection";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import PaginationButton from "../../components/PaginationButtons/PaginationButtons";
import "../../components/styled/style-cardDelivery.css";
import "./background.css";
import Next_Icon from "../../components/icons/Next_icon";
import Before_Icon from "../../components/icons/Before_Icon";
import getDatesOrders from "../../services/db/list-deliveryOrders";
import { getAllOrdersDelivery } from "../../services/db/list-TravelsFinished";

export default function List_Delivery() {
  const [dealers, setDealers] = useState([]);
  const [charginData, setDatacharging] = useState(false);
  const [name, setName] = useState("");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const dateselect1 = startDate?.toISOString();
  const dateselect2 = endDate?.toISOString();
  const [order, setOrder] = useState("Orden alfabético a-z");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dealers.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageNumbers = [];

  useEffect(() => {
    async function showAll() {
      setDatacharging(true);
      const travels = await getAllOrdersDelivery();
      const agrupadosPorRepartidor = {};

      // Iterar sobre el arreglo de objetos
      for (const objeto of travels) {
        const repartidorID = objeto?.delivery_man_id?.id;
        if (agrupadosPorRepartidor.hasOwnProperty(repartidorID)) {
          agrupadosPorRepartidor[repartidorID].orders.push(objeto);
        } else {
          agrupadosPorRepartidor[repartidorID] = {
            name: objeto?.delivery_man_id?.name || "",
            createdAt: objeto?.delivery_man_id?.createdAt || "",
            delivery_transport:
              objeto?.delivery_man_id?.delivery_transport || "",
            id: objeto?.delivery_man_id?.id || "",
            last_name: objeto?.delivery_man_id?.last_name || "",
            levels_byAccount: objeto?.delivery_man_id?.levels_byAccount || {},
            openPay_id: objeto?.delivery_man_id?.openPay_id || "",
            register_approved:
              objeto?.delivery_man_id?.register_approved || false,
            role: objeto?.delivery_man_id?.role || "",
            url_photoProfile: objeto?.delivery_man_id?.url_photoProfile || "",
            orders: [objeto],
          };
        }
      }

      const resultado = Object.values(agrupadosPorRepartidor);

      setDealers(resultado.sort(dateSort));
      setDatacharging(false);
    }
    showAll();
  }, []);

  function dateSort(a, b) {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  }
  function compareFn(a, b) {
    const stA = a.status || false;
    const stB = b.status || false;
    if (stA) return -1;
    if (stB) return 1;
    return 0;
  }

  async function searchName(name) {
    setDatacharging(true);
    const foundDealers = await listDeliverymenName([], 50, null, name);
    setDatacharging(false);
    setDealers(foundDealers);
  }

  function SortArrayAZ(x, y) {
    if (x.shop_name < y.shop_name) {
      return -1;
    }
    if (x.shop_name > y.shop_name) {
      return 1;
    }
    return 0;
  }
  for (let i = 1; i <= Math.ceil(dealers.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const dealersList = currentItems.map((item, index) => {
    if (item.id !== "") return <DeliveryCard key={index} item={item} />;
  });

  return (
    <>
      <section className="dispersion-delivery">
        <div className="dispersion-delivery-top">
          <div className="bar-options">
            <InputSearch
              onClick={() => searchName(name)}
              setName={setName}
              name={name}
              placeholder={"Repartidores registrados..."}
            />
          </div>

          <div className="grid-layout">{dealersList}</div>
        </div>
        <LoadingModal
          onClose={!charginData}
          open={charginData}
          text={"Procesando repartidores, estamos haciendo cuentas..."}
        ></LoadingModal>
      </section>
      <div className="pagination">
        {currentPage > 1 && (
          <button onClick={() => paginate(currentPage - 1)}>
            <Before_Icon />
          </button>
        )}

        {pageNumbers.slice(currentPage - 1, currentPage + 4).map((number) => (
          <button
            key={number}
            className={number === currentPage ? "active" : ""}
            onClick={() => paginate(number)}
          >
            {number}
          </button>
        ))}

        {currentPage < pageNumbers.length && (
          <button onClick={() => paginate(currentPage + 1)}>
            <Next_Icon />
          </button>
        )}
      </div>
    </>
  );
}
