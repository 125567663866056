import { createProduct } from '../common/mutation-functions';

async function createNikuProducts (remainingErpProducts = [{ id: '' }]) {
  try {
    const promises = remainingErpProducts.map((product) => {
      return createProduct({ ...product, url_image: '' });
    });

    const results = promises.filter((result) => result !== null);

    return Promise.all(results);
  } catch (exception) {
    return null;
  }
}

export { createNikuProducts };
