import Openpay from './openpay';

const openpay = new Openpay();
const errors = ['request', 'internal', 'gateway'];

async function deletePlanOpp (planID = '') {
  try {
    const data = await openpay.deletePlan(planID);
    return !errors.includes(data.category);
  } catch (err) {
    return false;
  }
}

export default deletePlanOpp;
