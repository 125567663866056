/* eslint-disable */
import * as React from "react"

const X_Icon = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.929 15.213a1.682 1.682 0 0 1-.52-1.23c0-.473.173-.883.52-1.23l4.611-4.61L.928 3.53a1.68 1.68 0 0 1-.52-1.229c0-.473.174-.883.52-1.23.348-.347.758-.52 1.23-.52.474 0 .884.173 1.23.52L8 5.681l4.612-4.612a1.68 1.68 0 0 1 1.23-.52c.472 0 .882.174 1.23.521.346.347.52.757.52 1.23s-.174.883-.52 1.23L10.46 8.141l4.612 4.612c.346.346.52.756.52 1.23 0 .472-.174.882-.52 1.23-.348.346-.758.52-1.23.52a1.68 1.68 0 0 1-1.23-.52L8 10.602l-4.612 4.612a1.68 1.68 0 0 1-1.23.52c-.472 0-.882-.174-1.23-.52Z"
      fill="#DC2027"
    />
  </svg>
)

export default X_Icon
