export const createUploadFileSlice = (set) => ({
  marketUpdateFile: null,
  marketUpdateFileName: 'Seleccionar archivo...',
  setMarketUpdateFile: (file) => set({ marketUpdateFile: file }),
  setMarketUpdateFileName: (fileName) => set({ marketUpdateFileName: fileName }),
  resetMarketUpdateFile: () => set({
    marketUpdateFile: null,
    marketUpdateFileName: 'Seleccionar archivo...'
  })
});
