import { get } from '../request';

const path = require('../../urls.json');

/**
 * Function to get the cutting status
 *
 * @param {string} dateStart
 * @param {string} dateEnd
 * @return {Promise<boolean>} True if the cutting status was updated successfully
 */
async function getCuttingStatus (dateStart, dateEnd) {
  try {
    const url = `${path.REACT_APP_UNSPLASH_URLSERVER}status/${dateStart}/${dateEnd}`;
    const res = await get(url);
    return res.data;
  } catch (error) {
    return false;
  }
}

export default getCuttingStatus;
