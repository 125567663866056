import * as React from "react";

const Before_Icon = (props) => (
  <svg
    width={11}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.198 12a.77.77 0 0 1-.49-.172L3.46 6.452a.535.535 0 0 1 0-.837L9.784.174a.769.769 0 0 1 .974 0c.269.231.269.606 0 .837l-5.835 5.02 5.765 4.96c.27.23.27.605 0 .837a.757.757 0 0 1-.49.172Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.747 12C.335 12 0 11.722 0 11.38V.62C0 .279.335 0 .747 0c.413 0 .748.278.748.62v10.76c0 .342-.335.62-.748.62Z"
      fill="#fff"
    />
  </svg>
);

export default Before_Icon;
