/* eslint-disable */
import { useAuth } from "../../hooks";
import { useUserStore } from "../../stores";
import RegisterToCollectionFinance from "../RegisterToCollectionFinance/RegisterToCollectionFinance";

export default function SendStatusAccountDrivers() {
  const currentUser = useUserStore((state) => state.currentUser);
  const [addDocument, loading, error] = RegisterToCollectionFinance(
    "project_CMS/Sendings/StatementsDrivers"
  );
  async function post(url, data) {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify(data),
      };

      const response = await fetch(url, options);
      /*  const save_log = {
        timestamp: new Date().toISOString(),
        by: currentUser.username,
        send_to: data.email,
        status: response.status,
        description: response.statusText,
        details: {
          nameOfDriver: data.nameOfDriver || "",
          phoneNumber: data.phoneNumber || "",
          totalGain: data.totalGain,
          netAmount: data.netAmount,
          iva: data.iva,
          isr: data.isr,
          platformFees: data.platformFees,
          totalCommission: data.totalCommission,
          balanceTotalAmount: data.balanceTotalAmount,
        },
        period: "Semanal",
      };

      await addDocument(save_log); */
      return response.json();
    } catch (err) {
      return {
        code: 500,
        message: err.message,
        success: false,
        data: null,
      };
    }
  }

  async function send_status_account_DRIVERS(data) {
    const url = "https://niku-cms.com/email/receipt/driver";
    await post(url, data);
  }

  return { send_status_account_DRIVERS };
}
