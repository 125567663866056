/* eslint-disable */
import { Subtitle } from "../font";

export function ConsortiumUpdateHeader() {
  return (
    <>
      <Subtitle>
        {`Te damos la bienvenida al modulo de actualización de productos de 
        "Mi Mercadito de la 14" en Niku. Por favor, sigue las instrucciones 
        para ejecutar correctamente la tarea.`}
      </Subtitle>
    </>
  );
}
