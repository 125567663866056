/* eslint-disable */
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Modal } from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import Close_Icon from "../icons/Close_Icon";

export default function PresentationCard({ DriverInfo }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card sx={{ width: 345, margin: "10px" }}>
        <CardActionArea onClick={handleOpen}>
          <CardMedia
            component="img"
            height="140"
            image={DriverInfo?.photo}
            alt="Driver's photo"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {DriverInfo?.name + " " + DriverInfo?.last_name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Correo: {DriverInfo?.email}
              <br />
              Telefono: {DriverInfo?.phone}
              <br />
              RFC: {DriverInfo?.rfc || "SIN RFC"}
              <br />
              Nivel:{" "}
              {DriverInfo?.driver_level?.driver_level_name || "SIN NIVEL"}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button startIcon={<CopyAllIcon />} size="small" color="primary">
            Copiar
          </Button>
        </CardActions>
      </Card>

      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Button
            onClick={handleClose}
            color="primary"
            style={{
              alignSelf: "flex-end",
              top: 0,
              right: 0,
              position: "absolute",
            }}
          >
            <Close_Icon />
          </Button>
          <img
            src={DriverInfo?.photo}
            alt="Driver's photo"
            style={{ width: "20%", height: "auto" }}
          />
        </div>
      </Modal>
    </>
  );
}
