import { checkAuth, cognitoSignOut, getCurrentUser } from '../../services';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoginStore, useMarketUpdateStore, useUserStore } from '../../stores';

export function useCheckCurrentUser () {
  const navigate = useNavigate();
  const location = useLocation();
  const setCurrentUser = useUserStore((state) => state.setCurrentUser);
  const resetLogin = useLoginStore((state) => state.resetLogin);
  const resetMarketUpdate = useMarketUpdateStore((state) => state.resetMarketUpdate);
  const setAuthIsProcessed = useLoginStore((state) => state.setAuthIsProcessed);

  const onUserObtained = useCallback((user) => {
    setCurrentUser(user);
    setAuthIsProcessed(true);
  }, [setAuthIsProcessed, setCurrentUser]);

  const checkCurrentUser = useCallback(async () => {
    const user = await getCurrentUser();
    const origin = checkAuth(user, location);

    if (origin === '/updateProducts') resetMarketUpdate();

    if (origin !== '') {
      onUserObtained(user);
      navigate(origin);

      return;
    }

    await cognitoSignOut();
    onUserObtained(null);
  }, [location, navigate, onUserObtained, resetMarketUpdate]);

  useEffect(() => {
    checkCurrentUser();

    return () => resetLogin();
  }, [checkCurrentUser, resetLogin]);
}
