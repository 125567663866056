export function formateDateTitle(title, rangesDate) {
  return deleteSpaces(
    title +
      rangesDate[0].toISOString().substring(0, 10) +
      " - " +
      rangesDate[1].toISOString().substring(0, 10)
  );
}

export function convertDate(timestamp) {
  if (!timestamp) return "Sin fecha";

  let meses = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];

  let fecha = new Date(timestamp);
  let dia = fecha.getDate();
  let mes = meses[fecha.getMonth()];
  let año = fecha.getFullYear();
  let horas = String(fecha.getHours()).padStart(2, "0");
  let minutos = String(fecha.getMinutes()).padStart(2, "0");
  let segundos = String(fecha.getSeconds()).padStart(2, "0");
  let ampm = horas >= 12 ? "PM" : "AM";
  let hora12 = horas > 12 ? horas - 12 : horas;

  return `${dia} DE ${mes} DEL ${año} - ${hora12}:${minutos}:${segundos}${ampm}`;
}

export function destructureDate(dateString) {
  let monthMap = {
    ENERO: 0,
    FEBRERO: 1,
    MARZO: 2,
    ABRIL: 3,
    MAYO: 4,
    JUNIO: 5,
    JULIO: 6,
    AGOSTO: 7,
    SEPTIEMBRE: 8,
    OCTUBRE: 9,
    NOVIEMBRE: 10,
    DICIEMBRE: 11,
  };

  let parts = dateString.split(" ");

  let day = parseInt(parts[0]);
  let month = monthMap[parts[2].toUpperCase()];
  let year = parseInt(parts[4]);
  let time = parts[6];

  let timeParts = time.match(/(\d+):(\d+):(\d+)([APM]+)$/);
  let hours = parseInt(timeParts[1]);
  let minutes = parseInt(timeParts[2]);
  let seconds = parseInt(timeParts[3]);
  let period = timeParts[4];

  if (period === "PM" && hours !== 12) {
    hours += 12;
  }

  return new Date(year, month, day, hours, minutes, seconds);
}
export function convertMethodPay(method) {
  let methodPay = method.toUpperCase();

  if (
    methodPay !== "EFECTIVO" &&
    methodPay !== "WALLET" &&
    methodPay !== "TARJETA"
  ) {
    return "TARJETA";
  } else {
    return methodPay;
  }
}

export function convertToFixedAndNumber(total) {
  return parseFloat(total.toFixed(2));
}
export function calculateCommisionOpenpay(totalTarifa = 0) {
  return convertToFixedAndNumber(totalTarifa * 0.0225);
}

export function calculateNetoDeposito(totalTarifa = 0) {
  return convertToFixedAndNumber(
    totalTarifa - calculateCommisionOpenpay(totalTarifa)
  );
}
export function calculateTotalSinIva(totalTarifa = 0) {
  return convertToFixedAndNumber(totalTarifa / 1.16);
}
export function calculateIva(totalTarifa = 0, porcentage = 0) {
  return convertToFixedAndNumber(
    calculateTotalSinIva(totalTarifa) * porcentage
  );
}

export function calculateDifIVA(totalTarifa = 0, porcentage = [0, 0]) {
  return convertToFixedAndNumber(
    calculateIva(totalTarifa, porcentage[0]) -
      calculateIva(totalTarifa, porcentage[1])
  );
}
export function calculateIsr(totalTarifa = 0, porcentage = 0) {
  return convertToFixedAndNumber(totalTarifa * porcentage);
}
export function calculateDifISR(totalTarifa = 0, porcentages = [0, 0]) {
  return convertToFixedAndNumber(
    calculateIsr(totalTarifa, porcentages[0]) -
      calculateIsr(totalTarifa, porcentages[1])
  );
}
export function calculateUsoPlataforma(totalTarifa = 0, porcentage = 0) {
  return totalTarifa * porcentage;
}
export function calculateCommission(totalTarifa = 0, porcentage = 0) {
  return convertToFixedAndNumber(totalTarifa * porcentage);
}

export function calculateTarifadelviaje(
  dynamic = 0,
  base = 0,
  tariffduration = 0,
  tariffdistance = 0
) {
  return convertToFixedAndNumber(
    (base + tariffduration + tariffdistance) * dynamic
  );
}
export function deleteSpaces(str) {
  return str.replace(/\s/g, "");
}

export function totalgetNiku(
  cuota = 0,
  iva8 = 0,
  isr21 = 0,
  usoplat = 0,
  commission = 0
) {
  return convertToFixedAndNumber(cuota + iva8 + isr21 + usoplat + commission);
}

export function calculateBalance(efect = 0, card = 0) {
  if (efect > card) {
    return "Por cobrar";
  } else if (efect < card) {
    return "Por pagar";
  } else {
    return "Sin deuda";
  }
}

export function refactoringDates(
  fechas = [new Date(), new Date()],
  weekselect
) {
  let date = "";
  if (
    fechas[0]?.toLocaleDateString() === fechas[1]?.toLocaleDateString() ||
    fechas[0] === null ||
    fechas[1] === null
  )
    date = "No hay selección de fecha";
  else if (fechas[0]?.toLocaleDateString() !== fechas[1]?.toLocaleDateString())
    date = `${fechas[0]?.toLocaleDateString()} - ${fechas[1]?.toLocaleDateString()}`;
  else if (weekselect === null) date = "No hay selección de semana";
  else date = `Semana #: ${weekselect.weekselect}`;
  return date;
}
