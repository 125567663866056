/* eslint-disable */
import * as React from "react"

const CardNot_Icon = (props) => (
  <svg
  width={25}
  height={25}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.9 10.03h20"
      stroke="red"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.46 20.53H6.35c-3.55 0-4.45-.88-4.45-4.39V7.92c0-3.18.74-4.2 3.53-4.36.28-.01.59-.02.92-.02h11.11c3.55 0 4.45.88 4.45 4.39v4.41"
      stroke="red"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M17.9 22.03a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      stroke="red"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g
      opacity={0.4}
      stroke="red"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m18.97 19.15-2.11-2.12M18.95 17.06l-2.12 2.11" />
    </g>
    <path
      opacity={0.4}
      d="M5.9 16.03h4"
      stroke="red"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CardNot_Icon
