/* eslint-disable */
import "./login.css";
import * as Component from "./components";
import { Toaster } from "react-hot-toast";
import { useCheckCurrentUser } from "../../hooks";
import { useLoginStore } from "../../stores/login/use-login-store";

export function Login() {
  useCheckCurrentUser();
  const authIsProcessed = useLoginStore((state) => state.authIsProcessed);

  if (!authIsProcessed) return <Component.LoginLoader />;

  return (
    <article className="login">
      <section className="login-container">
        <Toaster />
        <Component.LoginImages />

        <div className="login-form">
          <Component.LoginHeaderText />

          <div className="login-form-field">
            <Component.LoginLabel text="Correo" />
            <Component.EmailInput />
          </div>

          <div className="login-form-field">
            <Component.LoginLabel text="Contraseña" />
            <Component.PasswordInput />
          </div>

          <Component.LoginButton />
        </div>
      </section>
    </article>
  );
}
