const black = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 1,
}

function name_conductor_weekly(name, positions) {
  return {
    startRow: positions[0],
    startColumn: positions[1],
    rowData: [
      {
        values: {
          userEnteredValue: {
            stringValue: name,
          },
          userEnteredFormat: {
            textFormat: {
              bold: true,
              foregroundColorStyle: {
                rgbColor: black,
              },
              fontSize: 8,
            },
            horizontalAlignment: 'LEFT',
            verticalAlignment: 'MIDDLE',
            wrapStrategy: 'LEGACY_WRAP',
          },
        },
      },
    ],
  }
}

export { name_conductor_weekly }
