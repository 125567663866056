/* eslint-disable */
import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import { Donwload_Driver_PDF } from "../../hooks/ListDriverTravels/DownloadPDF";
import { Create_spreadsheet_Driver } from "../../hooks/Google_spreadsheet_0Auth2.0/Create_spreadsheet_driver";
import { DateRangeContainer, RadioOptionLabel, ViewDocument } from "./Styles";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import ExcelColor_Icon from "../icons/ExcelColor_Icon";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SendData from "../../hooks/ListDriverTravels/SendPDF";

export default function DrawerOptions({ state, setState, ObjectDriver }) {
  const [selectedExportDates, setSelectedExportDates] = useState([new Date(), new Date()]);
  const [selectedFilterDates, setSelectedFilterDates] = useState([null, null]);
  const handleExportDateChange = (dates) => {
    setSelectedExportDates(dates);
  };

  const handleFilterDateChange = (dates) => {
    setSelectedFilterDates(dates);
  };
  const { Create_spreadsheet_driver_function, loading, urlDoc } =
    Create_spreadsheet_Driver();
  const { send_status_account } = SendData();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <ListItem disablePadding style={{ width: "350px" }}>
              <ListItemButton onClick={() => Donwload_Driver_PDF(ObjectDriver)}>
                <ListItemIcon>
                  <PictureAsPdfIcon style={{ color: "red" }} />
                </ListItemIcon>
                <ListItemText primary={"Exportar PDF"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding style={{ width: "350px" }}>
              <ListItemButton
                onClick={() =>
                  Create_spreadsheet_driver_function(ObjectDriver, [
                    new Date(),
                    new Date(),
                  ])
                }
              >
                <ListItemIcon>
                  <ExcelColor_Icon />
                </ListItemIcon>
                <ListItemText primary={"Exportar Excel"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding style={{ width: "350px" }}>
              <ListItemButton onClick={() => send_status_account(ObjectDriver)}>
                <ListItemIcon>
                  <ScheduleSendIcon />
                </ListItemIcon>
                <ListItemText primary={"Enviar estado de cuenta"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding style={{ width: "350px" }}>
              
              
            </ListItem>
          </Drawer>
        </React.Fragment>
      ))}
      {loading ? (
        <>
          <DateRangeContainer>
            Generando documento...
            <LoadingSpinner />
          </DateRangeContainer>
        </>
      ) : urlDoc !== "" ? (
        <>
          {urlDoc !== "Error" ? (
            <>
              <RadioOptionLabel>
                El estado de cuenta ha sido generado correctamente
              </RadioOptionLabel>

              <ViewDocument
                href={urlDoc}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ExcelColor_Icon />
                Da click aqui para ir al documento
              </ViewDocument>
            </>
          ) : (
            <>
              <RadioOptionLabel>
                Tuvimos un error al generar el documento, contacta al
                administrador.
              </RadioOptionLabel>
            </>
          )}
        </>
      ) : null}
    </div>
  );
}
