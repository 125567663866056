import Calculate_status_coms_drivers from "../Calculata_status_coms_drivers/Calculate_status_coms_drivers";
import createPDF from "../CreatePDF/createPDF";
import { destructureDate } from "../Google_spreadsheet_0Auth2.0/CalculateFunctions/CalculateFunctions";
import download_status_account_DRIVERS from "../pdfSendDriver/downloadStatusAccount";

function comparesDates(a, b) {
  const fechaA = new Date(destructureDate(a[0]));
  const fechaB = new Date(destructureDate(b[0]));
  if (fechaA > fechaB) return -1;
  if (fechaA < fechaB) return 1;
  return 0;
}

export async function Donwload_Driver_PDF(ObjectDriver) {
  const response_calculate = await Calculate_status_coms_drivers(
    ObjectDriver.travel_matches
  );

  const Driver = ObjectDriver?.driver_info;
  const nameDriver = Driver?.name + " " + Driver?.last_name || "";
  const structure_object_send = {
    email: Driver?.email,
    nameOfDriver: nameDriver,
    phoneNumber: Driver?.phone || "",
    isDebt: response_calculate.isDebt,
    movementData: response_calculate.movementData.sort(comparesDates),
    totalGain: response_calculate.totalGain,
    netAmount: response_calculate.netAmount,
    iva: response_calculate.iva,
    isr: response_calculate.isr,
    platformFees: response_calculate.platformFees,
    totalCommission: response_calculate.totalCommission,
    balanceTotalAmount: response_calculate.balanceTotalAmount,
    applicationFee: response_calculate.applicationFee,
  };

  const download_status_account = await download_status_account_DRIVERS(
    structure_object_send
  );

  let name_doc = `ESTADODECUENTA_${
    nameDriver.toUpperCase().replace(/ /g, "_") +
      "_" +
      new Date().toISOString() || ""
  }.pdf`;

  createPDF(name_doc, download_status_account);

  /*     setIsStatusFiles("Archivo pdf descargado correctamente");
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 5000); */
}
