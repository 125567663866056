/* eslint-disable */
import * as React from 'react';

const Next_Icon = (props) => (
  <svg width={11} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.802 0c.18 0 .352.06.49.172L7.54 5.548c.27.231.27.606 0 .837l-6.324 5.441a.769.769 0 0 1-.974 0 .535.535 0 0 1 0-.837l5.835-5.02L.31 1.01a.535.535 0 0 1 0-.837A.758.758 0 0 1 .801 0Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.253 0c.412 0 .747.278.747.62v10.76c0 .342-.335.62-.747.62-.413 0-.748-.278-.748-.62V.62c0-.342.335-.62.748-.62Z"
      fill="#fff"
    />
  </svg>
);

export default Next_Icon;
