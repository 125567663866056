import Analytics from "./analytics-nikucar";
import ListConcilationDrivers from "./list-concilation-drivers";
import ListDelivery from "./list-delivery";
import ListDriverTravels from "./list-driver-travels";
import ListDrivers from "./list-drivers";
import Equipments from "./list-equipments";
import ListHistoryDriver from "./list-history-drivers";
import ListShops from "./list-shops";

export const pagesRoutes = [
  {
    id: "86c70789-7b38-41f2-8c25-d5388e54ae29",
    path: "/ListDelivery",
    component: ListDelivery,
  },
  {
    id: "4edaa03e-7477-41f7-955f-853eb8cce47f",
    path: "/ListDrivers",
    component: ListDrivers,
  },
  {
    id: "4edaa03e-7477-41f7-955f-853eb8cce47f",
    path: "/ListConcilationDrivers",
    component: ListConcilationDrivers,
  },
  {
    id: "44ddee26-0338-4b79-9551-4107c944934d",
    path: "/ListHistoryDriver",
    component: ListHistoryDriver,
  },
  {
    id: "85fce271-4e21-4bcf-a2ca-8623a68b28ad7",
    path: "/ListShops",
    component: ListShops,
  },
  {
    id: "85fce271-4e21-4bcf-a2ca-8623a68b28b7",
    path: "/ListDriverTravels/:id",
    component: ListDriverTravels,
  },
  {
    id: "85fce271-4e21-4bcf-a2ca-8623a68b68b2",
    path: "/Equipments",
    component: Equipments,
  },
  {
    id: "85fce271-4e21-4bff-a2ca-8623a68b6db2",
    path: "/Analytics",
    component: Analytics,
  },
];
