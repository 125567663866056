import { listOrdersByDateNT } from '../common/query-functions';

async function listOrdersByDate (
  id = '',
  datesBetween = ['', ''],
  orders = [],
  limit = 200,
  next = null
) {
  const { items, nextToken } = await listOrdersByDateNT(id, datesBetween, limit, next);

  if (items.length > 0) {
    const newItems = orders.concat(items);
    if (nextToken === null) {
      return newItems;
    }
    return listOrdersByDate(id, datesBetween, newItems, limit, nextToken);
  }

  if (items.length === 0 && nextToken !== null) {
    return listOrdersByDate(id, datesBetween, orders, limit, nextToken);
  }

  return orders;
}

export default listOrdersByDate;
