import {
  calculateCommission,
  calculateUsoPlataforma,
  convertToFixedAndNumber,
  formateDateTitle,
} from "../CalculateFunctions/CalculateFunctions";
import { header_global } from "./header_global";
import { name_conductor_global } from "./name_global";
import { subtitle_conductor_global } from "./subtitles_global";
import { Import_conductor_global } from "./subtotals/imports_global";
import { Iva_conductor_global } from "./subtotals/iva_global";
import { Subtotal_conductor_global } from "./subtotals/subtotals_global";
import { Total_conductor_global } from "./subtotals/total_global";
import { travel_conductor_global } from "./travel_global";
const orange = {
  red: 0.914,
  green: 0.353,
  blue: 0.075,
  alpha: 1,
};

export function Sheet_Global(arre_travels, rangesDate) {
  const bodySheet = [];
  //Seteo de subheader y header del excel global
  bodySheet.push(
    /*     subtitle_conductor_global("Conductor", [0, 18]),
    subtitle_conductor_global("Retenido", [0, 20]),
    subtitle_conductor_global("Real", [0, 21]),
    subtitle_conductor_global("Retenido", [0, 23]),
    subtitle_conductor_global("Real", [0, 24]), */
    header_global
  );

  let currentRow = 2;

  for (let i in arre_travels) {
    let sumUsoPlataforma = 0;
    let sumCommission = 0;
    const name_conductor = (
      arre_travels[i]?.driver_info?.name +
      " " +
      arre_travels[i]?.driver_info?.last_name
    ).toUpperCase();
    const travels = arre_travels[i]?.travel_matches;
    let rfc = arre_travels[i]?.driver_info?.rfc || "Sin RFC";
    let dateRFC = arre_travels[i]?.driver_info?.date || "";
    let dateRegisterRFC = new Date(dateRFC);

    if (rfc === "Sin RFC") {
      dateRegisterRFC = null;
    }

    bodySheet.push(name_conductor_global(name_conductor, [currentRow++, 0]));
    for (let o in travels) {
      const travel = travels[o];
      const tarifa_viaje_total = travel?.subtotal_tarifa_dinamica || 0;
      const uso_plataforma = travel?.uso_plataforma || 0;
      sumUsoPlataforma = sumUsoPlataforma + uso_plataforma;
      sumCommission =
        sumCommission + calculateCommission(tarifa_viaje_total, 0.15);
      bodySheet.push({
        startRow: currentRow++,
        startColumn: 0,
        rowData: travel_conductor_global(travel, dateRegisterRFC),
      });
    }

    const subtotal_results = {
      sumPlatfromIVA: sumUsoPlataforma,
      sumCommission: sumCommission,
    };
    const import_results = {
      sumPlatfromIVA: convertToFixedAndNumber(sumUsoPlataforma / 1.16),
      sumCommission: convertToFixedAndNumber(sumCommission / 1.16),
    };
    const ivas = {
      sumPlatfromIVA: convertToFixedAndNumber(sumUsoPlataforma * 0.16),
      sumCommission: convertToFixedAndNumber(sumCommission * 0.16),
    };
    const total_results = {
      sumPlatfromIVA: sumUsoPlataforma,
      sumCommission: sumCommission,
    };
    bodySheet.push(
      Subtotal_conductor_global([currentRow++, 18], subtotal_results)
    );
    bodySheet.push(Import_conductor_global([currentRow++, 18], import_results));
    bodySheet.push(Iva_conductor_global([currentRow++, 18], ivas));
    bodySheet.push(Total_conductor_global([currentRow++, 18], total_results));
  }
  return {
    properties: {
      title: formateDateTitle("TESORERÍA", rangesDate),
      tabColorStyle: {
        rgbColor: orange,
      },
    },
    data: bodySheet,
  };
}
