import { UpdateType } from '../../models/market-update';
import { getAllErpProducts } from './get-all-erp-products';
import { getProductsOutOfStock } from './get-products-out-of-stock';
import { getRecentlyModifiedProducts } from './get-recently-modified-products';

export async function getErpProducts (type = 'all') {
  const types = {
    [UpdateType.All]: getAllErpProducts,
    [UpdateType.RecentlyModified]: getRecentlyModifiedProducts,
    [UpdateType.OutOfStock]: getProductsOutOfStock
  };

  return types[type]();
}
