import {
  calculateCommisionOpenpay,
  calculateCommission,
  calculateDifISR,
  calculateDifIVA,
  calculateIsr,
  calculateNetoDeposito,
  convertDate,
  convertMethodPay,
  convertToFixedAndNumber,
  totalgetNiku,
} from "../CalculateFunctions/CalculateFunctions";

const blueGainNiku = {
  red: 0.427,
  green: 0.62,
  blue: 0.922,
  alpha: 1,
};

const green = {
  red: 0.573,
  green: 0.816,
  blue: 0.314,
  alpha: 1,
};

const yellow = {
  red: 1,
  green: 0.753,
  blue: 0,
  alpha: 1,
};

const black = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 1,
};

function createCell(
  value,
  isBold = false,
  backgroundColor = null,
  position = "LEFT"
) {
  const cell = {
    userEnteredValue: {},
    userEnteredFormat: {
      textFormat: {
        bold: isBold,
        fontSize: 10,
      },
      horizontalAlignment: position,
      verticalAlignment: "MIDDLE",
      wrapStrategy: "CLIP",
    },
  };

  if (typeof value === "number") {
    cell.userEnteredValue.numberValue = value;
    cell.userEnteredFormat.horizontalAlignment = "RIGHT";
  } else {
    cell.userEnteredValue.stringValue = value;
  }

  if (backgroundColor) {
    cell.userEnteredFormat.backgroundColorStyle = { rgbColor: backgroundColor };
  }

  return cell;
}

function travel_conductor_global(item, dateRegisterRFC) {
  const tripId = item.trip_id || "Sin trip_id";
  const date = convertDate(item?.time_request);
  const vehicleName = item?.vehicle_data?.name || "Nikucar";
  const paymentMethod = item?.payment_method || "Ninguno";
  const driver_level_name =
    item?.driver_data?.driver_level?.driver_level_name || "Bronce";
  let rfc = item?.driver_data?.rfc || "Sin RFC";
  const minutes_travel = item?.viaje_minutos || 0;
  const distance_travel = item?.distance || 0;
  const dynamic_tarif = item?.tarifa_dinamica || 0;
  const base_rate = item?.rate_data?.base_rate || 0;
  const tarifa_duracion_viaje = item?.tarifa_duracion_viaje || 0;
  const tarifa_por_distancia = item?.tarifa_por_distancia || 0;
  const cuota_de_solicitud = item?.cuota_de_solicitud || 0;
  const tarifa_viaje_total = item?.tarifa_total || 0;
  const pasarela =
    convertMethodPay(paymentMethod) === "TARJETA"
      ? calculateCommisionOpenpay(tarifa_viaje_total)
      : 0;
  const neto_deposito =
    convertMethodPay(paymentMethod) === "TARJETA"
      ? calculateNetoDeposito(tarifa_viaje_total)
      : tarifa_viaje_total;
  const total_conductor = item?.pago_total || 0;

  const tarifadelviaje = item?.subtotal_tarifa_dinamica || 0;
  const subtotal_sin_impuestos = item?.subtotal_sin_impuestos || 0;
  const iva8 = item?.plataforma_iva || 0;
  const isr2_1 = item?.impuestos_isr || 0;
  const uso_plataforma = item?.uso_plataforma || 0;

  const dateTravel = new Date(item?.time_request);

  if (dateRegisterRFC !== null) {
    if (dateTravel < dateRegisterRFC) {
      rfc = "Sin RFC";
    }
  }

  const cells = [
    createCell(tripId, true), //Id del viaje
    createCell(date), //Fecha del viaje
    createCell(convertMethodPay(paymentMethod)), //Metodo de pago
    createCell(rfc !== "Sin RFC" ? "✅" : "❌", false, null, "CENTER"), //Datos fiscales
    createCell(rfc), //RFC
    createCell("Puebla"), //Estado
    createCell(dynamic_tarif, true), //Tarifa dinámica
    createCell(base_rate, true), // Tarifa base
    createCell(tarifa_duracion_viaje, true), //Tarifa por duración del viaje
    createCell(tarifa_por_distancia, true), // Tarifa por distancia
    createCell(convertToFixedAndNumber(tarifadelviaje), true, green), //Tarifa del viaje
    createCell(convertToFixedAndNumber(cuota_de_solicitud), true, blueGainNiku), //Cuota de solicitud
    createCell(tarifa_viaje_total, true), //Total del viaje
    createCell(pasarela, true), //Pasarela de pago
    createCell(neto_deposito, true, yellow), //Total neto dedepósito
    createCell(convertToFixedAndNumber(subtotal_sin_impuestos), true), //Total viaje sin iva
    createCell(convertToFixedAndNumber(iva8 * 2), true), //IVA RETENIDO
    createCell(convertToFixedAndNumber(iva8), true, blueGainNiku), //IVA REAL
    createCell(convertToFixedAndNumber(iva8 * 2), true), //IVA 16%
    createCell(convertToFixedAndNumber(isr2_1), true, blueGainNiku), //ISR 2.1
    createCell(calculateIsr(subtotal_sin_impuestos, 0.2), true), // ISR 20%
    createCell(uso_plataforma, true, blueGainNiku), //Uso de plataforma 5%
    createCell(calculateCommission(tarifadelviaje, 0.15), true, blueGainNiku), //Comisión 15%
    createCell(total_conductor, true, yellow), //Total a recibir conductor
    createCell(
      totalgetNiku(
        cuota_de_solicitud,
        iva8,
        isr2_1,
        uso_plataforma,
        calculateCommission(tarifadelviaje, 0.15)
      ),
      true,
      blueGainNiku
    ), //Total a recibir niku
  ];

  return [{ values: cells }];
}

export { travel_conductor_global };
