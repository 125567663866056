/* eslint-disable */
import { useState } from "react";
import "firebase/firestore";
import { dbFinance } from "../../services/firebaseFinance/firebaseFinance";
import { collection, getDocs } from "firebase/firestore";

function GetDataTablePaymentsDrivers() {
  const [isLoading, setisLoading] = useState(false);
  const [dataTable, setdataTable] = useState([]);

  async function GetInfoTableDriverPayments(FilterBy) {
    try {
      setisLoading(true);
      const dataTable = [];

      const subCollectionRef = collection(
        dbFinance,
        `payments_NIKU/SPEI_Transfers/DriverPayments`
      );

      const querySnapshot = await getDocs(subCollectionRef);
      querySnapshot.forEach((doc) => {
        dataTable.push(doc.data());
      });
      setdataTable(dataTable);
      setisLoading(false);
      console.log("GetData OPayments", FilterBy, dataTable);
    } catch (error) {
      console.log(error);
    }
  }

  return { GetInfoTableDriverPayments, dataTable, isLoading };
}
export default GetDataTablePaymentsDrivers;
