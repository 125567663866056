import { defaultProd } from './consortium-update-presets';

/** Return the products that are not in the updatedProducts array. */
export function compareProducts (
  products = [defaultProd], updatedProducts = [defaultProd]
) {
  return products.filter((product) =>
    !updatedProducts.some((updatedProduct) =>
      product.id === updatedProduct.id
    )
  );
}
