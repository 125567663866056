/* eslint-disable */
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Car_Icon from "../icons/Car_Icon";
import UpdateButton from "./UpdateButtonTableTravels";
import { Avatar, TextField } from "@mui/material";
import { TableDriverStyle } from "../../styles/Tables/TableDriverStyle";
import { useNavigate } from "react-router-dom";
export default function TableDrivers({ Drivers, refresh = () => {}, env = 2 }) {
  const navigate = useNavigate();

  const [filterText, setFilterText] = React.useState("");

  const filteredItems = Drivers.filter(
    (item) =>
      (item.driver_info?.name &&
        item.driver_info?.name
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.driver_info?.email &&
        item.driver_info?.email
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.driver_info?.last_name &&
        item.driver_info?.last_name
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.driver_id &&
        item.driver_id.toLowerCase().includes(filterText.toLowerCase())) ||
      (item?.driver_id?.phone &&
        item?.driver_id?.phone.toLowerCase().includes(filterText.toLowerCase()))
  );

  const SearchDriver = React.useMemo(() => {
    return (
      <TextField
        sx={{
          width: 200,
        }}
        id="filled-basic"
        label="Buscar conductor"
        variant="standard"
        onChange={(e) => setFilterText(e.target.value)}
        value={filterText}
      />
    );
  }, [filterText]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.driver_id,
      sortable: true,
      cell: (row) => (
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            padding: "5px",
          }}
        >
          {!!row?.driver_info?.photo ? (
            <Avatar
              alt="profile"
              src={row?.driver_info?.photo}
              sx={{
                width: 40,
                height: 40,
              }}
            />
          ) : (
            <Car_Icon />
          )}

          <strong
            style={{
              width: "50px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.driver_id}
          </strong>
        </div>
      ),
    },
    {
      name: "Nombre completo",
      selector: (row) =>
        (
          row.driver_info?.name +
          " " +
          row.driver_info?.last_name
        ).toLocaleUpperCase(),
      sortable: true,
      cell: (row) => (
        <p>
          {(
            row.driver_info?.name +
            " " +
            row.driver_info?.last_name
          ).toLocaleUpperCase()}
        </p>
      ),
    },
    {
      name: "Nivel",
      selector: (row) =>
        (row.driver_info?.driver_level?.driver_level_name).toLocaleUpperCase(),
      sortable: true,
      cell: (row) => (
        <p>
          {(row.driver_info?.driver_level?.driver_level_name).toLocaleUpperCase() ||
            "Sin nivel"}
        </p>
      ),
    },
    {
      name: "Equipamiento",
      selector: (row) =>
        (
          row.driver_info?.name +
          " " +
          row.driver_info?.last_name
        ).toLocaleUpperCase(),
      sortable: true,
      cell: (row) => (
        <p
          style={{
            border: "1px solid rgb(229, 237, 255)",
            padding: "5px",
            backgroundColor: row?.driver_info?.equipments?.[0]?.equipment
              ? "rgb(229, 237, 255)"
              : "white",
          }}
        >
          {row?.driver_info?.equipments?.[0]?.equipment || "Sin equipo"}
        </p>
      ),
    },
    {
      name: "Correo electrónico",
      selector: (row) => row.driver_info?.email,
      sortable: true,
    },
    {
      name: "Número telefónico",
      selector: (row) => row.driver_info?.phone,
      sortable: true,
    },
    {
      name: "Estatus",
      selector: (row) => row.driver_info?.status,
      sortable: true,
    },
    {
      name: "RFC",
      selector: (row) => row.driver_info?.rfc || "Sin RFC",
      sortable: true,
    },
    {
      name: "Viajes finalizados",
      selector: (row) => row.travel_matches.length,
      sortable: true,
      cell: (row) => <strong>{row.travel_matches.length}</strong>,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Usuarios por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const RefreshBtn = React.useMemo(
    () => <UpdateButton refresh={refresh} env={env} />,
    []
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          width: "90%",
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "10px",
          zIndex: 0,
          border: "1px solid rgb(229, 237, 255)",
        }}
      >
        <DataTable
          title="Viajes por conductores"
          fixedHeader
          columns={columns}
          data={filteredItems}
          pagination
          responsive
          customStyles={{
            headCells: {
              style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                backgroundColor: "#004990",
                color: "#FFFFFF",
              },
            },
            table: {
              style: {
                minWidth: "100%",
                width: "60vw",
                borderCollapse: "separate",
                height: "70vh",
              },
            },
            cells: {
              style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                border: "1px solid rgb(229, 237, 255)",
              },
            },
          }}          highlightOnHover
          pointerOnHover
          paginationComponentOptions={paginationComponentOptions}
          actions={[SearchDriver, RefreshBtn]}
          onRowClicked={(row) => {
            navigate(`/ListDriverTravels/${row.driver_id}`, {
              state: { info: row },
            });
          }}
        />
      </div>
    </div>
  );
}
