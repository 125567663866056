import React, { useEffect, useState } from "react";
import TableEquipments from "../../components/equipments-list/TableEquipments";
import TabsEquipments from "../../components/equipments-list/TabsEquipments";
import { getEquipmentsDrivers } from "../../hooks/List_Equipments_Drivers/ListEquipments";
const Equipments = () => {
  const [FilterBy, setFilterBy] = useState(0);
  const [Equipments, setEquipments] = useState([]);

  async function getEquipments() {
    const equipments = await getEquipmentsDrivers(0);
    setEquipments(equipments);
  }
  useEffect(() => {
    getEquipments();
  }, []);
  useEffect(() => {
    document.title = `SGFC - Equipamientos registrados`;
  }, []);
  return (
    <>
      <TabsEquipments FilterBy={FilterBy} setFilterBy={setFilterBy} />
      <div style={{ padding: "10px", height: "90vh" }}>
        <TableEquipments Equipments={Equipments} />
      </div>
    </>
  );
};

export default Equipments;
