/* eslint-disable */
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export default function TabsEquipments({ FilterBy, setFilterBy }) {
  const handleChange = (event, newValue) => {
    setFilterBy(newValue);
  };

  const TabStyle = {
    color: "#333",
    fontFamily: "Montserrat, sans-serif",
    "&:hover": {
      backgroundColor: "#e4743d",
      color: "white",
    },
    "&.Mui-selected": {
      backgroundColor: "#e95a13",
      color: "white",
    },
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        paddingLeft: "5%",
      }}
    >
      <Tabs
        value={FilterBy}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab sx={TabStyle} label="Equipamientos" />
      </Tabs>
    </Box>
  );
}
