/* eslint-disable */
import React, { useState } from "react";
import Calculatecoms from "../../hooks/comsDealers/CalculateComs";
import Next_Icon from "../icons/Next_icon";
import Before_Icon from "../icons/Before_Icon";
import DataTable from "react-data-table-component";

export default function TableTravelsDelivery({ Travels, levelDelivery }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState("desc");
  const ComsHook = new Calculatecoms();
  const itemsPerPage = 12;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const levelObject = levelDelivery?.items[0]?.id_level || {
    platform_usage: 0.1,
  };

  let filteredTravels = Travels;

  filteredTravels.sort((a, b) => {
    const dateA = new Date(a.updatedAt).getTime();
    const dateB = new Date(b.updatedAt).getTime();
    if (orderBy === "asc") {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });

  const currentItems = filteredTravels.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(filteredTravels.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const ListTravels = currentItems.map((Travel, index) => {
    const shipping_fees = Travel.shipping_fees || null;
    let calculateComs = {};
    let platformUse = 0;
    let fees = {};
    if (shipping_fees != null) fees = JSON.parse(shipping_fees);
    if (typeof fees === "string") fees = JSON.parse(fees);

    let costForDistance = fees.delivery + fees.distance + fees.collection;

    platformUse = costForDistance * levelObject.platform_usage || 0;
    calculateComs = ComsHook.calculateDebtsObj(costForDistance);
    let totalGain =
      costForDistance - calculateComs.iva - calculateComs.isr - platformUse;
    return (
      <tr key={index}>
        <td>#{Travel.id.slice(0, 6)}</td>
        <td>{Travel.status}</td>
        <td>{Travel.updatedAt?.substring(0, 10)}</td>
        <td>{Travel.method_pay}</td>
        <td>${costForDistance}</td>
        <td>${calculateComs?.nullIVA}</td>
        <td>${calculateComs?.iva}</td>
        <td>${calculateComs?.isr}</td>
        <td>${platformUse.toFixed(2)}</td>
        <td>${totalGain.toFixed(2)}</td>
      </tr>
    );
  });

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Year",
      selector: (row) => row.year,
    },
  ];

  const data = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];

  return (
    <>
      {/*       <div style={{ marginBottom: "10px" }}>
        <button
          style={{
            border: "none",
            backgroundColor: "#e95a13",
            color: "white",
            fontSize: "14px",
            fontWeight: "600",
            borderRadius: "5px",
            cursor: "pointer",
            height: "38px",
            marginLeft: "5px",
            width: "150px",
          }}
          onClick={() => setOrderBy(orderBy === "asc" ? "desc" : "asc")}
        >
          Orden: {orderBy === "asc" ? "Ascendente" : "Descendente"}
        </button>
      </div>

      <table>
        <thead className="t-head-travels">
          <tr>
            <th>Id</th>
            <th>Estado</th>
            <th>Fecha</th>
            <th>Método de pago</th>
            <th>Cobro de viaje</th>
            <th>Cobro sin IVA</th>
            <th>IVA</th>
            <th>ISR</th>
            <th>U.Plataforma</th>
            <th>Ganancia</th>
          </tr>
        </thead>
        <tbody>{ListTravels}</tbody>
      </table>
      <div className="pagination">
        {currentPage > 1 && (
          <button onClick={() => paginate(currentPage - 1)}>
            <Before_Icon />
          </button>
        )}

        {pageNumbers.slice(currentPage - 1, currentPage + 4).map((number) => (
          <button
            key={number}
            className={number === currentPage ? "active" : ""}
            onClick={() => paginate(number)}
          >
            {number}
          </button>
        ))}

        {currentPage < pageNumbers.length && (
          <button onClick={() => paginate(currentPage + 1)}>
            <Next_Icon />
          </button>
        )}
      </div> */}
      <DataTable columns={columns} data={data} />
    </>
  );
}
