/* eslint-disable */
import { useState } from "react";
import { FinishedTravels } from "./getTravelsFinished";

export default function GetDrivers() {
  const [bundledDrivers, setBundledDrivers] = useState([]);
  const [Loading, setLoading] = useState(true);
  const { filterTravels } = FinishedTravels();

  async function getTravelsDrivers() {
    setLoading(true);
    const getDrivers = await filterTravels(
      "https://admin-billing.niku-cms.com/firebase/v1/ListTravel"
    );
    if (getDrivers) {
      const arregloConductorViajes = getDrivers.reduce((resultado, objeto) => {
        const conductorName = objeto.conductorName;
        const conductorId = objeto.conductorId;
        const clienteId = objeto.clienteId;
        const clienteName = objeto.clienteName;
        const conductorCorreo = objeto.conductorCorreo;
        const conductorTelefono = objeto.conductorTelefono;
        const calificacion = objeto.calificacion;
        const placas = objeto.placas;
        const fotoPerfil = objeto.fotoPerfil;

        const objetoExistente = resultado.find(
          (item) => item.conductorId === conductorId
        );

        if (objetoExistente) {
          if (objeto.estatus === "Terminado") {
            objetoExistente.viajes.push(objeto);
          }
        } else {
          const nuevoObjeto = {
            conductorName: conductorName,
            conductorId: conductorId,
            clienteIdent: clienteId,
            clienteName: clienteName,
            conductorCorreo: conductorCorreo,
            conductorTelefono: conductorTelefono,
            calificacion: calificacion,
            fotoPerfil: fotoPerfil,
            placas: placas,
            viajes: [objeto],
          };

          if (
            objeto.estatus === "Terminado" &&
            objeto?.conductorId != "No asignado"
          ) {
            resultado.push(nuevoObjeto);
          }
        }

        return resultado;
      }, []);

      setBundledDrivers(arregloConductorViajes);
    }
    setLoading(false);
  }

  async function getTravelsHasura(offset, status, result = []) {
    let limit = 10;
    let apiKey = process.env.REACT_APP_HAURA_KEY;

    const endpoint = `https://superb-mackerel-18.hasura.app/api/rest/getTravels/${limit}/${offset}/${status}`;
    try {
      const response = await fetch(endpoint, {
        headers: {
          "x-hasura-admin-secret": `${apiKey}`,
        },
      });
      const newData = await response.json();

      if (newData?.trips.length === 0) {
        return result;
      }

      result = result.concat(newData?.trips);
      return getTravelsHasura(offset + limit, status, result);
    } catch (error) {
      console.error("Error al obtener los datos de viajes:", error);
      return result;
    }
  }

  return { bundledDrivers, Loading, getTravelsDrivers, getTravelsHasura };
}
