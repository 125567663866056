/* eslint-disable */
import { LoadingModal, LoadingSpinner } from "../../../components";
import styles from "./LoginLoader.module.css";

export function LoginLoader() {
  return (
    <div className={styles.loading}>
      <LoadingModal onClose={false} open={true} text={"Bienvenido a CMS..."} />
    </div>
  );
}
