import axios from "axios";

export async function moveFileToFolder(fileId, folderId, accessToken) {
  try {
    return await axios({
      method: "POST",
      url: `https://www.googleapis.com/drive/v2/files/${fileId}/parents`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        id: folderId,
      },
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: Move_Folder.js:17 ~ moveFileToFolder ~ error:",
      error
    );
    return error;
  }
}
