export const UpdateState = Object.freeze({
  Waiting: 'waiting',
  Loading: 'loading',
  Success: 'success',
  Error: 'error'
});

export const UpdateType = Object.freeze({
  Unselected: 'unselected',
  All: 'ALL_PRODUCTS',
  RecentlyModified: 'RECENTLY_MODIFIED_PRODUCTS',
  OutOfStock: 'PRODUCTS_OUT_OF_STOCK'
});

export const UpdateStep = Object.freeze({
  First: 0,
  Second: 1,
  Third: 2
});
