import { API } from "aws-amplify";
import { listTravelsDelivery } from "../../graphql/myQueries";

export async function getAllOrdersDelivery() {
  try {
    const limit = 2000;
    let nextToken = null;
    let allItems = [];

    do {
      // Realizar la consulta a la API
      const response = await API.graphql({
        query: listTravelsDelivery,
        variables: {
          limit: limit,
          nextToken: nextToken,
        },
      });

      const items = response?.data?.listOrders?.items || {};

      allItems = allItems.concat(items);

      nextToken = response.data.listOrders.nextToken;
    } while (nextToken);

    return allItems;
  } catch (err) {
    return null;
  }
}
